<template>
  <v-dialog persistent overlay-opacity="0.6" max-width="1200" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-dialog ref="dateDialog" v-model="dateModal" width="290px">
        <v-date-picker
          @input="saveDateDialog()"
          v-model="dateDialogValue"
          scrollable
          :first-day-of-week="1"
          :locale="$store.state.user.locale"
        >
        </v-date-picker>
      </v-dialog>
      <v-dialog ref="dateDialog" v-model="shipmentDateModal" width="290px">
        <v-date-picker
          @input="saveShipmentDateDialog()"
          v-model="shipmentDateDialogValue"
          scrollable
          :first-day-of-week="1"
          :locale="$store.state.user.locale"
        >
        </v-date-picker>
      </v-dialog>
      <v-dialog
        ref="dateDialog"
        v-model="loanExpirationDateModal"
        width="290px"
      >
        <v-date-picker
          @input="saveLoanExpirationDateDialog()"
          v-model="loanExpirationDateDialogValue"
          scrollable
          :first-day-of-week="1"
          :locale="$store.state.user.locale"
        >
        </v-date-picker>
      </v-dialog>

      <messageBox ref="msgBox" />
      <setCurrency ref="setCurrency" />
      <setQuantity ref="setQuantity" />
      <setString ref="setString" />
      <customProduct ref="customProduct" @customProduct="handleCustomProduct" />

      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-cube-send</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("order-edit-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div class="vertical-spacer" />

            <v-row v-if="$store.state.hasRight('180') && !editingOrder">
              <v-col class="mb-2">
                {{ $t("order-edit-user") }}
                <v-autocomplete
                  style="max-width:500px; margin:auto;"
                  :disabled="order.customer_id != null"
                  outlined
                  dense
                  v-model="user"
                  :items="users"
                  item-text="name"
                  item-value="id"
                  return-object
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row v-if="$store.state.hasRight('180') && editingOrder && user">
              <v-col>
                {{ $t("order-edit-user") }} :
                {{ user.first_name + " " + user.last_name }}
              </v-col>
            </v-row>

            <div v-if="!customer && user">
              {{ $t("order-edit-customer") }}
              <div>
                <v-autocomplete
                  style="max-width:500px; margin:auto;"
                  dense
                  clearable
                  no-filter
                  v-model="customer"
                  :items="customers"
                  :loading="isLoadingCustomers"
                  :search-input.sync="searchCustomersInput"
                  append-icon="mdi-magnify"
                  :label="$t('order-edit-customer-search')"
                  item-text="display_name"
                  item-value="id"
                  hide-details
                  single-line
                  outlined
                  return-object
                >
                  <template v-slot:item="data">
                    <div style="width:100%">
                      <v-icon :color="data.item.reference_color"
                        >mdi-circle</v-icon
                      >
                      <span class="text-center body-2 font-weight-light">{{
                        data.item.name
                      }}</span>
                    </div>
                  </template>
                </v-autocomplete>
              </div>
            </div>

            <v-progress-circular
              v-if="customer && loading_startup_products"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="primary"
            ></v-progress-circular>

            <ValidationObserver
              ref="obs"
              v-slot="{ invalid, validated }"
              v-if="customer && !loading_startup_products"
            >
              <v-row class="text-center" dense v-if="customer">
                <v-col>
                  {{ $t("order-edit-customer-name") }}<BR />
                  <span class="font-weight-bold">{{
                    customer.business_name
                      ? customer.business_name
                      : customer.first_name + " " + customer.last_name
                  }}</span>
                </v-col>
                <v-col>
                  {{ $t("order-edit-customer-price-list") }}<BR />
                  <span class="font-weight-bold">{{
                    customer.price_list
                  }}</span>
                </v-col>
                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      style="max-width:500px; margin: auto;"
                      :label="$t('order-edit-hint-sales-point')"
                      outlined
                      dense
                      v-model="order.sales_point"
                      item-text="name"
                      item-value="id"
                      prepend-icon="mdi-factory"
                      :items="salesPoints"
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row dense v-if="customer && shipment_on_closed_day">
                <v-col>
                  <span class="blink font-weight-bold red--text">{{
                    $t("order-edit-closure-day")
                  }}</span>
                </v-col>
              </v-row>

              <div v-if="customer">
                <div style="height:10px" />
                <v-row dense
                  ><v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        outlined
                        dense
                        :immediate="true"
                        @click="openDateDialog()"
                        :value="order.creation_date | toLocaleDate"
                        :label="$t('order-edit-select-date')"
                        prepend-icon="mdi-calendar"
                        readonly
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        outlined
                        dense
                        :immediate="true"
                        @click="openShipmentDateDialog()"
                        :value="order.shipment_date | toLocaleDate"
                        :label="$t('order-edit-select-shipment-date')"
                        prepend-icon="mdi-calendar"
                        readonly
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col v-if="order.loan">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        outlined
                        dense
                        :immediate="true"
                        @click="openLoanExpirationDateDialog()"
                        :value="order.loan_expiration | toLocaleDate"
                        :label="$t('order-edit-select-loan-expiration-date')"
                        prepend-icon="mdi-calendar"
                        readonly
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="3">
                    <v-select
                      hide-details
                      :label="$t('order-edit-hint-state')"
                      outlined
                      dense
                      v-model="order.state"
                      item-text="name"
                      item-value="id"
                      prepend-icon="mdi-refresh"
                      :items="orderStatesOptions"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-if="!customer.mandatory_po"
                      hide-details
                      :label="$t('order-edit-po')"
                      outlined
                      dense
                      v-model="order.purchase_order"
                    >
                    </v-text-field>
                    <ValidationProvider
                      v-if="customer.mandatory_po"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        hide-details
                        :label="$t('order-edit-po')"
                        outlined
                        dense
                        v-model="order.purchase_order"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col>
                    <v-select
                      hide-details
                      :label="$t('order-edit-hint-courier')"
                      outlined
                      dense
                      v-model="order.courier_id"
                      item-text="name"
                      item-value="id"
                      prepend-icon="mdi-truck"
                      :items="couriers"
                      :disabled="
                        ($store.state.hasRight('183') &&
                          !$store.state.hasRight('189') &&
                          !$store.state.isAdmin()) ||
                          order.no_shipment == 1
                      "
                    >
                    </v-select></v-col
                  ><v-col cols="2">
                    <v-checkbox
                      v-model="order.no_shipment"
                      hide-details
                      class="shrink mr-2 mt-0"
                      :label="$t('order-edit-no-delivery')"
                    ></v-checkbox></v-col
                ></v-row>

                <div>
                  <v-row>
                    <v-col cols="5">
                      <div>
                        <span class="font-weight-bold">{{
                          $t("order-edit-shipment-address")
                        }}</span>
                        <v-autocomplete
                          :disabled="
                            customer_fetching_addresses ||
                              customer_fetching_exposure ||
                              customer_fetching_discount ||
                              customer_fetching_templates ||
                              fetching_template_items ||
                              storing_template ||
                              deleting_template
                          "
                          hide-details
                          outlined
                          dense
                          ref="shipmentAddress"
                          item-value="id"
                          item-text="address"
                          prepend-icon="mdi-home"
                          v-model="order.shipment_address_id"
                          :items="shipment_addresses"
                        >
                        </v-autocomplete>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <div>
                        <span class="font-weight-bold">{{
                          $t("order-edit-invoice-address")
                        }}</span>
                        <v-autocomplete
                          :disabled="
                            customer_fetching_addresses ||
                              customer_fetching_exposure ||
                              customer_fetching_discount ||
                              customer_fetching_templates ||
                              fetching_template_items ||
                              storing_template ||
                              deleting_template
                          "
                          hide-details
                          outlined
                          dense
                          ref="invoiceAddress"
                          item-value="id"
                          item-text="address"
                          prepend-icon="mdi-home"
                          v-model="order.invoice_address_id"
                          :items="invoice_addresses"
                        >
                        </v-autocomplete>
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <span class="font-weight-bold">{{
                        $t("order-edit-num-of-packages")
                      }}</span>
                      <ValidationProvider
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <v-text-field
                          v-model="num_of_packages"
                          :label="$t('order-edit-num-of-packages')"
                          outlined
                          dense
                          hide-details
                          :disabled="
                            customer_fetching_addresses ||
                              customer_fetching_exposure ||
                              customer_fetching_discount ||
                              customer_fetching_templates ||
                              fetching_template_items ||
                              storing_template ||
                              deleting_template
                          "
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="9">
                      <v-select
                        v-if="
                          customer &&
                            !customer.products_free_search &&
                            customer.price_list_id
                        "
                        :disabled="
                          loading_startup_products ||
                            customer_fetching_addresses ||
                            customer_fetching_exposure ||
                            customer_fetching_discount ||
                            customer_fetching_templates ||
                            fetching_template_items ||
                            storing_template ||
                            deleting_template
                        "
                        hide-details
                        :label="$t('order-edit-add-product')"
                        outlined
                        dense
                        v-model="selected_product"
                        item-text="name"
                        item-value="id"
                        :items="select_products"
                        prepend-icon="mdi-database"
                        return-object
                      >
                        <template v-slot:item="data">
                          <template v-if="isNotObject(data.item)">
                            <v-list-item-content
                              v-text="data.item"
                            ></v-list-item-content>
                          </template>
                          <template v-else>
                            <v-row>
                              <v-col cols="1">
                                <v-progress-circular
                                  style="margin-top:10px;"
                                  v-if="data.item.fetchingImage"
                                  :key="data.item.fetchKey"
                                  :indeterminate="true"
                                  :rotate="0"
                                  :size="48"
                                  :width="4"
                                  color="primary"
                                ></v-progress-circular>
                                <v-list-item-avatar
                                  style="margin-top:10px; border:1px solid black;"
                                  v-if="!data.item.fetchingImage"
                                  tile
                                  size="48"
                                  :key="data.item.fetchKey"
                                >
                                  <img
                                    v-if="data.item.fetchedImage"
                                    :src="data.item.fetchedImage"
                                  />
                                  <img
                                    v-if="!data.item.fetchedImage"
                                    :src="require('../assets/product.png')"
                                  />
                                </v-list-item-avatar>
                              </v-col>
                              <v-col align="left" cols="11">
                                <v-list-item-content>
                                  <v-list-item-title
                                    style="line-height:48px"
                                    v-html="data.item.compositeName"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-col>
                            </v-row>
                          </template>
                        </template>
                      </v-select>

                      <v-autocomplete
                        v-if="
                          !customer ||
                            customer.products_free_search ||
                            !customer.price_list_id
                        "
                        v-model="selected_product"
                        :items="products"
                        :loading="loading_products"
                        :search-input.sync="loading_products_search"
                        hide-details
                        outlined
                        dense
                        item-text="compositeName"
                        item-value="id"
                        :placeholder="$t('order-edit-search-product')"
                        prepend-icon="mdi-database-search"
                        return-object
                        :disabled="
                          customer_fetching_addresses ||
                            customer_fetching_exposure ||
                            customer_fetching_discount ||
                            customer_fetching_templates ||
                            fetching_template_items ||
                            storing_template ||
                            deleting_template
                        "
                      >
                        <template v-slot:item="data">
                          <template v-if="isNotObject(data.item)">
                            <v-list-item-content
                              v-text="data.item"
                            ></v-list-item-content>
                          </template>
                          <template v-else>
                            <v-row>
                              <v-col cols="1">
                                <v-progress-circular
                                  style="margin-top:10px;"
                                  v-if="data.item.fetchingImage"
                                  :key="data.item.fetchKey"
                                  :indeterminate="true"
                                  :rotate="0"
                                  :size="48"
                                  :width="4"
                                  color="primary"
                                ></v-progress-circular>
                                <v-list-item-avatar
                                  style="margin-top:10px; border:1px solid black;"
                                  v-if="!data.item.fetchingImage"
                                  tile
                                  size="48"
                                  :key="data.item.fetchKey"
                                >
                                  <img
                                    v-if="data.item.fetchedImage"
                                    :src="data.item.fetchedImage"
                                  />
                                  <img
                                    v-if="!data.item.fetchedImage"
                                    :src="require('../assets/product.png')"
                                  />
                                </v-list-item-avatar>
                              </v-col>
                              <v-col align="left" cols="11">
                                <v-list-item-content>
                                  <v-list-item-title
                                    style="line-height:48px"
                                    v-html="data.item.compositeName"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-col>
                            </v-row>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="1">
                      <v-tooltip bottom
                        ><template v-slot:activator="{ on }"
                          ><v-btn
                            class="mr-1 ml-1"
                            :disabled="
                              customer_fetching_addresses ||
                                customer_fetching_exposure ||
                                customer_fetching_discount ||
                                customer_fetching_templates ||
                                fetching_template_items ||
                                storing_template ||
                                deleting_template
                            "
                            x-small
                            fab
                            color="green"
                            @click.stop="addCustomProduct()"
                            v-on="on"
                            ><v-icon dark>mdi-plus</v-icon></v-btn
                          ></template
                        ><span>{{
                          $t("order-edit-add-custom-product")
                        }}</span></v-tooltip
                      >
                      <v-tooltip bottom
                        ><template v-slot:activator="{ on }"
                          ><v-btn
                            class="mr-1 ml-1"
                            :disabled="
                              customer_fetching_addresses ||
                                customer_fetching_exposure ||
                                customer_fetching_discount ||
                                customer_fetching_templates ||
                                fetching_template_items ||
                                storing_template ||
                                deleting_template
                            "
                            x-small
                            fab
                            color="#445D48"
                            @click.stop="addCustomProductVat()"
                            v-on="on"
                            ><v-icon dark>mdi-plus</v-icon></v-btn
                          ></template
                        ><span>{{
                          $t("order-edit-add-custom-product-vat")
                        }}</span></v-tooltip
                      >
                    </v-col>
                    <v-col cols="1">
                      <v-progress-circular
                        v-if="
                          fetching_template_items ||
                            customer_fetching_templates ||
                            deleting_template
                        "
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="primary"
                      ></v-progress-circular>
                      <v-menu
                        v-if="
                          !fetching_template_items &&
                            !customer_fetching_templates
                        "
                        rounded="lg"
                        offset-y
                      >
                        <template v-slot:activator="{ on: menu, attrs }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                              <v-btn
                                :disabled="
                                  customer_fetching_addresses ||
                                    customer_fetching_exposure ||
                                    customer_fetching_discount ||
                                    customer_fetching_templates ||
                                    fetching_template_items ||
                                    storing_template ||
                                    deleting_template ||
                                    !$store.state.hasRight('209')
                                "
                                color="purple"
                                dark
                                fab
                                x-small
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                              >
                                <v-icon dark>mdi-database-import</v-icon>
                              </v-btn>
                            </template>
                            <span>{{
                              $t("order-edit-select-order-template")
                            }}</span>
                          </v-tooltip>
                        </template>
                        <v-list>
                          <v-list-item
                            ripple
                            v-for="x in templates"
                            :key="x.id"
                          >
                            <v-list-item-title
                              ><span @click="templateSelected(x)">{{
                                x.name
                              }}</span>
                              <v-btn
                                :disabled="
                                  customer_fetching_addresses ||
                                    customer_fetching_exposure ||
                                    customer_fetching_discount ||
                                    customer_fetching_templates ||
                                    fetching_template_items ||
                                    storing_template ||
                                    deleting_template ||
                                    !$store.state.hasRight('211')
                                "
                                icon
                                dark
                                color="red"
                                @click="removeTemplate(x)"
                              >
                                <v-icon small>mdi-delete</v-icon>
                              </v-btn>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                    <v-col cols="1">
                      <v-progress-circular
                        v-if="storing_template"
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="primary"
                      ></v-progress-circular>

                      <v-tooltip
                        v-if="!customer_fetching_templates && !storing_template"
                        bottom
                        ><template v-slot:activator="{ on }"
                          ><v-btn
                            :disabled="
                              !$store.state.hasRight('210') ||
                                !order.shipment_address_id ||
                                !order.invoice_address_id ||
                                customer_fetching_addresses ||
                                customer_fetching_exposure ||
                                customer_fetching_discount ||
                                customer_fetching_templates ||
                                fetching_template_items ||
                                deleting_template ||
                                !cart_valid ||
                                !num_of_boxes_valid
                            "
                            x-small
                            fab
                            color="green"
                            @click.stop="addOrderTemplate()"
                            v-on="on"
                            ><v-icon dark>mdi-database-export</v-icon></v-btn
                          ></template
                        ><span>{{
                          $t("order-edit-save-order-template")
                        }}</span></v-tooltip
                      >
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-layout row fill-height>
                        <v-flex
                          id="orderContainer"
                          class="elevation-1"
                          style="border-top:1px solid gray; max-height:300px;overflow:auto;"
                          fill-height
                        >
                          <v-simple-table
                            v-if="!fetchingCart && !loading_startup_products"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th class="text-center">
                                  {{ $t("order-edit-tbl-code") }}
                                </th>
                                <th class="text-center">
                                  {{ $t("order-edit-tbl-product") }}
                                </th>

                                <th class="text-center" style="min-width:100px">
                                  {{ $t("order-edit-tbl-base-price") }}
                                </th>
                                <th class="text-center" style="min-width:100px">
                                  {{ $t("order-edit-tbl-list-price") }}
                                </th>
                                <th class="text-center" style="min-width:160px">
                                  {{ $t("order-edit-tbl-final-price") }}
                                </th>
                                <th
                                  v-if="customer.discount_shown"
                                  class="text-center"
                                  style="min-width:100px"
                                >
                                  {{ $t("order-edit-tbl-default-discount") }}
                                </th>
                                <th
                                  v-if="customer.discount_shown"
                                  class="text-center"
                                  style="min-width:100px"
                                >
                                  {{ $t("order-edit-tbl-shown-discount") }}
                                </th>

                                <th class="text-center">
                                  {{ $t("order-edit-tbl-measure-item") }}
                                </th>
                                <th class="text-center">
                                  {{ $t("order-edit-tbl-quantity") }}
                                </th>
                                <th class="text-center">
                                  {{ $t("order-edit-tbl-total") }}
                                </th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in cart" :key="index">
                                <td class="text-left">
                                  <img
                                    width="48"
                                    height="48"
                                    v-if="item.fetchedImage"
                                    :src="item.fetchedImage"
                                    style="margin-top:5px; border:1px solid black;"
                                  />
                                  <img
                                    width="48"
                                    height="48"
                                    v-if="!item.fetchedImage"
                                    :src="require('../assets/product.png')"
                                    style="margin-top:5px; border:1px solid black;"
                                  />
                                </td>
                                <td class="text-center">
                                  {{ getProductCode(item) }}
                                </td>
                                <td class="text-center">{{ item.name }}</td>

                                <td class="text-center">
                                  {{ item.price | toCurrencySymbol }}
                                </td>
                                <td class="text-center">
                                  {{ item.list_price | toCurrencySymbol }}
                                </td>
                                <td
                                  class="text-center"
                                  style=";min-width:140px"
                                >
                                  {{ item.effective_price | toCurrencySymbol }}
                                  <v-tooltip
                                    bottom
                                    v-if="
                                      $store.state.hasRight('180') &&
                                        !item.custom
                                    "
                                    ><template v-slot:activator="{ on }"
                                      ><v-btn
                                        :disabled="
                                          customer_fetching_addresses ||
                                            customer_fetching_exposure ||
                                            customer_fetching_discount ||
                                            customer_fetching_templates ||
                                            fetching_template_items ||
                                            storing_template ||
                                            deleting_template
                                        "
                                        x-small
                                        text
                                        icon
                                        color="primary"
                                        @click.stop="editPrice(item)"
                                        v-on="on"
                                        ><v-icon>mdi-pencil</v-icon></v-btn
                                      ></template
                                    ><span>{{
                                      $t("order-edit-set-price-title")
                                    }}</span></v-tooltip
                                  >

                                  <v-tooltip
                                    bottom
                                    v-if="
                                      $store.state.hasRight('180') &&
                                        !item.custom
                                    "
                                    ><template v-slot:activator="{ on }"
                                      ><v-btn
                                        :disabled="
                                          customer_fetching_addresses ||
                                            customer_fetching_exposure ||
                                            customer_fetching_discount ||
                                            customer_fetching_templates ||
                                            fetching_template_items ||
                                            storing_template ||
                                            deleting_template
                                        "
                                        x-small
                                        text
                                        icon
                                        color="warning"
                                        @click.stop="setDiscount(item)"
                                        v-on="on"
                                        ><v-icon
                                          >mdi-sack-percent</v-icon
                                        ></v-btn
                                      ></template
                                    ><span>{{
                                      $t("order-edit-set-discount-title")
                                    }}</span></v-tooltip
                                  >
                                </td>
                                <td
                                  v-if="customer.discount_shown"
                                  class="text-center"
                                >
                                  {{
                                    (
                                      100 -
                                      (item.effective_price / item.price) * 100
                                    ).toFixed(2)
                                  }}
                                  %
                                </td>
                                <td
                                  v-if="customer.discount_shown"
                                  class="text-center"
                                >
                                  <span v-if="item.discount_shown">
                                    {{ item.discount_shown }} %</span
                                  >
                                </td>
                                <td>
                                  {{
                                    $t(
                                      $store.state.measure_units_by_id[
                                        item.measure_unit
                                      ]
                                    )
                                  }}
                                </td>
                                <td
                                  v-if="item.spinners"
                                  class="text-center"
                                  :style="
                                    getQuantityStyle(item) + ';min-width:200px;'
                                  "
                                >
                                  {{ formatQuantity(item.quantity) }}
                                  <v-btn
                                    :disabled="
                                      customer_fetching_addresses ||
                                        customer_fetching_exposure ||
                                        customer_fetching_discount ||
                                        customer_fetching_templates ||
                                        fetching_template_items ||
                                        storing_template ||
                                        deleting_template
                                    "
                                    v-if="!item.custom"
                                    @click="increaseQuantity(item)"
                                    class="ml-4"
                                    fab
                                    dark
                                    width="24"
                                    height="24"
                                    color="success"
                                    ><v-icon small dark>mdi-plus</v-icon></v-btn
                                  ><v-btn
                                    :disabled="
                                      customer_fetching_addresses ||
                                        customer_fetching_exposure ||
                                        customer_fetching_discount ||
                                        customer_fetching_templates ||
                                        fetching_template_items ||
                                        storing_template ||
                                        deleting_template
                                    "
                                    v-if="!item.custom"
                                    @click="decreaseQuantity(item)"
                                    class="ml-4"
                                    fab
                                    dark
                                    width="24"
                                    height="24"
                                    color="success"
                                    ><v-icon small dark
                                      >mdi-minus</v-icon
                                    ></v-btn
                                  ><v-btn
                                    :disabled="
                                      customer_fetching_addresses ||
                                        customer_fetching_exposure ||
                                        customer_fetching_discount ||
                                        customer_fetching_templates ||
                                        fetching_template_items ||
                                        storing_template ||
                                        deleting_template
                                    "
                                    v-if="!item.custom"
                                    @click="editQuantity(item)"
                                    class="ml-4"
                                    fab
                                    dark
                                    width="24"
                                    height="24"
                                    color="primary"
                                    ><v-icon small dark
                                      >mdi-pencil</v-icon
                                    ></v-btn
                                  >
                                </td>
                                <td
                                  style="vertical-align:middle;"
                                  v-if="!item.spinners && !item.custom"
                                >
                                  <v-text-field
                                    :disabled="
                                      customer_fetching_addresses ||
                                        customer_fetching_exposure ||
                                        customer_fetching_discount ||
                                        customer_fetching_templates ||
                                        fetching_template_items ||
                                        storing_template ||
                                        deleting_template
                                    "
                                    :ref="
                                      item.id.toString() +
                                        '_quantity_text_field'
                                    "
                                    v-model="item.quantity"
                                    outlined
                                    dense
                                    number
                                    hide-details
                                    single-line
                                    @keypress="isQuantityNumber($event)"
                                  ></v-text-field>
                                </td>
                                <td
                                  style="vertical-align:middle;"
                                  v-if="item.custom"
                                >
                                  {{ item.quantity }}
                                </td>

                                <td class="text-center">
                                  {{
                                    item.quantity
                                      ? $options.filters.toCurrencySymbol(
                                          item.effective_price *
                                            parseFloat(
                                              item.quantity
                                                .toString()
                                                .replace(",", ".")
                                            )
                                        )
                                      : ""
                                  }}
                                </td>
                                <td class="text-center">
                                  <v-btn
                                    :disabled="
                                      customer_fetching_addresses ||
                                        customer_fetching_exposure ||
                                        customer_fetching_discount ||
                                        customer_fetching_templates ||
                                        fetching_template_items ||
                                        storing_template ||
                                        deleting_template
                                    "
                                    @click="removeItem(item)"
                                    fab
                                    dark
                                    width="24"
                                    height="24"
                                    color="red"
                                    ><v-icon small dark
                                      >mdi-delete</v-icon
                                    ></v-btn
                                  >
                                </td>
                                <td></td>
                              </tr>
                              <tr v-if="cart.length > 0">
                                <td></td>
                                <td></td>
                                <td></td>

                                <td colspan="2" class="text-center">
                                  {{ $t("order-edit-total-products") }}:
                                  {{ quantityTotal }}
                                </td>
                                <td class="text-center">
                                  {{ cartTotal | toCurrencySymbol }}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                            <tbody v-if="loading_product_price">
                              <tr>
                                <td colspan="9" class="text-center">
                                  <v-progress-circular
                                    :indeterminate="true"
                                    :rotate="0"
                                    :size="32"
                                    :width="4"
                                    color="primary"
                                  ></v-progress-circular>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-flex>
                      </v-layout>
                    </v-col>
                  </v-row>
                </div>

                <v-row v-if="customer">
                  <v-col>
                    <v-textarea
                      height="100"
                      outlined
                      v-model="order.notes"
                      :label="$t('order-edit-hint-notes')"
                    ></v-textarea>
                  </v-col>
                  <v-col>
                    <v-textarea
                      height="100"
                      outlined
                      v-model="order.invoice_notes"
                      :label="$t('order-edit-hint-invoice-notes')"
                    ></v-textarea>
                  </v-col>
                  <v-col>
                    <v-textarea
                      height="100"
                      color=""
                      outlined
                      v-model="order.private_notes"
                      :label="$t('order-edit-hint-private-notes')"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <div>
                  <v-row dense v-if="daysExposureLocked">
                    <v-col>
                      <span class="blink font-weight-bold red--text">{{
                        $t("order-edit-days-exposure")
                      }}</span>
                    </v-col>
                  </v-row>

                  <v-row dense v-if="ordersExposureLocked">
                    <v-col>
                      <span class="blink font-weight-bold red--text">{{
                        $t("order-edit-orders-exposure")
                      }}</span>
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    v-if="invoiceExposureLocked && !ordersExposureLocked"
                  >
                    <v-col>
                      <span class="blink font-weight-bold red--text">{{
                        $t("order-edit-invoices-exposure")
                      }}</span>
                    </v-col>
                  </v-row>

                  <v-row dense v-if="!num_of_boxes_valid && cart.length > 0">
                    <v-col>
                      <span class="blink font-weight-bold red--text">{{
                        $t("order-edit-num-of-packages-warn")
                      }}</span>
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    v-if="
                      itemsTotal < customer.minimum_int_order_num &&
                        customer.minimum_int_order_num > 0 &&
                        cart.length > 0
                    "
                  >
                    <v-col>
                      <span class="blink font-weight-bold red--text">{{
                        $t("order-edit-num-of-items-warn", {
                          items: customer.minimum_int_order_num
                        })
                      }}</span>
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    v-if="
                      weightTotal < customer.minimum_int_order_weight &&
                        customer.minimum_int_order_weight > 0 &&
                        cart.length > 0
                    "
                  >
                    <v-col>
                      <span class="blink font-weight-bold red--text">{{
                        $t("order-edit-weight-of-items-warn", {
                          weight: customer.minimum_int_order_weight
                        })
                      }}</span>
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    v-if="
                      (!order.purchase_order ||
                        order.purchase_order.length < 1) &&
                        customer.mandatory_po
                    "
                  >
                    <v-col>
                      <span class="blink font-weight-bold red--text">{{
                        $t("order-edit-po-warn")
                      }}</span>
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    v-if="
                      num_of_boxes_valid &&
                        !order.courier_id &&
                        !order.no_shipment &&
                        cart.length > 0
                    "
                  >
                    <v-col>
                      <span class="blink font-weight-bold red--text">{{
                        $t("order-edit-courier-warn")
                      }}</span>
                    </v-col>
                  </v-row>
                </div>

                <div style="height:10px" />
                <v-progress-circular
                  v-if="pushing_order"
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="primary"
                ></v-progress-circular>

                <v-btn
                  v-if="!pushing_order"
                  width="120"
                  :disabled="
                    invalid ||
                      !order.shipment_address_id ||
                      !order.invoice_address_id ||
                      customer_fetching_addresses ||
                      customer_fetching_exposure ||
                      customer_fetching_discount ||
                      customer_fetching_templates ||
                      fetching_template_items ||
                      storing_template ||
                      deleting_template ||
                      !cart_valid ||
                      !num_of_boxes_valid ||
                      (!order.courier_id && !order.no_shipment) ||
                      invoiceExposureLocked ||
                      ordersExposureLocked ||
                      daysExposureLocked
                  "
                  v-on:click="confirm()"
                  color="primary"
                  >{{ $t("gbl-ok") }}</v-btn
                >
                <v-btn
                  v-if="!pushing_order && !editingOrder"
                  width="200"
                  :disabled="
                    invalid ||
                      !order.shipment_address_id ||
                      !order.invoice_address_id ||
                      customer_fetching_addresses ||
                      customer_fetching_exposure ||
                      customer_fetching_discount ||
                      customer_fetching_templates ||
                      fetching_template_items ||
                      storing_template ||
                      deleting_template ||
                      !cart_valid ||
                      !num_of_boxes_valid ||
                      (!order.courier_id && !order.no_shipment) ||
                      invoiceExposureLocked ||
                      ordersExposureLocked ||
                      daysExposureLocked
                  "
                  v-on:click="confirmAndContinue()"
                  color="primary"
                  >{{ $t("order-edit-confirm-and-continue") }}</v-btn
                >
                <v-btn width="120" v-on:click="cancel()" color="secondary">{{
                  $t("gbl-cancel")
                }}</v-btn>
              </div>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

import customersManager from "../apis/customers";
import usersManager from "../apis/users";
import orderManager from "../apis/orders";
import priceListsManager from "../apis/pricelists";
import productsManager from "../apis/products";
import staticData from "../apis/static";
import messageBox from "../components/MessageBox";
import setCurrency from "../components/SetCurrency";
import setQuantity from "../components/SetQuantity";
import setString from "../components/SetString";
import customProduct from "../components/CustomProduct";
import config from "../config";

export default {
  data() {
    return {
      order: {
        customer_id: null,
        shipment_address_id: null,
        invoice_address_id: null,
        user_id: null,
        courier_id: null,
        cart: [],
        state: 0,
        notes: "",
        sales_point: null
      },
      orderStatesOptions: staticData.ordersStates,
      salesPoints: [],
      shipment_on_closed_day: false,
      customer: null,
      customers: [],
      couriers: [],
      searchCustomersInput: null,
      isLoadingCustomers: false,
      num_of_packages: null,

      customer_fetching_addresses: false,
      customer_fetching_exposure: false,
      customer_fetching_discount: false,
      customer_fetching_templates: false,
      fetching_template_items: false,
      storing_template: false,
      deleting_template: false,
      invoice_addresses: [],
      shipment_addresses: [],
      editingOrder: false,
      fetchingCart: false,
      dateModal: false,
      shipmentDateModal: false,
      loanExpirationDateModal: false,
      dateDialogValue: null,
      shipmentDateDialogValue: null,
      loanExpirationDateDialogValue: null,
      user: null,
      users: [],
      templates: [],
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      perPage: staticData.defautPerPage,
      options: {
        sortBy: ["name"],
        sortDesc: [false]
      },
      headers: [
        {
          text: this.$t("order-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "name"
        },
        { text: "", align: "right", sortable: false, value: "actions" }
      ],
      filter: "",
      cart: [],
      select_products: [],
      products: [],
      selected_product: null,
      loading_products: false,
      loading_startup_products: true,
      loading_products_search: null,
      loading_product_price: false,
      imageCache: {},
      disable_customer_watcher: false,
      pushing_order: false
    };
  },

  props: ["value"],

  components: {
    ValidationObserver,
    ValidationProvider,
    messageBox,
    setCurrency,
    setQuantity,
    customProduct,
    setString
  },

  watch: {
    searchCustomersInput(val) {
      clearTimeout(this._searchCustomersInput);
      if (val) {
        this._searchCustomersInput = setTimeout(() => {
          this.isLoadingCustomseters = true;
          this.fetchFiltersCustomers(val)
            .then(res => {
              this.customers = res.customers.map(x => {
                if (x.business_name) x.display_name = x.business_name;
                else x.display_name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomers = false;
            })
            .catch(() => {
              this.isLoadingCustomers = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        if (!this.editingOrder) this.order.customer_id = null;
      }
    },

    "order.shipment_date": {
      handler() {
        this.checkForShipmentDay();
      }
    },

    user: {
      handler() {
        if (!this.editingOrder) {
          if (this.user) {
            this.order.user_id = this.user.id;
            this.order.sales_point = this.user.sales_point
              ? this.user.sales_point
              : 1;
          }
        }
      }
    },

    "order.no_shipment": {
      handler(val) {
        if (val) {
          this.order.courier_id = null;
        }
      }
    },

    customer: {
      handler() {
        if (!this.disable_customer_watcher) {
          if (!this.editingOrder)
            this.order.customer_id = this.customer ? this.customer.id : null;
          if (this.customer) {
            this.fetchCustomerExposure(this.order.customer_id)
              .then(async exposure_result => {
                this.fetchExtraCustomerInfo(this.order.customer_id)
                  .then(extraInfoResult => {
                    this.fetchCustomerTemplates(this.order.customer_id)
                      .then(templates_result => {
                        customersManager
                          .fetchCustomer(this.order.customer_id)
                          .then(async customer_results => {
                            customer_results.customer.days_exposure =
                              exposure_result.elapsed_days;
                            customer_results.customer.invoices_exposures =
                              exposure_result.invoices.net_total;
                            customer_results.customer.orders_exposures =
                              exposure_result.orders.net_total;
                            customer_results.customer.discount_shown =
                              extraInfoResult.show_discount_values;
                            customer_results.templates = templates_result;
                            this.order.customer_id = customer_results.customer
                              ? customer_results.customer.id
                              : null;
                            customer_results.minimum_ext_order_num =
                              extraInfoResult.minimum_ext_order_num;
                            customer_results.minimum_ext_order_weight =
                              extraInfoResult.minimum_ext_order_weight;
                            customer_results.minimum_int_order_num =
                              extraInfoResult.minimum_int_order_num;
                            customer_results.minimum_int_order_weight =
                              extraInfoResult.minimum_int_order_weight;

                            this.fetchCustomerAddresses(
                              customer_results.customer.id
                            ).then(() => {
                              this.disable_customer_watcher = true;
                              this.customer = customer_results.customer;
                              this.checkForShipmentDay();
                              if (
                                !this.customer.products_free_search &&
                                this.customer.price_list_id
                              ) {
                                this.loadSelectProducts();
                              } else {
                                this.loading_startup_products = false;
                              }
                            });
                          })
                          .catch(err => {
                            console.log(err);
                          });
                      })
                      .catch(err => {
                        console.log(err);
                      });
                  })
                  .catch(err => {
                    console.log(err);
                  });
              })
              .catch(err => {
                console.log(err);
              });
          } else {
            this.disable_customer_watcher = false;
          }
        } else {
          this.disable_customer_watcher = false;
        }
      }
    },

    selected_product: {
      handler() {
        if (this.selected_product) {
          const id = this.selected_product.id;
          this.addProduct(this.selected_product).then(() => {
            this.selected_product = null;
            if (this.customer.products_free_search) this.products = [];
            this.buildSelectProducts();
            this.loading_products_search = null;
            this.loading_products = false;
            const orderContainer = document.getElementById("orderContainer");
            orderContainer.scrollTop = orderContainer.scrollHeight;
            const key = id.toString() + "_quantity_text_field";
            const editor = this.$refs[key];
            if (editor[0]) editor[0].focus();
          });
        }
      }
    },

    loading_products_search: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (
            !this.loading_products &&
            this.loading_products_search &&
            this.loading_products_search.length >= 2
          ) {
            this.loading_products = true;
            let ctx = {};
            ctx.currentPage = 1;
            ctx.perPage = null;
            ctx.filter = this.loading_products_search;
            ctx.sortBy = "name";
            ctx.sortDesc = true;
            ctx.sortByCustomerUsage = this.order.customer_id;
            if (this.customer.limit_products_views) {
              ctx.limit_products_views = this.customer.id;
              ctx.price_list_id = this.customer.price_list_id;
            }

            productsManager
              .fetchOrderProducts(ctx)
              .then(result => {
                this.loading_products = false;
                this.products = [];
                for (let index in result.items) {
                  if (!this.isInCart(result.items[index])) {
                    result.items[index].fetchingImage = true;
                    result.items[index].fetchKey = 0;
                    this.fetchProductImage(result.items[index]);
                    let id = result.items[index].id.toString();
                    while (id.length < config.productIdPadding) id = "0" + id;
                    result.items[index].compositeName =
                      id + " - " + result.items[index].name;
                    this.products.push(result.items[index]);
                  }
                }
              })
              .catch(err => {
                console.log(err);
                this.loading_products = false;
              });
          } else if (
            !this.loading_products_search ||
            !this.loading_products_search.length
          ) {
            this.selected_product = null;
            this.products = [];
          }
        }, 300);
      }
    }
  },

  mounted() {
    this.salesPoints = [
      { id: null, disabled: true, name: this.$t("gbl-none-male") },
      ...this.$store.state.sales_points
    ];
  },

  computed: {
    cartTotal() {
      let total = 0;
      for (let index in this.cart) {
        total += this.cart[index].quantity
          ? parseFloat(this.cart[index].quantity.toString().replace(",", ".")) *
            this.cart[index].effective_price
          : 0;
      }
      return total;
    },

    weightTotal() {
      let total = 0;
      for (let index in this.cart) {
        if (this.cart[index].measure_unit == 2) {
          total += this.cart[index].quantity
            ? parseFloat(this.cart[index].quantity.toString().replace(",", "."))
            : 0;
        }
      }
      return total;
    },

    itemsTotal() {
      let total = 0;
      for (let index in this.cart) {
        if (this.cart[index].measure_unit == 1) {
          total += this.cart[index].quantity
            ? parseFloat(this.cart[index].quantity.toString().replace(",", "."))
            : 0;
        }
      }
      return total;
    },

    quantityTotal() {
      return this.cart.length;
    },

    num_of_boxes_valid() {
      if (!this.num_of_packages || parseInt(this.num_of_packages < 1))
        return false;
      return true;
    },

    cart_valid() {
      if (this.cart.length < 1) return false;
      for (let n = 0; n < this.cart.length; n++) {
        let item = this.cart[n];
        let quantity = item.quantity
          ? parseFloat(item.quantity.toString().replace(",", "."))
          : 0;

        if (isNaN(quantity)) return false;
        if (quantity <= 0) return false;
      }

      if (
        this.itemsTotal < this.customer.minimum_int_order_num &&
        this.customer.minimum_int_order_num > 0
      )
        return false;
      if (
        this.weightTotal < this.customer.minimum_int_order_weight &&
        this.customer.minimum_int_order_weight > 0
      )
        return false;

      return true;
    },

    invoiceExposureLocked() {
      if (this.customer) {
        if (this.customer.maximum_unpayed_on_invoices == 0) return false;
        if (this.invoiceExposure > this.customer.maximum_unpayed_on_invoices)
          return true;
        return false;
      } else {
        return false;
      }
    },

    ordersExposureLocked() {
      if (this.customer) {
        if (this.customer.maximum_unpayed_on_orders == 0) return false;
        if (this.ordersExposure > this.customer.maximum_unpayed_on_orders)
          return true;
        return false;
      } else {
        return false;
      }
    },

    daysExposureLocked() {
      if (this.customer && this.customer.days_exposure) {
        if (this.customer.maximum_days_unpayed == 0) return false;
        if (this.daysExposure > this.customer.maximum_days_unpayed) return true;
        return false;
      } else {
        return false;
      }
    },

    invoiceExposure() {
      return (
        (this.customer.orders_exposure ? this.customer.orders_exposure : 0) +
        this.cartTotal
      );
    },

    ordersExposure() {
      return (
        (this.customer.invoices_exposure
          ? this.customer.invoices_exposure
          : 0) + this.cartTotal
      );
    },

    daysExposure() {
      return this.customer.days_exposure ? this.customer.days_exposure : 0;
    }
  },

  methods: {
    templateSelected(item) {
      this.order.invoice_address_id = item.invoice_address_id;
      this.order.shipment_address_id = item.shipment_address_id;
      this.order.sales_point = item.sales_point;
      this.order.no_shipment = item.no_shipment;
      this.num_of_packages = item.num_of_packages;

      this.fetching_template_items = true;
      customersManager.loadOrderTemplate(item.id).then(async template => {
        this.fetching_template_items = false;

        this.cart = [];
        for (let n = 0; n < template.length; n++) {
          let item = template[n];
          await this.addProduct(item, item.quantity);
        }

        this.selected_product = null;
        if (this.customer.products_free_search) this.products = [];
        this.buildSelectProducts();
        this.loading_products_search = null;
        this.loading_products = false;
      });
    },

    removeTemplate(item) {
      this.$refs.msgBox
        .show(
          this.$t("order-edit-remove-template-text"),
          this.$t("order-edit-remove-template-title")
        )
        .then(() => {
          this.deleting_template = true;
          customersManager.deleteOrderTemplate(item.id).then(() => {
            this.deleting_template = false;
            this.fetchCustomerTemplates();
          });
        })
        .catch(() => {});
    },

    handleCustomProduct(event) {
      let product = event.product;
      let handle_vat = event.handle_vat;

      if (handle_vat && product.unit_price && product.vat_percent) {
        product.unit_price =
          parseFloat(product.unit_price) /
          (1.0 + parseFloat(product.vat_percent) / 100);
      }
      product.list_price = parseFloat(product.unit_price).toFixed(2);
      product.price = parseFloat(product.unit_price).toFixed(2);
      product.effective_price = parseFloat(product.unit_price).toFixed(2);
      product.no_stock = 1;
      product.hidden = 0;
      product.stock_amount = 0;
      product.discount = 0;
      product.discount_shown = null;
      product.fetchKey = 0;

      this.cart.push(product);
      this.$refs.customProduct.closeDialog();
    },

    addCustomProduct() {
      this.$refs.customProduct.show(false);
    },

    addCustomProductVat() {
      this.$refs.customProduct.show(true);
    },

    buildSelectProducts() {
      this.select_products = [];
      for (let n = 0; n < this.products.length; n++) {
        let product = this.products[n];
        if (!this.isInCart(product)) this.select_products.push(product);
      }
    },

    loadSelectProducts() {
      // Featch all the products
      this.loading_startup_products = true;
      let ctx = {};
      ctx.currentPage = 1;
      ctx.perPage = null;
      ctx.filter = null;
      ctx.sortBy = "name";
      ctx.sortDesc = true;
      ctx.sortByCustomerUsage = this.order.customer_id;
      ctx.limit_products_views = this.customer.id;
      ctx.price_list_id = this.customer.price_list_id;
      ctx.customer_id = this.order.customer_id;
      productsManager
        .fetchOrderProducts(ctx)
        .then(result => {
          this.products = [];
          for (let index in result.items) {
            result.items[index].fetchingImage = true;
            result.items[index].fetchKey = 0;
            this.fetchProductImage(result.items[index]);
            let id = result.items[index].id.toString();
            while (id.length < config.productIdPadding) id = "0" + id;
            result.items[index].compositeName =
              id + " - " + result.items[index].name;
            this.products.push(result.items[index]);
          }
          this.buildSelectProducts();
          this.loading_startup_products = false;
        })
        .catch(err => {
          console.log(err);
          this.loading_startup_products = false;
        });
    },

    isQuantityNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 44
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    checkForShipmentDay() {
      this.shipment_on_closed_day = false;

      if (this.customer && this.order.shipment_date) {
        let day = new Date(Date.parse(this.order.shipment_date)).getDay();
        if (day == 0) day = 7;
        if (
          this.customer.closed_days &&
          this.customer.closed_days
            .split(",")
            .map(x => {
              return parseInt(x);
            })
            .includes(day)
        ) {
          this.shipment_on_closed_day = true;
        }
      }
    },

    fetchFiltersCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter, true)
          .then(results => {
            results.customers = results.customers.map(x => {
              if (!x.price_list) x.price_list = this.$t("gbl-none-male");
              return x;
            });
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    isNotObject(item) {
      return typeof item !== "object";
    },

    formatQuantity(q) {
      if (q) return this.$options.filters.toCurrency(q);
      else return "";
    },

    getQuantityStyle(item) {
      return !item.no_stock &&
        (item.quantity
          ? parseFloat(item.quantity.toString().replace(",", "."))
          : 0) > item.stock_amount
        ? "color:red"
        : "";
    },

    isInCart(item) {
      for (let index in this.cart) {
        if (this.cart[index].id == item.id) return true;
      }
      return false;
    },

    getProductCode(item) {
      if (item && item.id) {
        let padding = item.id.toString();
        while (padding.length < config.productIdPadding)
          padding = "0" + padding;
        return padding;
      }
      return "";
    },

    fetchProductPrice(customer_id, item_id) {
      return new Promise(function(resolve, reject) {
        priceListsManager
          .fetchProductPrice(customer_id, item_id)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    fetchProductImage(item) {
      return new Promise((resolve, reject) => {
        if (item.fetchingImage) {
          if (!this.imageCache[item.id]) {
            productsManager
              .fetchProductImage(item.id)
              .then(results => {
                if (results.image) {
                  item.fetchedImage = "data:image/png;base64," + results.image;
                  this.imageCache[item.id] = results.image;
                  item.fetchKey++;
                  item.fetchingImage = false;
                  resolve();
                } else {
                  item.fetchedImage = null;
                  item.fetchKey++;
                  item.fetchingImage = false;
                  resolve();
                }
              })
              .catch(err => {
                console.log(err);
                item.fetchedImage = null;
                item.fetchKey++;
                item.fetchingImage = false;
                resolve();
              });
          } else {
            item.fetchedImage =
              "data:image/png;base64," + this.imageCache[item.id];
            item.fetchingImage = false;
            item.fetchKey++;
            resolve();
          }
        } else {
          reject();
        }
      });
    },

    addProduct(item, quantity) {
      return new Promise(resolve => {
        this.loading_product_price = true;
        var cart_item = Object.assign({}, item);
        if (!quantity) cart_item.quantity = null;
        else cart_item.quantity = quantity;
        this.fetchProductPrice(this.order.customer_id, cart_item.id)
          .then(prices => {
            cart_item.list_price = prices.list_price;
            cart_item.price = prices.price;
            cart_item.effective_price = prices.list_price;
            cart_item.vat_percent = prices.vat_percent;
            cart_item.discount = prices.discount;
            cart_item.product_prefix = prices.product_prefix;
            cart_item.discount_shown = prices.discount_shown;
            this.fetchProductImage(cart_item)
              .then(() => {
                this.cart.push(cart_item);
                this.loading_product_price = false;
                if (!quantity) {
                  const orderContainer = document.getElementById(
                    "orderContainer"
                  );
                  orderContainer.scrollTop = orderContainer.scrollHeight;
                }
                resolve(cart_item.id);
              })
              .catch(() => {
                this.cart.push(cart_item);
                this.loading_product_price = false;
                resolve();
              });
          })
          .catch(err => {
            console.log(err);
            this.loading_product_price = false;
            resolve();
          });
      });
    },

    saveDateDialog: function() {
      this.dateModal = false;
      this.shipmentDateModal = false;
      this.order.creation_date = this.dateDialogValue;
    },

    saveShipmentDateDialog: function() {
      this.shipmentDateModal = false;
      this.order.shipment_date = this.shipmentDateDialogValue;
    },

    openDateDialog: function() {
      this.dateModal = true;
    },

    openShipmentDateDialog: function() {
      this.shipmentDateModal = true;
    },

    openLoanExpirationDateDialog: function() {
      this.loanExpirationDateModal = true;
    },

    saveLoanExpirationDateDialog: function() {
      this.loanExpirationDateModal = false;
      this.order.loan_expiration = this.loanExpirationDateDialogValue;
    },

    confirm: function() {
      this.handleSubmit(false);
    },

    confirmAndContinue: function() {
      this.handleSubmit(true);
    },

    cancel: function() {
      this.resetAfterAdd();
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    closeDialog: function() {
      this.cancel();
    },

    handleSubmit(continueInsert) {
      this.pushing_order = true;
      this.order.num_of_packages = this.num_of_packages;
      this.order.cart = [];
      for (let index in this.cart) {
        let item = this.cart[index];
        let object = {
          id: item.id,
          price: item.price,
          effective_price: item.effective_price,
          list_price: item.list_price,
          quantity: item.quantity
            ? parseFloat(item.quantity.toString().replace(",", "."))
            : null,
          vat_percent: item.vat_percent,
          custom: item.custom,
          measure_unit: item.custom ? item.measure_unit : null,
          name: item.custom ? item.name : null,
          product_prefix: item.product_prefix,
          discount_shown: item.discount_shown
        };
        this.order.cart.push(object);
      }

      this.$emit("orderConfigured", {
        editingOrder: this.editingOrder,
        order: this.order,
        continueInsert: continueInsert
      });
    },

    resetAfterAdd() {
      this.pushing_order = false;
      this.invoice_addresses = [
        { id: null, address: this.$t("gbl-none-male"), disabled: true }
      ];
      this.shipment_addresses = [
        { id: null, address: this.$t("gbl-none-male"), disabled: true }
      ];
      this.customer = null;
      this.cart = [];
      this.num_of_packages = null;
      this.order = {
        user: this.user,
        customer_id: null,
        shipment_address_id: null,
        invoice_address_id: null,
        purchase_order: null,
        user_id: this.user ? this.user.id : null,
        courier_id: null,
        no_shipment: false,
        num_of_packages: null,
        cart: [],
        state: 0,
        notes: "",
        sales_point: this.user ? this.user.sales_point : null,
        creation_date: new Date().toISOString(),
        shipment_date: new Date().toISOString(),
        loan_expiration: new Date().toISOString()
      };
    },

    setModalOrder(order, editing) {
      return new Promise(async (resolve, reject) => {
        if (!editing) {
          this.order = Object.assign(
            {
              creation_date: new Date().toISOString(),
              shipment_date: new Date().toISOString(),
              loan_expiration: new Date().toISOString()
            },
            order
          );
          this.num_of_packages = null;
        } else {
          this.order = Object.assign({}, order);
          this.num_of_packages = this.order.num_of_packages;
        }

        this.editingOrder = editing;
        this.pushing_order = false;
        this.select_products = [];

        usersManager
          .fetchCouriers()
          .then(async res => {
            this.couriers = [
              {
                id: null,
                name: this.$t("order-edit-select-courier"),
                disabled: true
              },
              ...res.items
            ];

            await this.reset(editing);

            resolve();
          })
          .catch(async err => {
            console.log(err);
            await this.reset(editing);
            reject();
          });
      });
    },

    reset(editing) {
      this.imageCache = {}; // Always reset the cache
      this.invoice_addresses = [
        { id: null, address: this.$t("gbl-none-male"), disabled: true }
      ];
      this.shipment_addresses = [
        { id: null, address: this.$t("gbl-none-male"), disabled: true }
      ];
      this.customer = null;
      this.cart = [];

      return new Promise(async (resolve, reject) => {
        // Fetch cart items and customer details as well as addresses
        if (editing) {
          this.fetchingCart = true;
          customersManager
            .fetchCustomer(this.order.customer_id)
            .then(result => {
              this.disable_customer_watcher = true;
              this.customer = result.customer;
              this.fetchExtraCustomerInfo(this.order.customer.id)
                .then(result => {
                  this.customer.discount_shown = result;
                  this.fetchCustomerExposure(this.order.customer.id)
                    .then(result => {
                      this.customer.invoices_exposures =
                        result.invoices.net_total;
                      this.customer.orders_exposures = result.orders.net_total;
                      this.customer.invoices_exposures = result.elapsed_days;
                      customersManager
                        .fetchAddresses(this.customer.id, false)
                        .then(async results => {
                          this.shipment_addresses = [
                            {
                              id: null,
                              address: this.$t("gbl-none-male"),
                              disabled: true
                            },
                            ...results.shipment_addresses
                          ];
                          this.invoice_addresses = [
                            {
                              id: null,
                              address: this.$t("gbl-none-male"),
                              disabled: true
                            },
                            ...results.invoice_addresses
                          ];

                          this.checkForShipmentDay();
                          // Fill in the cart with the order items
                          await orderManager
                            .fetchOrderCart(this.order.id)
                            .then(async results => {
                              for (let index in results.cart) {
                                let cart_item = results.cart[index];
                                cart_item.fetchingImage = true;
                                cart_item.fetchKey = 0;
                                if (!cart_item.no_stock)
                                  cart_item.stock_amount += parseFloat(
                                    cart_item.quantity
                                      .toString()
                                      .replace(",", ".")
                                  ); // add own quantity to available stock amount
                                this.cart.push(cart_item);
                                this.fetchProductImage(cart_item);
                              }
                              this.loadSelectProducts();
                            });
                        })
                        .catch(err => {
                          console.log(err);
                        });
                    })
                    .catch(err => {
                      console.log(err);
                    });
                })
                .catch(err => {
                  console.log(err);
                });
            })
            .catch(err => {
              console.log(err);
            });
          this.fetchingCart = false;
        } else {
          this.checkForShipmentDay();
        }

        if (this.$store.state.hasRight("180")) {
          usersManager
            .fetchUsersNames()
            .then(users => {
              this.users = [
                {
                  id: null,
                  name: this.$t("order-edit-select-user"),
                  sales_point: null,
                  disabled: true
                },
                ...users.items.map(x => {
                  x.name = x.first_name + " " + x.last_name;
                  return x;
                })
              ];
              if (!editing) {
                this.user = null;
                this.order.sales_point = null;
              } else {
                for (let n = 0; n < this.users.length; n++) {
                  if (this.users[n].id == this.order.user_id) {
                    this.user = this.users[n];
                    break;
                  }
                }
              }

              resolve();
            })
            .catch(() => {
              this.user = null;
              reject();
            });
        } else {
          if (!editing) {
            this.user = {
              id: this.$store.state.user.id,
              sales_point: this.$store.state.user.sales_point,
              first_name: this.$store.state.user.first_name,
              last_name: this.$store.state.user.last_name
            };
            this.order.user_id = this.user.id;
            this.order.sales_point = this.user.sales_point;
          } else {
            for (let n = 0; n < this.users.length; n++) {
              if (this.users[n].id == this.order.user_id) {
                this.user = this.users[n];
                break;
              }
            }
          }
          resolve();
        }
      });
    },

    fetchCustomerExposure() {
      this.customer_fetching_exposure = true;
      return new Promise((resolve, reject) => {
        customersManager
          .fetchExposure(this.customer.id, false)
          .then(results => {
            this.customer_invoices_exposure = 0;
            this.customer_orders_exposure = 0;
            this.customer_fetching_exposure = false;
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            this.customer_fetching_exposure = false;
            reject(err);
          });
      });
    },

    fetchCustomerTemplates() {
      this.templates = [];
      this.customer_fetching_templates = true;
      return new Promise((resolve, reject) => {
        customersManager
          .fetchTemplates(this.customer.id, false)
          .then(results => {
            this.customer_fetching_templates = false;
            this.templates = results;
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            this.customer_fetching_templates = false;
            reject(err);
          });
      });
    },

    fetchExtraCustomerInfo() {
      this.customer_fetching_discount = true;
      return new Promise((resolve, reject) => {
        customersManager
          .fetchExtraInfo(this.customer.id, false)
          .then(results => {
            this.customer_fetching_discount = false;
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            this.customer_fetching_discount = false;
            reject(err);
          });
      });
    },

    fetchCustomerAddresses() {
      this.customer_fetching_addresses = true;
      this.addresses = [];
      return new Promise((resolve, reject) => {
        customersManager
          .fetchAddresses(this.customer.id, false)
          .then(results => {
            this.invoice_addresses = [
              { id: null, address: this.$t("gbl-none-male"), disabled: true },
              ...results.invoice_addresses
            ];
            this.shipment_addresses = [
              { id: null, address: this.$t("gbl-none-male"), disabled: true },
              ...results.shipment_addresses
            ];
            this.customer_fetching_addresses = false;
            if (results.invoice_addresses.length == 1)
              this.order.invoice_address_id = results.invoice_addresses[0].id;
            if (results.shipment_addresses.length == 1)
              this.order.shipment_address_id = results.shipment_addresses[0].id;
            if (results.shipment_addresses.length == 1)
              this.order.courier_id =
                results.shipment_addresses[0].default_courier;

            if (
              !this.order.courier_id &&
              !this.editingOrder &&
              this.$store.state.hasRight("183") &&
              !this.$store.state.hasRight("189") &&
              !this.$store.state.isAdmin() &&
              !this.order.no_shipment
            ) {
              this.order.courier_id = this.$store.state.user.id;
            }

            resolve();
          })
          .catch(err => {
            console.log(err);
            this.customer_fetching_addresses = false;
            reject(err);
          });
      });
    },

    editPrice(item) {
      this.$refs.setCurrency
        .show(
          this.$t("order-edit-set-price-text"),
          this.$t("order-edit-set-price-hint"),
          this.$t("order-edit-set-price-title"),
          item.effective_price,
          item,
          true,
          false
        )
        .then(event => {
          item.effective_price = parseFloat(
            event.value.replace(",", ".")
          ).toFixed(2);
          item.discount_shown = 0;
        })
        .catch(() => {});
    },

    setDiscount(item) {
      this.$refs.setCurrency
        .show(
          this.$t("order-edit-set-discount-text"),
          this.$t("order-edit-set-discount-hint"),
          this.$t("order-edit-set-discount-title"),
          0,
          item,
          true,
          false
        )
        .then(event => {
          item.effective_price = (
            item.price -
            (item.price * event.value) / 100
          ).toFixed(2);
          item.discount_shown = parseFloat(
            event.value.replace(",", ".")
          ).toFixed(2);
        })
        .catch(() => {});
    },

    editQuantity(item) {
      this.$refs.setQuantity
        .show(
          this.$t("order-edit-set-quantity-text"),
          this.$t("order-edit-set-quantity-hint"),
          this.$t("order-edit-set-quantity-title"),
          item.quantity,
          item,
          true,
          false
        )
        .then(event => {
          item.quantity = parseFloat(event.value.replace(",", ".")).toFixed(2);
        })
        .catch(() => {});
    },

    removeItem(item) {
      this.$refs.msgBox
        .show(
          this.$t("order-edit-remove-text"),
          this.$t("order-edit-decrease-remove-title")
        )
        .then(() => {
          this.cart.splice(this.cart.indexOf(item), 1);
          this.buildSelectProducts();
        })
        .catch(() => {});
    },

    addOrderTemplate() {
      this.$refs.setString
        .show(
          this.$t("order-edit-add-template-title"),
          this.$t("order-edit-add-template-hint"),
          this.$t("order-edit-add-template-text"),
          "",
          null,
          true,
          false
        )
        .then(event => {
          this.storing_template = true;
          customersManager
            .addOrdersTemplate({
              customer_id: this.order.customer_id,
              name: event.value,
              cart: this.cart.map(x => {
                return { id: x.id, quantity: parseFloat(x.quantity) };
              }),
              shipment_address_id: this.order.shipment_address_id,
              invoice_address_id: this.order.invoice_address_id,
              sales_point: this.order.sales_point,
              no_shipment: this.order.no_shipment,
              num_of_packages: this.num_of_packages
            })
            .then(() => {
              this.storing_template = false;
              this.fetchCustomerTemplates();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    increaseQuantity(item) {
      item.quantity++;
    },

    decreaseQuantity(item) {
      if (item.quantity == 1) {
        this.$refs.msgBox
          .show(
            this.$t("order-edit-decrease-remove-text"),
            this.$t("order-edit-decrease-remove-title")
          )
          .then(() => {
            if (item.quantity > 0) item.quantity--;
            if (item.quantity <= 0) this.cart.splice(this.cart.indexOf(item));
          })
          .catch(() => {});
      } else {
        if (item.quantity > 0) item.quantity--;
        if (item.quantity <= 0) this.cart.splice(this.cart.indexOf(item));
      }
    }
  }
};
</script>

<style scoped>
.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
