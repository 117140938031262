<template>
  <div>
    <messageBox ref="msgBox" />
    <setString ref="setString" />

    <v-container fluid class="px-6 py-6 mb-0 mt-0">
      <v-row dense>
        <v-col>
          <base-material-stats-card
            color="primary"
            icon="mdi-currency-eur"
            :title="$t('invoicesexpired-total-net-value')"
            :value="computeTotalExpired"
          />
        </v-col>
        <v-col>
          <base-material-stats-card
            color="success"
            icon="mdi-currency-eur"
            :title="$t('invoicesexpired-total-expired-now')"
            :value="computeExpiredNow"
          /> </v-col
        ><v-col>
          <base-material-stats-card
            color="warning"
            icon="mdi-currency-eur"
            :title="$t('invoicesexpired-total-expired-30')"
            :value="computeExpired30"
          /> </v-col
        ><v-col>
          <base-material-stats-card
            color="error"
            icon="mdi-currency-eur"
            :title="$t('invoicesexpired-total-expired-60')"
            :value="computeExpired60"
          /> </v-col
        ><v-col>
          <base-material-stats-card
            color="error"
            icon="mdi-currency-eur"
            :title="$t('invoicesexpired-total-expired-90-plus')"
            :value="computeExpired90"
          />
        </v-col>
      </v-row>
    </v-container>

    <base-material-card
      color="primary"
      icon="mdi-cube-send"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-1 font-weight-light card-header">
          {{ $t("invoicesexpired-title") }}
        </div>
      </template>

      <div style="height:10px" />

      <div class="vertical-spacer" />

      <FiltersBar
        :filters="filters"
        :disabled="loadingInvoices"
        @onFiltersLoading="onFiltersLoading"
        @onFiltersLoaded="onFiltersLoaded"
        :showLoader="true"
        :ExpirationFilter="true"
      />

      <v-card-text class="px-0 py-0">
        <v-data-table
          class="grid-table"
          :items-per-page="options.itemsPerPage"
          :must-sort="true"
          :headers="headers"
          :items="invoices"
          :page.sync="detachedPageHandler"
          :options.sync="options"
          :server-items-length="totalInvoices"
          :loading="loadingInvoices"
          :footer-props="dataTableFooterOptions"
        >
          <template v-slot:item.document_date="{ item }">
            {{ item.document_date | UTCToLocalDate }}
          </template>

          <template v-slot:item.actions="{ item }">
            <div>
              <v-tooltip v-if="!item.notes" bottom
                ><template v-slot:activator="{ on }">
                  <v-btn
                    @click.stop="setNotes(item)"
                    x-small
                    text
                    icon
                    v-on="on"
                    ><v-icon>mdi-information-outline</v-icon></v-btn
                  > </template
                ><span>{{ $t("invoicesexpired-set-notes") }}</span></v-tooltip
              >

              <v-tooltip v-if="item.notes" bottom
                ><template v-slot:activator="{ on }">
                  <v-btn
                    @click.stop="setNotes(item)"
                    x-small
                    text
                    icon
                    color="#bf126c"
                    v-on="on"
                    ><v-icon>mdi-information-outline</v-icon></v-btn
                  > </template
                ><span>{{ item.notes }}</span></v-tooltip
              >
            </div>
          </template>

          <template v-slot:item.business_name="{ item }">
            {{
              item.business_name
                ? item.business_name
                : item.first_name + " " + item.last_name
            }}
          </template>

          <template v-slot:item.total_not_expired="{ item }">
            {{ item.total_not_expired | toCurrency }}
          </template>

          <template v-slot:item.total_exposition="{ item }">
            {{ item.total_exposition | toCurrency }}
          </template>

          <template v-slot:item.total_expired="{ item }">
            {{ item.total_expired | toCurrency }}
          </template>

          <template v-slot:item.expired_now="{ item }">
            {{ item.expired_now | toCurrency }}
          </template>
          <template v-slot:item.expired_30="{ item }">
            {{ item.expired_30 | toCurrency }}
          </template>
          <template v-slot:item.expired_60="{ item }">
            {{ item.expired_60 | toCurrency }}
          </template>
          <template v-slot:item.expired_90="{ item }">
            {{ item.expired_90 | toCurrency }}
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
  </div>
</template>

<script>
import staticData from "@/apis/static";
import ordersManager from "@/apis/orders";
import usersManager from "@/apis/users";
import customersManager from "@/apis/customers";

import config from "@/config";
import localStorageService from "@/services/localStorageService";

import setString from "../components/SetString";
import FiltersBar from "../components/FiltersBar";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      registry_types: [],
      partitions: [],
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      detachedPageHandler: 1,
      options: {
        sortBy: ["total_expired"],
        sortDesc: [true]
      },

      headers: [],
      perPage: staticData.defautPerPage,
      locations: [],
      fetching_data: false,
      textFilter: null,
      warningsFilters: [],
      filters: {
        filterByCustomerId: null,
        filterByPeriod: 14,
        filterByLabLocation: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByOrderState: null,
        filterByInvoiceNumbers: null,
        filterByPartitionNumbers: null,
        filterByInvoiceYear: null,
        filterByDocumentType: null,
        filterByRegistryType: null,
        filterByPartition: null,
        filterByWarningState: null,
        filterByAgentId: null
      },
      invoices: [],
      totalInvoices: 0,
      loadingInvoices: false,
      loadingFilters: true,
      exporting_invoices: false,
      counters: {},
      searchAccountHolderInput: null,
      flt_accountHolders: [],
      isLoadingAccountHolder: false,
      invoiceServices: [],
      invoicesServicesDetailsDialog: false,

      isLoadingAgent: false,
      searchAgentInput: null,
      agents: []
    };
  },

  mounted() {
    this.loadingFilters = true;
    this.warningsFilters = [
      {
        id: null,
        name: this.$t("gbl-all-male")
      },
      {
        id: 1,
        name: this.$t("invoicesexpired-filter-warning-expired-not-sent-30")
      },
      {
        id: 2,
        name: this.$t("invoicesexpired-filter-warning-expired-not-sent-60")
      },
      {
        id: 3,
        name: this.$t("invoicesexpired-filter-warning-expired-not-sent-90")
      },
      {
        id: 4,
        name: this.$t("invoicesexpired-filter-warning-expired-sent-30")
      },
      {
        id: 5,
        name: this.$t("invoicesexpired-filter-warning-expired-sent-60")
      },
      {
        id: 6,
        name: this.$t("invoicesexpired-filter-warning-expired-sent-90")
      }
    ];

    this.fetchPaymentsMethods().then(res => {
      this.paymentMethods = res.map(x => {
        return { id: x.id, name: x.description, type: x.type };
      });
      this.paymentMethodsById = {};
      for (let x of res) {
        this.paymentMethodsById[x.id] = x;
      }
      this.loadingFilters = false;
    });

    this.headers = [
      {
        text: this.$t("invoicesexpired-tbl-customer"),
        align: "left",
        sortable: true,
        value: "business_name"
      },

      {
        text: this.$t("invoicesexpired-tbl-total-exposition"),
        align: "left",
        sortable: true,
        value: "total_exposition"
      },
      {
        text: this.$t("invoicesexpired-tbl-total-no-expired"),
        align: "left",
        sortable: true,
        value: "total_not_expired"
      },
      {
        text: this.$t("invoicesexpired-tbl-total"),
        align: "left",
        sortable: true,
        value: "total_expired"
      },

      {
        text: this.$t("invoicesexpired-tbl-expired-now"),
        align: "left",
        sortable: true,
        value: "expired_now"
      },
      {
        text: this.$t("invoicesexpired-tbl-expired-30"),
        align: "left",
        sortable: true,
        value: "expired_30"
      },

      {
        text: this.$t("invoicesexpired-tbl-expired-60"),
        align: "left",
        sortable: true,
        value: "expired_60"
      },
      {
        text: this.$t("invoicesexpired-tbl-expired-90plus"),
        align: "left",
        sortable: true,
        value: "expired_90"
      },
      {
        text: "",
        align: "right",
        sortable: false,
        value: "actions"
      }
    ];
  },

  components: {
    messageBox,
    FiltersBar,
    setString
  },

  watch: {
    searchAccountHolderInput(val) {
      if (!this.loadingInvoice) {
        clearTimeout(this._searchAccountHolderInput);
        if (val) {
          this._searchAccountHolderInput = setTimeout(() => {
            this.isLoadingAccountHolder = true;
            this.fetchCustomer(val)
              .then(res => {
                this.flt_accountHolders = res.map(x => {
                  x.display_name = x.name;
                  return x;
                });
                this.isLoadingAccountHolder = false;
              })
              .catch(() => {
                this.isLoadingAccountHolder = false;
              });
          }, config.searchInputsFastTimeout);
        }
      }
    },

    options: {
      handler() {
        if (this.detachedPageHandler != 1) this.detachedPageHandler = 1;
        else this.fetchExpirations();
      },
      deep: true
    },

    detachedPageHandler: {
      handler() {
        this.fetchExpirations();
      }
    },

    textFilter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.detachedPageHandler != 1) this.detachedPageHandler = 1;
          else this.fetchExpirations();
        }, config.searchInputsTimeout);
      }
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.detachedPageHandler != 1) this.detachedPageHandler = 1;
          else this.fetchExpirations();
        }, config.searchInputsTimeout);
      },
      deep: true
    }
  },

  computed: {
    filterByPeriodText() {
      return staticData.statisticsPeriods[this.filters.filterByPeriod].name;
    },

    computeTotalExpired() {
      if (this.counters && this.counters.total_expired)
        return this.$options.filters.toCurrency(
          this.counters.total_expired.toFixed(2)
        );
      return "...";
    },

    computeExpiredNow() {
      if (this.counters && this.counters.expired_now)
        return this.$options.filters.toCurrency(
          this.counters.expired_now.toFixed(2)
        );
      return "...";
    },

    computeExpired30() {
      if (this.counters && this.counters.expired_30)
        return this.$options.filters.toCurrency(
          this.counters.expired_30.toFixed(2)
        );
      return "...";
    },

    computeExpired60() {
      if (this.counters && this.counters.expired_60)
        return this.$options.filters.toCurrency(
          this.counters.expired_60.toFixed(2)
        );
      return "...";
    },

    computeExpired90() {
      if (this.counters && this.counters.expired_90)
        return this.$options.filters.toCurrency(
          this.counters.expired_90.toFixed(2)
        );
      return "...";
    }
  },

  methods: {
    onFiltersLoading() {
      this.loadingFilters = true;
    },

    onFiltersLoaded() {
      this.loadingFilters = false;
    },

    setNotes(item) {
      this.$refs.setString
        .show(
          this.$t("invoicesexpired-add-notes-title"),
          this.$t("invoicesexpired-add-notes-hint"),
          this.$t("invoicesexpired-add-notes-text"),
          item.notes,
          null,
          false,
          false
        )
        .then(event => {
          customersManager
            .setExpirationNotes(item.id, event.value)
            .then(() => {
              this.fetchExpirations();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    sendWarning(item, level) {
      this.$refs.msgBox
        .show(
          this.$t("invoicesexpired-confirm-warning-text"),
          this.$t("invoicesexpired-confirm-warning-title")
        )
        .then(() => {
          this.$store.state.global_overlay = true;
          this.$store.state.global_overlay_text = this.$t(
            "invoicesexpired-expiration-sending-mail"
          );

          customersManager
            .sendExpirationWarning(item.id, level)
            .then(() => {
              this.$store.state.global_overlay = false;
              this.fetchExpirations();
            })
            .catch(err => {
              this.$store.state.global_overlay = false;
              console.log(err);
            });
        })
        .catch(() => {});
    },

    resetWarnings(item) {
      this.$refs.msgBox
        .show(
          this.$t("invoicesexpired-confirm-reset-text"),
          this.$t("invoicesexpired-confirm-reset-title")
        )
        .then(() => {
          customersManager
            .resetExpirationWarning(item.id)
            .then(() => {
              this.fetchExpirations();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    lockWarnings(item, state) {
      customersManager
        .lockWarnings(item.id, state)
        .then(() => {
          this.fetchExpirations();
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadExpirationWarning(item) {
      return new Promise((resolve /*, reject*/) => {
        const link = document.createElement("a");
        const token = localStorageService.getAccessToken();
        const url =
          config.apiEndPoint +
          "/customers/downloadExpirationWarningPDF?id=" +
          encodeURIComponent(item.id) +
          "&token=" +
          encodeURIComponent(token);
        link.setAttribute("href", url);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.remove();
        resolve();
      });
    },

    fetchCustomer(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchAnyCustomer(filter, null)
          .then(results => {
            resolve(results.customers);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchPaymentsMethods() {
      return new Promise(resolve => {
        resolve([]);
      });
    },

    getPeriodFilters: () => {
      return staticData.statisticsPeriods;
    },

    getPaymentState(item) {
      let state = staticData.invoicesPaymentsStates[item.payment_state];
      if (item.payment_date)
        state +=
          " - " + this.$options.filters.UTCToLocalDate(item.payment_date);

      return state;
    },

    fetchExpirations() {
      this.loadingInvoices = true;
      this.totalInvoices = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = this.detachedPageHandler;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.textFilter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByInvoiceState = this.filters.filterByInvoiceState;
      ctx.filterByInvoiceNumbers = this.filters.filterByInvoiceNumbers;
      ctx.filterByPartitionNumbers = this.filters.filterByPartitionNumbers;
      ctx.filterByInvoiceYear = this.filters.filterByInvoiceYear;
      ctx.filterByDocumentType = this.filters.filterByDocumentType;
      ctx.filterByLabLocation = this.filters.filterByLabLocation;
      ctx.filterByRegistryType = this.filters.filterByRegistryType;
      ctx.filterByPartition = this.filters.filterByPartition;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByWarningState = this.filters.filterByWarningState;
      ctx.filterByAgentId = this.filters.filterByAgentId;

      ordersManager
        .fetchExpirations(ctx)
        .then(result => {
          this.totalInvoices = result.totalCount;
          this.invoices = result.items;
          this.counters = result.counters;
          this.loadingInvoices = false;
        })
        .catch(err => {
          this.loadingInvoices = false;
          console.log(err);
        });
    },

    downloadReportExcel() {
      let ctx = Object.assign({}, this.options);
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByInvoiceState = this.filters.filterByInvoiceState;
      ctx.filterByInvoiceNumbers = this.filters.filterByInvoiceNumbers;
      ctx.filterByPartitionNumbers = this.filters.filterByPartitionNumbers;
      ctx.filterByInvoiceYear = this.filters.filterByInvoiceYear;
      ctx.filterByDocumentType = this.filters.filterByDocumentType;
      ctx.filterByLabLocation = this.filters.filterByLabLocation;
      ctx.filterByRegistryType = this.filters.filterByRegistryType;
      ctx.filterByPartition = this.filters.filterByPartition;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;

      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadInvoicesExcel(ctx)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadReportXMLZip() {
      this.$refs.msgBox
        .show(
          this.$t("invoicesmng-sync-zip-text"),
          this.$t("invoicesmng-sync-zip-text")
        )
        .then(res => {
          let ctx = Object.assign({}, this.options);
          ctx.currentPage = ctx.page;
          ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
          ctx.filter = this.filter;
          ctx.sortBy = ctx.sortBy[0];
          ctx.sortDesc = ctx.sortDesc[0];
          ctx.filterByPeriod = this.filters.filterByPeriod;
          ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
          ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
          ctx.filterByCustomerId = this.filters.filterByCustomerId;
          ctx.filterByInvoiceState = this.filters.filterByInvoiceState;
          ctx.filterByInvoiceNumbers = this.filters.filterByInvoiceNumbers;
          ctx.filterByPartitionNumbers = this.filters.filterByPartitionNumbers;
          ctx.filterByInvoiceYear = this.filters.filterByInvoiceYear;
          ctx.filterByDocumentType = this.filters.filterByDocumentType;
          ctx.filterByLabLocation = this.filters.filterByLabLocation;

          ctx.filterByRegistryType = this.filters.filterByRegistryType;
          ctx.filterByPartition = this.filters.filterByPartition;
          ctx.filterByCustomerId = this.filters.filterByCustomerId;

          usersManager
            .keepAlive()
            .then(() => {
              ordersManager
                .downloadInvoicesXMLZip(ctx, res)
                .then(() => {})
                .catch(err => {
                  console.log(err);
                });
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    }
  }
};
</script>
