<template>
  <v-dialog persistent overlay-opacity="0.6" max-width="800" :value="open">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0 " color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col
                  align="center"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("set-coordinates-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <v-row>
                <v-col
                  ><v-text-field
                    :label="$t('set-coordinates-latitude')"
                    outlined
                    dense
                    hide-details
                    v-model="latitude"
                  ></v-text-field
                ></v-col>
                <v-col
                  ><v-text-field
                    :label="$t('set-coordinates-latitude')"
                    outlined
                    hide-details
                    dense
                    v-model="longitude"
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-card-text>
            <v-btn width="120" @click="confirm()" color="primary">{{
              $t("msgbox-ok")
            }}</v-btn>
            <v-btn width="120" @click="dismiss()" color="secondary">{{
              $t("msgbox-cancel")
            }}</v-btn>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      latitude: null,
      longitude: null,
      open: false
    };
  },

  methods: {
    show(lat, long) {
      return new Promise((rs, rj) => {
        this.resolve = rs;
        this.reject = rj;
        this.latitude = lat;
        this.longitude = long;
        this.open = true;
      });
    },

    hide() {
      this.open = false;
      this.reject();
    },

    confirm() {
      this.open = false;
      this.resolve({ latitude: this.latitude, longitude: this.longitude });
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    }
  }
};
</script>
