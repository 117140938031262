import axios from "axios";
import config from "../config";

let dashboard = new Object({
  fetchFinancialDashboard: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/dashboard/financial",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            stats: resp.data.responseData.stats,
            payed: resp.data.responseData.payed,
            unpayed: resp.data.responseData.unpayed,
            topProducts: resp.data.responseData.topProducts,
            topCustomers: resp.data.responseData.topCustomers,
            ordersCount: resp.data.responseData.ordersCount,
            customersCount: resp.data.responseData.customersCount,
            topProductsCountResults:
              resp.data.responseData.topProductsCountResults,
            topCustomersCountResults:
              resp.data.responseData.topCustomersCountResults,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
});

export default dashboard;
