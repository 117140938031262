<template>
  <OrdersManager />
</template>

<script>
import OrdersManager from "../views/OrdersManager";

export default {
  components: {
    OrdersManager
  }
};
</script>
