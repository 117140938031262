<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-dialog
        overlay-opacity="0.6"
        persistent
        max-width="600"
        :value="paymentsResultsDialog"
      >
        <!-- @input is required to emit emit input if clicked outside unless we use persistent -->

        <v-container fill-height fluid grid-list-xl>
          <v-layout style="max-height:600px" justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="primary">
                <template v-slot:heading>
                  <v-row class="pa-0">
                    <v-col cols="1" class="pa-0"
                      ><v-icon large>mdi-currency-eur</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="10"
                      class="pa-0 card-title-text"
                      style="font-size: 25px"
                      >{{ $t("ordershipments-resume-title") }}</v-col
                    >
                    <v-col cols="1" class="pa-0 text-right"
                      ><v-icon @click="paymentsResultsDialog = false" large
                        >mdi-close</v-icon
                      ></v-col
                    >
                  </v-row>
                </template>
                <v-card-text>
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="text-center">
                          {{ $t("ordershipments-resume-tbl-name") }}
                        </th>
                        <th class="text-center">
                          {{ $t("ordershipments-resume-tbl-gross") }}
                        </th>
                        <th class="text-center">
                          {{ $t("ordershipments-resume-tbl-status") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in payments_results"
                        :key="index"
                      >
                        <td class="text-left">
                          <v-icon :color="item.reference_color"
                            >mdi-circle</v-icon
                          >
                          {{
                            item.business_name
                              ? item.business_name
                              : item.first_name + " " + item.last_name
                          }}
                        </td>
                        <td class="text-center">
                          {{
                            getPaymentCurrency(
                              parseFloat(item.gross_value).toFixed(2)
                            )
                          }}
                        </td>
                        <td class="text-center">
                          {{
                            item.invoice
                              ? getInvoicesPaymentState(item.payment_state)
                              : getPaymentState(item.payment_state)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>

      <v-dialog
        overlay-opacity="0.6"
        persistent
        max-width="600"
        :value="courierChangeDialog"
      >
        <!-- @input is required to emit emit input if clicked outside unless we use persistent -->

        <v-container fill-height fluid grid-list-xl>
          <v-layout justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="primary">
                <template v-slot:heading>
                  <v-row class="pa-0">
                    <v-col cols="1" class="pa-0"
                      ><v-icon large>mdi-shipment</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="10"
                      class="pa-0 card-title-text"
                      style="font-size: 25px"
                      >{{ $t("ordershipments-relocate-courier") }}</v-col
                    >
                    <v-col cols="1" class="pa-0 text-right"
                      ><v-icon @click="courierChangeDialog = false" large
                        >mdi-close</v-icon
                      ></v-col
                    >
                  </v-row>
                </template>
                <v-card-text>
                  <v-row>
                    <v-col>
                      {{ $t("ordershipments-relocate-subtitle") }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        clearable
                        dense
                        no-filter
                        v-model="flt_courier_from"
                        :items="flt_couriers_from"
                        :loading="isLoadingCourierFrom"
                        :search-input.sync="searchCourierInputFrom"
                        outlined
                        item-text="name"
                        item-value="id"
                        hide-details
                        :label="$t('filters-courier-from')"
                        return-object
                        :disabled="changingCourier"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        clearable
                        dense
                        no-filter
                        v-model="flt_courier_to"
                        :items="flt_couriers_to"
                        :loading="isLoadingCourierTo"
                        :search-input.sync="searchCourierInputTo"
                        outlined
                        item-text="name"
                        item-value="id"
                        hide-details
                        :label="$t('filters-courier-to')"
                        return-object
                        :disabled="changingCourier"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-progress-circular
                        v-if="changingCourier"
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="primary"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        :disabled="
                          !courierTo ||
                            !courierFrom ||
                            courierTo == courierFrom ||
                            changingCourier
                        "
                        @click="applyRelocation()"
                        color="primary"
                        >{{ $t("ordershipments-relocate-courier") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-dialog>

      <v-flex md12>
        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="success darken-2"
              icon="mdi-currency-eur"
              :title="$t('ordershipments-total-gross-value')"
              :value="computeGrossValue"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <base-material-stats-card
              color="success darken-2"
              icon="mdi-checkbook"
              :title="$t('ordershipments-total-check')"
              :value="computeCheckValue"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <base-material-stats-card
              color="success darken-2"
              icon="mdi-checkbook"
              :title="$t('ordershipments-total-pos')"
              :value="computePosValue"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <base-material-stats-card
              color="success darken-2"
              icon="mdi-cash"
              :title="$t('ordershipments-total-cash')"
              :value="computeCashValue"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="success darken-2"
              icon="mdi-bank"
              :title="$t('ordershipments-total-wire')"
              :value="computeWireValue"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
        </v-row>

        <messageBox ref="msgBox" />
        <v-row>
          <v-col>
            <v-btn
              v-if="$store.state.hasRight('214') && !loadingFilters"
              @click="relocateCourier()"
              color="primary"
              >{{ $t("ordershipments-relocate-courier") }}</v-btn
            >
          </v-col>
        </v-row>

        <div class="vertical-spacer" />
        <FiltersBar
          :filters="filters"
          :disabled="loadingOrders"
          @onFiltersLoading="onFiltersLoading"
          @onFiltersLoaded="onFiltersLoaded"
          :showLoader="true"
          :OrdersReportsFilter="true"
        />

        <base-material-card
          v-if="!loadingFilters"
          color="primary"
          icon="mdi-cube-send"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("ordershipments-title") }}
            </div>
          </template>

          <div style="height:10px" />

          <div class="vertical-spacer" />

          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="orders"
            :options.sync="options"
            :server-items-length="totalOrders"
            :loading="loadingOrders"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.courier="{ item }">
              {{ item.courier }}
            </template>
            <template v-slot:item.deliver="{ item }">
              {{ item.deliver }}
            </template>
            <template v-slot:item.num_orders="{ item }">
              {{ item.num_orders }}
              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="primary"
                    @click="showOrders(item)"
                    v-on="on"
                    ><v-icon>mdi-clipboard-list-outline</v-icon></v-btn
                  ></template
                ><span>{{
                  $t("ordershipments-show-all-orders")
                }}</span></v-tooltip
              >
            </template>

            <template v-slot:item.gross_value="{ item }">
              {{ item.gross_value.toFixed(2) | toCurrency }} €
            </template>

            <template v-slot:item.total_unpayed="{ item }">
              {{ item.total_unpayed.toFixed(2) | toCurrency }} €
            </template>

            <template v-slot:item.total_cash="{ item }">
              {{ item.total_cash.toFixed(2) | toCurrency }} €
            </template>

            <template v-slot:item.total_check="{ item }">
              {{ item.total_check.toFixed(2) | toCurrency }} €
            </template>
            <template v-slot:item.total_pos="{ item }">
              {{ item.total_pos.toFixed(2) | toCurrency }} €
            </template>
            <template v-slot:item.total_wire="{ item }">
              {{ item.total_wire.toFixed(2) | toCurrency }} €
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import ordersManager from "../apis/orders";
import usersManager from "../apis/users";

import FiltersBar from "../components/FiltersBar";
import messageBox from "../components/MessageBox";

import config from "../config";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      options: {
        sortBy: ["shipment_date"],
        sortDesc: [true]
      },

      headers: [],
      perPage: staticData.defautPerPage,
      totalOrders: 0,

      filters: {
        filterByUserId: null,
        filterByCourierId: null,
        filterByCustomerId: null,
        filterByPeriod: 9,
        filterBySalesPoint: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByOrderState: null
      },
      orders: [],
      loadingOrders: false,
      loadingFilters: true,

      stats: null,
      payments_results: null,
      paymentsResultsDialog: null,

      courierChangeDialog: null,

      flt_courier_from: null,
      flt_couriers_from: [],
      searchCourierInputFrom: null,
      isLoadingCourierFrom: false,

      flt_courier_to: null,
      flt_couriers_to: [],
      searchCourierInputTo: null,
      isLoadingCourierTo: false,

      courierFrom: null,
      courierTo: null,
      changingCourier: false
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("ordersmng-tbl-courier"),
        align: "left",
        sortable: true,
        value: "courier"
      },
      {
        text: this.$t("ordersmng-tbl-deliver"),
        align: "left",
        sortable: true,
        value: "deliver"
      },

      {
        text: this.$t("ordershipments-tbl-amount"),
        align: "left",
        sortable: true,
        value: "num_orders"
      },
      {
        text: this.$t("ordershipments-tbl-payment-state-none"),
        align: "left",
        sortable: true,
        value: "total_unpayed"
      },
      {
        text: this.$t("ordershipments-tbl-payment-state-cash"),
        align: "left",
        sortable: true,
        value: "total_cash"
      },

      {
        text: this.$t("ordershipments-tbl-payment-state-check"),
        align: "left",
        sortable: true,
        value: "total_check"
      },

      {
        text: this.$t("ordershipments-tbl-payment-state-wire"),
        align: "left",
        sortable: true,
        value: "total_wire"
      },

      {
        text: this.$t("ordershipments-tbl-payment-state-pos"),
        align: "left",
        sortable: true,
        value: "total_pos"
      },

      {
        text: this.$t("ordershipments-tbl-gross"),
        align: "left",
        sortable: true,
        value: "gross_value"
      }
    ];
  },

  components: {
    messageBox,
    FiltersBar
  },

  computed: {
    shipment_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(this.shipment_date_value);
      }
    },

    filterByPeriodText() {
      return staticData.statisticsPeriods[this.filters.filterByPeriod].name;
    },

    computeGrossValue() {
      if (this.stats)
        return this.$options.filters.toCurrencySymbol(this.stats.gross_value);
      else return "...";
    },

    computeWireValue() {
      if (this.stats)
        return this.$options.filters.toCurrencySymbol(this.stats.total_wire);
      else return "...";
    },

    computeCheckValue() {
      if (this.stats)
        return this.$options.filters.toCurrencySymbol(this.stats.total_check);
      else return "...";
    },
    computePosValue() {
      if (this.stats)
        return this.$options.filters.toCurrencySymbol(this.stats.total_pos);
      else return "...";
    },
    computeCashValue() {
      if (this.stats)
        return this.$options.filters.toCurrencySymbol(this.stats.total_cash);
      else return "...";
    }
  },

  watch: {
    searchCourierInputFrom(val) {
      clearTimeout(this._searchCourierInputFrom);
      if (val && !this.flt_couriers_from.some(item => item.name === val)) {
        this._searchCourierInputFrom = setTimeout(() => {
          this.isLoadingCourierFrom = true;
          this.fetchFilterCourier(val)
            .then(res => {
              this.flt_couriers_from = res.items.map(x => {
                x.name = x.first_name + " " + x.last_name;
                return x;
              });

              this.isLoadingCourierFrom = false;
            })
            .catch(() => {
              this.isLoadingCourierFrom = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        if (!val) this.courierFrom = null;
      }
    },

    searchCourierInputTo(val) {
      clearTimeout(this._searchCourierInputTo);
      if (val && !this.flt_couriers_to.some(item => item.name === val)) {
        this._searchCourierInputTo = setTimeout(() => {
          this.isLoadingCourierTo = true;
          this.fetchFilterCourier(val)
            .then(res => {
              this.flt_couriers_to = res.items.map(x => {
                x.name = x.first_name + " " + x.last_name;
                return x;
              });

              this.isLoadingCourierTo = false;
            })
            .catch(() => {
              this.isLoadingCourierTo = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        if (!val) this.courierTo = null;
      }
    },

    flt_courier_to: {
      handler() {
        this.courierTo = this.flt_courier_to ? this.flt_courier_to.id : null;
      }
    },

    flt_courier_from: {
      handler() {
        this.courierFrom = this.flt_courier_from
          ? this.flt_courier_from.id
          : null;
      }
    },

    options: {
      handler() {
        this.fetchShippedOrders();
      },
      deep: true
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchShippedOrders();
        }, 500);
      },
      deep: true
    }
  },

  methods: {
    fetchFilterCourier(filter) {
      return new Promise((resolve, reject) => {
        usersManager
          .fetchCouriersNames(filter)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    applyRelocation() {
      this.changingCourier = true;
      let ctx = {};

      ctx.filterByUserId = this.filters.filterByUserId;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByOrderState = this.filters.filterByOrderState;
      ctx.filterBySalesPoint = this.filters.filterBySalesPoint;
      ctx.filterByCourierId = this.filters.filterByCourierId;

      ordersManager
        .swapCouriers(this.courierFrom, this.courierTo, ctx)
        .then(() => {
          this.changingCourier = false;
          this.courierChangeDialog = false;
          this.courierFrom = null;
          this.courierTo = null;
          this.fetchShippedOrders();
        })
        .catch(err => {
          console.log(err);
        });
    },

    relocateCourier() {
      this.courierChangeDialog = true;
    },

    getPaymentCurrency(value) {
      return this.$options.filters.toCurrency(value) + " €";
    },

    getInvoicesPaymentState(states) {
      let state = 0; // Not payed
      let s = states.split(",");
      let somethingPayed = false;
      let somethingUnpayed = false;
      let somethingVoid = false;
      for (let n = 0; n < s.length; n++) {
        if (parseInt(s[n]) > 1) somethingPayed = true;
        if (parseInt(s[n]) < 2) somethingUnpayed = true;
        if (parseInt(s[n]) == 5) somethingVoid = true;
      }

      if (somethingUnpayed && somethingPayed) state = 1;
      else if (somethingUnpayed) state = 0;
      else if (somethingVoid) state = 3;
      else state = 2;

      return staticData.invoicesPaymentsStates[state].name;
    },

    getPaymentState(state) {
      return staticData.ordersPaymentStates[state].name;
    },

    showOrders(item) {
      ordersManager
        .fetchOrdersWithPayments(item.ids)
        .then(result => {
          this.payments_results = result.items;
          this.paymentsResultsDialog = true;
        })
        .catch(err => {
          console.log(err);
        });
    },

    onFiltersLoading() {
      this.loadingFilters = true;
    },

    onFiltersLoaded() {
      this.loadingFilters = false;
    },

    getOrderPaymentState(state) {
      return staticData.ordersPaymentStates[state].name;
    },

    fetchShippedOrders() {
      this.loadingOrders = true;
      this.totalOrders = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByUserId = this.filters.filterByUserId;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByOrderState = this.filters.filterByOrderState;
      ctx.filterBySalesPoint = this.filters.filterBySalesPoint;
      ctx.filterByCourierId = this.filters.filterByCourierId;

      ordersManager
        .fetchShippedOrders(ctx)
        .then(result => {
          this.totalOrders = result.totalCount;
          this.orders = result.items;
          this.stats = result.stats;
          this.loadingOrders = false;
        })
        .catch(err => {
          this.loadingOrders = false;
          console.log(err);
        });
    }
  }
};
</script>
