import axios from "axios";
import config from "../config";

let orders = new Object({
  fetchTickets: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tickets/list",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            tickets: resp.data.responseData.tickets,
            totalCount: resp.data.responseData.count,
            stats: resp.data.responseData.stats
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTicket: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tickets/get",
        data: { id: id },
        method: "POST"
      })
        .then(resp => {
          resolve({
            ticket: resp.data.responseData.ticket
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  closeTicket: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tickets/close",
        data: { id: id },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  answerTicket: (id, comment) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tickets/answerTicket",
        data: { id: id, comment: comment },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeAnswer: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tickets/removeAnswer",
        data: { id: id },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeTicket: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/tickets/removeTicket",
        data: { id: id },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
});

export default orders;
