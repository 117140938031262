<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="success darken-1"
              icon="mdi-currency-eur"
              :title="$t('statistics-total-net-value')"
              :value="computeTotalNet"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="success darken-2"
              icon="mdi-currency-eur"
              :title="$t('statistics-total-orders')"
              :value="computeTotalOrders"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="success darken-2"
              icon="mdi-currency-eur"
              :title="$t('statistics-total-customers')"
              :value="computeTotalCustomers"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="success darken-2"
              icon="mdi-currency-eur"
              :title="$t('statistics-total-days')"
              :value="computeTotalDays"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
        </v-row>

        <div class="vertical-spacer" />
        <FiltersBar
          :filters="filters"
          :disabled="loadingStatistics"
          @onFiltersLoading="onFiltersLoading"
          @onFiltersLoaded="onFiltersLoaded"
          :showLoader="true"
          :OrdersStatisticsFilter="true"
        />

        <base-material-card
          color="primary"
          icon="mdi-cube-send"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("orderstats-title") }}
            </div>
          </template>

          <div style="height:10px" />

          <v-row v-if="!loadingStatistics">
            <v-col cols="12" sm="6" lg="6">
              <base-material-chart-card
                :data="ordersChart.data"
                :options="ordersChart.options"
                color="primary"
                type="Line"
              >
                <h4 class="card-title font-weight-light mt-2 ml-2">
                  {{ $t("statistics-graph-orders") }}
                </h4>
              </base-material-chart-card>
            </v-col>

            <v-col cols="12" sm="6" lg="6">
              <base-material-chart-card
                :data="salesChart.data"
                :options="salesChart.options"
                color="success"
                type="Line"
              >
                <h4 class="card-title font-weight-light mt-2 ml-2">
                  {{ $t("statistics-graph-revenue") }}
                </h4>
              </base-material-chart-card>
            </v-col>
          </v-row>

          <v-row v-if="loadingStatistics">
            <v-col col="12">
              <v-progress-circular
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>

          <v-row v-if="!loadingStatistics">
            <v-col cols="12" sm="6" lg="6">
              <base-material-card
                color="success"
                icon="mdi-account-multiple"
                inline
                class="px-5 py-3"
              >
                <template v-slot:after-heading>
                  <div class="display-1 font-weight-light card-header">
                    {{ $t("statistics-top-products") }}
                  </div>
                </template>
                <div class="vertical-spacer" />
                <v-data-table
                  :headers="top_revenue_headers"
                  :items="stats ? stats.customers_ladder.results : []"
                >
                  <template v-slot:item.revenue="{ item }">
                    {{
                      item.revenue
                        ? $options.filters.toCurrencySymbol(item.revenue)
                        : 0.0
                    }}
                  </template>
                </v-data-table>
              </base-material-card>
            </v-col>
            <v-col cols="12" sm="6" lg="6">
              <base-material-card
                color="success"
                icon="mdi-account-multiple"
                inline
                class="px-5 py-3"
              >
                <template v-slot:after-heading>
                  <div class="display-1 font-weight-light card-header">
                    {{ $t("statistics-top-products") }}
                  </div>
                </template>
                <div class="vertical-spacer" />
                <v-data-table
                  :headers="top_products_headers"
                  :items="stats ? stats.products_ladder.results : []"
                >
                  <template v-slot:item.revenue="{ item }">
                    {{
                      item.revenue
                        ? $options.filters.toCurrencySymbol(item.revenue)
                        : 0.0
                    }}
                  </template>
                </v-data-table>
              </base-material-card>
            </v-col>
          </v-row>
          <div class="vertical-spacer" />
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import FiltersBar from "../components/FiltersBar";
import ordersManager from "../apis/orders";

export default {
  data() {
    return {
      ordersChart: {
        data: {
          labels: [],
          series: [[]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            fillHoles: true
          }),
          low: 0,
          high: 0, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        percent: 0,
        ascending: true
      },

      salesChart: {
        data: {
          labels: [],
          series: [[]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            fillHoles: true
          }),
          low: 0,
          high: 0, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        percent: 0,
        ascending: true
      },

      top_revenue_headers: [
        {
          text: this.$t("top-revenue-tbl-customer"),
          align: "left",
          sortable: false,
          value: "business_name"
        },
        {
          text: this.$t("top-revenue-tbl-revenue"),
          align: "center",
          sortable: false,
          value: "revenue"
        }
      ],

      top_products_headers: [
        {
          text: this.$t("top-products-tbl-product"),
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("top-products-tbl-quantity"),
          align: "left",
          sortable: false,
          value: "quantity"
        },
        {
          text: this.$t("top-products-tbl-revenue"),
          align: "center",
          sortable: false,
          value: "revenue"
        }
      ],

      stats: null,
      filters: {
        filterByCustomerId: null,
        filterByProductId: null,
        filterByPeriod: 7,
        filterBySalesPoint: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null
      },
      loadingStatistics: true,
      loadingFilters: true,

      salesPoints: {}
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("ordersmng-tbl-id"),
        align: "left",
        sortable: true,
        value: "id"
      },
      {
        text: this.$t("ordersmng-tbl-customer"),
        align: "left",
        sortable: true,
        value: "business_name"
      },
      {
        text: this.$t("ordersmng-tbl-date"),
        align: "left",
        sortable: true,
        value: "creation_date"
      },
      {
        text: this.$t("ordersmng-tbl-user"),
        align: "left",
        sortable: true,
        value: "user"
      },
      {
        text: this.$t("ordersmng-tbl-shipment-date"),
        align: "left",
        sortable: true,
        value: "shipment_date"
      },

      {
        text: this.$t("ordersmng-tbl-courier"),
        align: "left",
        sortable: true,
        value: "courier"
      },

      {
        text: this.$t("ordersmng-tbl-deliver"),
        align: "left",
        sortable: true,
        value: "deliver"
      },
      {
        text: this.$t("ordersmng-tbl-state"),
        align: "left",
        sortable: true,
        value: "state"
      },
      {
        text: this.$t("ordersmng-tbl-payment-state"),
        align: "left",
        sortable: true,
        value: "payment_state"
      },
      {
        text: this.$t("ordersmng-tbl-signature"),
        align: "center",
        sortable: true,
        value: "signature_id"
      },
      {
        text: this.$t("ordersmng-tbl-gross"),
        align: "left",
        sortable: true,
        value: "total_gross"
      },
      {
        text: this.$t("ordersmng-tbl-amount"),
        align: "left",
        sortable: true,
        value: "amount"
      },
      {
        text: this.$t("ordersmng-tbl-ddt"),
        align: "left",
        sortable: true,
        value: "ddt_number"
      },
      {
        text: this.$t("ordersmng-tbl-invoice"),
        align: "left",
        sortable: true,
        value: "invoice_number"
      },

      {
        text: this.$t("ordersmng-tbl-sales-point"),
        align: "left",
        sortable: true,
        value: "sales_point"
      },
      {
        text: this.$t("ordersmng-tbl-notes"),
        align: "center",
        sortable: true,
        value: "notes"
      },
      {
        text: this.$t("ordersmng-tbl-invoice-notes"),
        align: "center",
        sortable: true,
        value: "invoice_notes"
      },
      {
        text: this.$t("ordersmng-tbl-private-notes"),
        align: "center",
        sortable: true,
        value: "private_notes"
      }
    ];

    this.headers = [
      ...this.headers,
      { value: "actions", sortable: false, text: "" }
    ];

    this.salesPoints = {};
    for (let x in this.$store.state.sales_points) {
      this.salesPoints[
        this.$store.state.sales_points[x].id
      ] = this.$store.state.sales_points[x].name;
    }
  },

  components: {
    FiltersBar
  },

  computed: {
    filterByPeriodText() {
      return staticData.statisticsPeriods[this.filters.filterByPeriod].name;
    },

    computeTotalNet() {
      if (this.stats)
        return this.$options.filters.toCurrencySymbol(
          this.stats.revenue_count.results[0].total_revenue
        );
      else return "...";
    },

    computeTotalOrders() {
      if (this.stats) {
        return this.stats.orders_count.results[0].count.toString();
      } else return "...";
    },

    computeTotalCustomers() {
      if (this.stats) {
        return this.stats.total_customers.results[0].count.toString();
      } else return "...";
    },

    computeTotalDays() {
      if (this.stats) {
        return this.stats.total_days.results[0].count.toString();
      } else return "...";
    }
  },

  watch: {
    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchStatistics();
        }, 500);
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    onFiltersLoading() {
      this.loadingFilters = true;
    },
    onFiltersLoaded() {
      this.loadingFilters = false;
    },
    fetchStatistics() {
      this.loadingStatistics = true;
      let ctx = Object.assign({}, this.filters);

      ordersManager
        .fetchStatistics(ctx)
        .then(res => {
          this.stats = res;
          this.ordersChart.data.series = [[]];
          this.ordersChart.data.labels = [];
          if (res.orders_time_graph)
            this.ordersChart.data.labels = res.orders_time_graph.labels;

          let max = 0;
          let min = 0;
          for (let dot in res.orders_time_graph.results[0]) {
            let value = parseInt(res.orders_time_graph.results[0][dot]);
            if (value == null || isNaN(value) || value == undefined) value = 0;
            if (value < min || min == 0) min = value;
            if (value > max || max == 0) max = value;
            this.ordersChart.data.series[0].push(value);
          }
          this.ordersChart.options.low = 0;
          this.ordersChart.options.high = max + max * 0.2;

          let startValue = this.ordersChart.data.series[0][0];
          let endValue = this.ordersChart.data.series[0][
            this.ordersChart.data.series[0].length - 1
          ];
          if (endValue > startValue) {
            this.ordersChart.percent = Math.abs(
              (startValue / (endValue - startValue)) * 100
            ).toFixed(0);
            this.ordersChart.ascending = true;
          } else {
            this.ordersChart.percent = Math.abs(
              (startValue / (endValue - startValue)) * 100
            ).toFixed(0);
            this.ordersChart.ascending = false;
          }

          this.salesChart.data.series = [[]];
          this.salesChart.data.labels = [];
          if (res.orders_time_graph)
            this.salesChart.data.labels = res.orders_time_graph.labels;

          max = 0;
          min = 0;
          for (let dot in res.revenue_time_graph.results[0]) {
            let value = parseInt(res.revenue_time_graph.results[0][dot]);
            if (value == null || isNaN(value) || value == undefined) value = 0;
            if (value < min || min == 0) min = value;
            if (value > max || max == 0) max = value;
            this.salesChart.data.series[0].push(value);
          }
          this.salesChart.options.low = 0;
          this.salesChart.options.high = max + max * 0.2;

          startValue = this.salesChart.data.series[0][0];
          endValue = this.salesChart.data.series[0][
            this.salesChart.data.series[0].length - 1
          ];
          if (endValue > startValue) {
            this.salesChart.percent = Math.abs(
              (startValue / (endValue - startValue)) * 100
            ).toFixed(0);
            this.salesChart.ascending = true;
          } else {
            this.salesChart.percent = Math.abs(
              (startValue / (endValue - startValue)) * 100
            ).toFixed(0);
            this.salesChart.ascending = false;
          }

          this.loadingStatistics = false;
        })
        .catch(err => {
          console.log(err);
          this.loadingStatistics = false;
        });
    }
  }
};
</script>
