var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","overlay-opacity":"0.6","max-width":"500","value":_vm.value},on:{"input":function($event){return _vm.closeDialog()}}},[_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-mail")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"25px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.$t("send-allergens-title")))]),_c('v-col',{staticClass:"pa-0 text-right",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)]},proxy:true}])},[_c('v-card-text',[_c('v-row',{staticClass:"text-subtitle-1",attrs:{"dense":""}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.item ? _vm.item.name : "")+" ")])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("send-allergens-select-customer"))+" "),_c('v-autocomplete',{attrs:{"disabled":_vm.isLoadingCustomer ||
                      _vm.sending_regular_mail ||
                      _vm.sending_legal_mail,"clearable":"","dense":"","no-filter":"","items":_vm.flt_customers,"loading":_vm.isLoadingCustomer,"search-input":_vm.searchCustomerInput,"outlined":"","item-text":"display_name","item-value":"id","label":_vm.$t('filters-customers'),"return-object":"","hide-details":""},on:{"update:searchInput":function($event){_vm.searchCustomerInput=$event},"update:search-input":function($event){_vm.searchCustomerInput=$event}},model:{value:(_vm.flt_customer),callback:function ($$v) {_vm.flt_customer=$$v},expression:"flt_customer"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("send-allergens-select-email"))+" "),_c('v-select',{attrs:{"disabled":_vm.isLoadingCustomer ||
                      _vm.isLoadingAddresses ||
                      !_vm.customer_id ||
                      _vm.sending_regular_mail ||
                      _vm.sending_legal_mail,"label":_vm.$t('address-editor-hint-type'),"item-text":"context","item-value":"id","outlined":"","dense":"","items":_vm.mail_addresses,"return-object":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" ("+_vm._s(data.item.context)+") "+_vm._s(data.item.email)+" - "+_vm._s(_vm.$t(data.item.type))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" ("+_vm._s(data.item.context)+") "+_vm._s(data.item.email)+" - "+_vm._s(_vm.$t(data.item.type))+" ")]}}]),model:{value:(_vm.mail_address),callback:function ($$v) {_vm.mail_address=$$v},expression:"mail_address"}})],1)],1),(_vm.send_result)?_c('v-alert',{staticClass:"ma-2",attrs:{"dense":"","type":"success","color":"green","dismissible":""}},[_vm._v(" "+_vm._s(_vm.$t("send-allergens-success"))+" ")]):_vm._e(),(_vm.send_error)?_c('v-alert',{staticClass:"ma-2",attrs:{"dense":"","type":"error","dismissible":""}},[_vm._v(" "+_vm._s(_vm.send_error)+" ")]):_vm._e(),_c('div',{staticStyle:{"height":"10px"}}),(!_vm.sending_legal_mail && !_vm.sending_regular_mail)?_c('v-btn',{attrs:{"disabled":_vm.sending_regular_mail || !_vm.mail_address || !_vm.customer_id,"color":"primary"},on:{"click":function($event){return _vm.sendLegalMail()}}},[_vm._v(_vm._s(_vm.$t("send-allergens-send-legal")))]):_vm._e(),(!_vm.sending_legal_mail && !_vm.sending_regular_mail)?_c('v-btn',{attrs:{"disabled":_vm.sending_legal_mail || !_vm.mail_address || !_vm.customer_id,"color":"orange"},on:{"click":function($event){return _vm.sendRegularMail()}}},[_vm._v(_vm._s(_vm.$t("send-allergens-send-normal")))]):_vm._e(),(_vm.sending_regular_mail || _vm.sending_legal_mail)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"primary"}}):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }