<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <messageBox ref="msgBox" />
        <categoryEditor
          @categoryEditingDone="handleCategoryEditingDone"
          ref="categoryEditor"
          v-model="categoryEditorDialog"
        />
        <productEditor
          @productConfigured="handleProduct"
          v-model="productEditorDialog"
          :measureUnits="$store.state.measure_units"
          ref="productEditor"
        />

        <FileSelector
          ref="fileSelector"
          @fileSelected="onAllergenCardSelection"
          v-model="cardSelectionDialog"
        />

        <sendAllergens ref="sendAllergens" v-model="sendAllergensDialog" />

        <v-progress-circular
          v-if="loadingData"
          :indeterminate="true"
          :rotate="0"
          :size="32"
          :width="4"
          color="primary"
        ></v-progress-circular>

        <v-btn
          v-if="!loadingData && $store.state.hasRight('164')"
          v-on:click="addProduct(0)"
          color="info"
          >{{ $t("productsmng-add-product") }}</v-btn
        >
        <v-btn
          v-if="!loadingData && $store.state.hasRight('167')"
          v-on:click="manageCategory(0)"
          color="primary"
          >{{ $t("productsmng-mng-category") }}</v-btn
        >
        <base-material-card
          v-if="!loadingData"
          color="primary"
          icon="mdi-package-variant-closed"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("productsmng-title") }}
            </div>
          </template>
          <div style="height:10px" />
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="filter"
                append-icon="mdi-magnify"
                class="mr-auto pl-1"
                :label="$t('gbl-search')"
                hide-details
                single-line
                style="max-width: 250px;"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <div style="height:10px" />
              <v-select
                :label="$t('productsmng-category')"
                hide-details
                item-text="name"
                item-value="id"
                outlined
                dense
                v-model="filterByProductCategory"
                :items="categories"
              >
              </v-select>
            </v-col>
          </v-row>

          <div class="vertical-spacer" />
          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="products"
            :options.sync="options"
            :server-items-length="totalProducts"
            :loading="loadingProducts"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.pimage="{ item }">
              <div style="height:64px; width: 64px; margin:5px;">
                <v-img
                  max-width="64"
                  max-height="64"
                  v-if="!item.image"
                  :src="require('../assets/product.png')"
                  :key="item.fetchKey"
                />
                <v-img
                  max-width="64"
                  max-height="64"
                  v-if="item.image"
                  :src="item.fetchedImage"
                  :key="item.fetchKey"
                />
                <v-progress-circular
                  v-if="item.fetchingImage"
                  :indeterminate="true"
                  :rotate="0"
                  :size="64"
                  :width="6"
                  color="primary"
                ></v-progress-circular>
              </div>
            </template>
            <template v-slot:item.vat_percent="{ item }">
              {{ item.vat_percent }}%
            </template>
            <template v-slot:item.product_code="{ item }">
              {{ getProductCode(item) }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-row
                ><v-col class="text-right" style="min-width:64px;  ">
                  <v-tooltip v-if="$store.state.hasRight('172')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click="editItem(item)"
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("productsmng-modifyproduct")
                    }}</span></v-tooltip
                  >
                  <v-tooltip
                    v-if="
                      $store.state.hasRight('171') && item.orders_count == 0
                    "
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="error"
                        @click="deleteItem(item)"
                        v-on="on"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("productsmng-removeproduct")
                    }}</span></v-tooltip
                  >
                  <v-tooltip
                    v-if="$store.state.hasRight('171') && item.orders_count > 0"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        :disabled="true"
                        x-small
                        text
                        icon
                        color="error"
                        @click="deleteItem(item)"
                        v-on="on"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("productsmng-removeproduct")
                    }}</span></v-tooltip
                  >
                </v-col></v-row
              ></template
            >

            <template v-slot:item.price="{ item }">
              {{ item.price | toCurrency }}
            </template>
            <template v-slot:item.allergen_card="{ item }">
              {{
                item.allergen_card
                  ? stripePath(item.allergen_card)
                  : $t("gbl-none-female")
              }}
              <v-tooltip
                bottom
                v-if="$store.state.hasRight('201') && item.allergen_card"
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="orange"
                    @click.stop="sendAllergenCard(item)"
                    v-on="on"
                    ><v-icon>mdi-mail</v-icon></v-btn
                  ></template
                ><span>{{
                  $t("productsmng-action-send-card")
                }}</span></v-tooltip
              >
              <v-tooltip
                bottom
                v-if="$store.state.hasRight('173') && item.allergen_card"
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="green"
                    @click.stop="downloadAllergenCard(item)"
                    v-on="on"
                    ><v-icon>mdi-arrow-down</v-icon></v-btn
                  ></template
                ><span>{{
                  $t("productsmng-action-download-card")
                }}</span></v-tooltip
              >
              <v-tooltip bottom v-if="$store.state.hasRight('174')"
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="orange"
                    @click.stop="selectAllergenCard(item)"
                    v-on="on"
                    ><v-icon>mdi-arrow-up</v-icon></v-btn
                  ></template
                ><span>{{
                  $t("productsmng-action-upload-card")
                }}</span></v-tooltip
              >

              <v-tooltip
                bottom
                v-if="$store.state.hasRight('175') && item.allergen_card"
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="red"
                    @click.stop="removeAllergenCard(item)"
                    v-on="on"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  ></template
                ><span>{{
                  $t("productsmng-action-remove-card")
                }}</span></v-tooltip
              >
            </template>

            <template v-slot:item.measure_unit="{ item }">
              {{ measureUnit(item) }}
            </template>
            <template v-slot:item.conversion_factor="{ item }">
              {{ item.conversion_factor | toCurrency }}
            </template>

            <template v-slot:item.stock_amount="{ item }">
              {{
                !item.no_stock
                  ? item.stock_amount
                  : $t("productsmng-tbl-no-stock")
              }}
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import messageBox from "../components/MessageBox";
import categoryEditor from "../components/CategoryEditor";
import productsManager from "../apis/products";
import productEditor from "../components/ProductEditor";
import fileManager from "../apis/filemanager";
import FileSelector from "../components/FileSelector";
import sendAllergens from "../components/SendAllergens";

import config from "../config";

export default {
  data() {
    return {
      categories: [{ name: this.$t("gbl-all-female"), value: null }],
      options: {
        sortBy: ["name"],
        sortDesc: [false]
      },
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      headers: [],
      imageCache: {},

      perPage: staticData.defautPerPage,

      filter: "",
      filterByProductCategory: null,

      products: [],
      totalProducts: 0,
      loadingProducts: false,
      loadingData: false,
      categoryEditorDialog: false,
      productEditorDialog: false,
      cardSelectionDialog: false,
      productSelection: null,
      sendAllergensDialog: false
    };
  },

  mounted() {
    this.headers = [
      {
        text: "",
        align: "left",
        sortable: false,
        value: "pimage",
        width: "64px"
      },
      {
        text: this.$t("productsmng-tbl-code"),
        align: "left",
        sortable: true,
        value: "product_code"
      },
      {
        text: this.$t("productsmng-tbl-name"),
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: this.$t("productsmng-tbl-category"),
        align: "left",
        sortable: true,
        value: "category"
      },
      {
        text: this.$t("productsmng-tbl-price"),
        align: "left",
        sortable: true,
        value: "price"
      },
      {
        text: this.$t("productsmng-tbl-vat"),
        align: "left",
        sortable: true,
        value: "vat_percent"
      },
      {
        text: this.$t("productsmng-tbl-unit-type"),
        align: "left",
        sortable: true,
        value: "measure_unit"
      },
      {
        text: this.$t("productsmng-tbl-conversion-factor"),
        align: "left",
        sortable: true,
        value: "conversion_factor"
      },

      {
        text: this.$t("productsmng-tbl-allergen-card"),
        align: "left",
        sortable: true,
        value: "allergen_card"
      },

      {
        text: this.$t("productsmng-tbl-amount"),
        align: "left",
        sortable: true,
        value: "stock_amount"
      },
      {
        text: this.$t("productsmng-tbl-notes"),
        align: "left",
        sortable: false,
        value: "notes"
      },
      { value: "actions", sortable: false, text: "", width: "64px" }
    ];

    this.fetchCategories();
  },

  components: {
    messageBox,
    categoryEditor,
    productEditor,
    FileSelector,
    sendAllergens
  },

  watch: {
    options: {
      handler() {
        this.fetchProducts();
      },
      deep: true
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchProducts();
        }, 300);
      }
    },

    filterByProductCategory: {
      handler() {
        this.fetchProducts();
      }
    }
  },

  methods: {
    measureUnit: function(item) {
      return this.$t(this.$store.state.measure_units_by_id[item.measure_unit]);
    },

    getProductCode(item) {
      if (item && item.id) {
        let padding = item.id.toString();
        while (padding.length < config.productIdPadding)
          padding = "0" + padding;
        return padding;
      }
      return "";
    },

    stripePath(path) {
      let index = path.lastIndexOf("/");
      if (index >= 0) {
        let res = path.substr(index + 1, path.length);
        if (res.length) return res;
      }
      return path;
    },

    removeAllergenCard(item) {
      this.$refs.msgBox
        .show(
          this.$t("products-view-remove-allergen-card-text"),
          this.$t("products-view-remove-allergen-card-title")
        )
        .then(() => {
          productsManager
            .setAllergenCard(item.id, "")
            .then(() => {
              item.allergen_card = "";
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    onAllergenCardSelection(result) {
      this.cardSelectionDialog = false;
      if (result) {
        productsManager
          .setAllergenCard(this.productSelection.id, result)
          .then(() => {
            this.productSelection.allergen_card = result;
            this.productSelection = null;
          })
          .catch(err => {
            this.productSelection = null;
            console.log(err);
          });
      } else {
        this.productSelection = null;
      }
    },

    sendAllergenCard(item) {
      this.$refs.sendAllergens.setItem(item);
      this.sendAllergensDialog = true;
    },

    downloadAllergenCard(item) {
      fileManager
        .downloadFile(item.allergen_card, this.stripePath(item.allergen_card))
        .then(() => {})
        .catch(err => {
          console.log(err);
        });
    },

    selectAllergenCard(item) {
      this.cardSelectionDialog = true;
      this.productSelection = item;
    },

    fetchCategories() {
      this.categories = [{ name: this.$t("gbl-all-female"), id: null }];
      this.loadingData = true;
      let ctx = {};
      ctx.sortBy = "name";
      ctx.sortDesc = false;
      productsManager
        .fetchCategories(ctx)
        .then(results => {
          this.categories = [...this.categories, ...results.items];
          this.loadingData = false;
        })
        .catch(err => {
          console.log(err);
          this.loadingData = false;
        });
    },

    manageCategory() {
      this.categoryEditorDialog = true;
    },

    handleCategoryEditingDone() {
      this.fetchCategories();
    },

    fetchProducts() {
      this.loadingProducts = true;
      this.totalProducts = 0;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        ctx.filterByProductCategory = this.filterByProductCategory;
        ctx.showHidden = true;
        productsManager
          .fetchProducts(ctx)
          .then(result => {
            this.totalProducts = result.totalCount;
            for (let item of result.items) {
              item.fetchKey = 0;
              if (item.image) {
                item.fetchingImage = true;
                this.fetchProductImage(item);
              } else {
                item.fetchingImage = false;
              }
            }
            this.products = result.items;
            this.loadingProducts = false;
            resolve(result);
          })
          .catch(err => {
            this.loadingProducts = false;
            console.log(err);
            reject();
          });
      });
    },

    fetchProductImage(item) {
      if (item.fetchingImage) {
        if (!this.imageCache[item.id]) {
          productsManager.fetchProductImage(item.id).then(results => {
            if (results.image) {
              item.fetchedImage = "data:image/png;base64," + results.image;
              this.imageCache[item.id] = results.image;
              item.fetchKey++;
            }
            item.fetchingImage = false;
          });
        } else {
          item.fetchedImage =
            "data:image/png;base64," + this.imageCache[item.id];
          item.fetchingImage = false;
        }
      }
    },

    handleProduct(event) {
      if (event.editingProduct) {
        let product = event.product;
        product.fetchedImage = null; // Remove fetched image to avoid increase payload
        productsManager
          .updateProduct(product)
          .then(() => {
            this.imageCache = {};
            this.fetchProducts();
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        let product = event.product;
        productsManager
          .addProduct(product)
          .then(() => {
            this.imageCache = {};
            this.fetchProducts();
          })
          .catch(err => {
            console.log(err);
          });
      }
      this.productEditorDialog = false;
    },

    deleteItem(item) {
      let productId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("productsmng-delete-title"),
          this.$t("productsmng-delete-text")
        )
        .then(() => {
          productsManager
            .removeProduct(productId)
            .then(() => {
              this.fetchProducts();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editItem(item) {
      let product = JSON.parse(JSON.stringify(item));
      this.$refs.productEditor.setModalProduct(product, true);
      this.productEditorDialog = true;
    },

    addProduct() {
      productsManager.fetchNextId().then(res => {
        this.$refs.productEditor.setModalProduct(
          {
            id: res.nextId,
            name: "",
            description: "",
            notes: "",
            image: false,
            category_id: null,
            price: 1.5,
            vat_percent: 4.0,
            stock_amount: 0,
            availability: 0,
            last_time: 0
          },
          false
        );
        this.productEditorDialog = true;
      });
    }
  }
};
</script>
