<template>
  <div>
    <FinancialDashboard v-if="$store.state.hasRight('53')" />
    <CourierDashboard
      v-if="$store.state.hasRight('183') && !$store.state.isAdmin()"
    />
    <ShowcaseDashboard
      v-if="$store.state.hasRight('200') && !$store.state.isAdmin()"
    />
  </div>
</template>

<script>
import FinancialDashboard from "./FinancialDashboard";
import ShowcaseDashboard from "./ShowcaseDashboard";
import CourierDashboard from "./CourierDashboard";

export default {
  data() {
    return {};
  },
  components: {
    FinancialDashboard,
    ShowcaseDashboard,
    CourierDashboard,
  },
};
</script>
