<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="480"
    :value="openDialog"
  >
    <v-dialog ref="dateDialog" v-model="dateDialog" width="290px">
      <v-date-picker
        v-model="value"
        scrollable
        :first-day-of-week="1"
        :locale="$store.state.user.locale"
      >
      </v-date-picker>
    </v-dialog>

    <base-material-card class="pa-0 " color="primary">
      <template v-slot:heading>
        <v-row class="pa-0">
          <v-col
            align="center"
            class="pa-0 card-title-text"
            style="font-size: 25px"
            >{{ $t("daysmng-add-exception-title") }}</v-col
          >
          <v-col cols="1" class="pa-0 text-right"
            ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
          >
        </v-row>
      </template>
      <v-card-text>
        <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
          <ValidationProvider
            immediate
            rules="required"
            v-slot="{ errors, valid }"
          >
            <v-text-field
              outlined
              dense
              :immediate="true"
              @click="dateDialog = true"
              :value="value | toLocaleDate"
              :label="$t('order-edit-select-date')"
              prepend-icon="mdi-calendar"
              readonly
            />
          </ValidationProvider>
        </ValidationObserver>
      </v-card-text>
      <v-btn width="120" color="primary" @click="confirm()">{{
        $t("gbl-ok")
      }}</v-btn>
      <v-btn width="120" color="secondary" @click="dismiss()">{{
        $t("gbl-cancel")
      }}</v-btn>
    </base-material-card>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      value: "",
      dateDialog: false,
      dialogResolve: null,
      dialogReject: null,
      openDialog: false
    };
  },

  components: {
    ValidationObserver,
    ValidationProvider
  },

  props: {
    showClose: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  methods: {
    show() {
      return new Promise((resolve, reject) => {
        this.value = new Date().toISOString().substr(0, 10);
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
      });
    },

    openDateDialog() {},

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      if (this.value.length > 0) {
        if (this.value != this.initial_value || !this.mustChange) {
          this.openDialog = false;
          this.dialogResolve(this.value);
        }
      }
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    }
  }
};
</script>
