<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <v-dialog ref="dialog_shipment_date" v-model="shipment_date_modal" :return-value.sync="shipment_date_value"
          width="290px">
          <v-date-picker @input="handleShipmentDate" v-model="shipment_date_value" scrollable :first-day-of-week="1"
            :locale="$store.state.user.locale">
          </v-date-picker>
        </v-dialog>
        <v-dialog ref="dialog_loan_expiration_date" v-model="loan_expiration_date_modal"
          :return-value.sync="loan_expiration_date_value" width="290px">
          <v-date-picker @input="handleLoanExpirationDate" v-model="loan_expiration_date_value" scrollable
            :first-day-of-week="1" :locale="$store.state.user.locale">
          </v-date-picker>
        </v-dialog>

        <v-dialog overlay-opacity="0.6" max-width="600" :value="showSignatureDialog" persistent>
          <v-container fill-height fluid grid-list-xl>
            <v-layout justify-center wrap>
              <v-flex md12>
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <v-row class="pa-0">
                      <v-col cols="1" class="pa-0"><v-icon large>mdi-account</v-icon></v-col>
                      <v-col align="center" cols="10" class="pa-0 card-title-text" style="font-size: 25px">{{
                        $t("order-show-signature-title") }}</v-col>
                      <v-col cols="1" class="pa-0"><v-icon @click="showSignatureDialog = false"
                          large>mdi-close</v-icon></v-col>
                    </v-row>
                  </template>
                  <div style="height:10px" />
                  <img :src="signatureData" />
                </base-material-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>

        <v-dialog overlay-opacity="0.6" max-width="600" :value="changeCourierDialog" persistent>
          <v-container fill-height fluid grid-list-xl>
            <v-layout justify-center wrap>
              <v-flex md12>
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <v-row class="pa-0">
                      <v-col cols="1" class="pa-0"><v-icon large>mdi-account</v-icon></v-col>
                      <v-col align="center" cols="10" class="pa-0 card-title-text" style="font-size: 25px">{{
                        $t("order-state-change-courier-title") }}</v-col>
                      <v-col cols="1" class="pa-0"><v-icon @click="changeCourierDialog = false"
                          large>mdi-close</v-icon></v-col>
                    </v-row>
                  </template>
                  <div style="height:10px" />
                  <v-select :label="$t('order-state-change-courier-hint-type')" item-text="name" item-value="id"
                    outlined dense v-model="changeCourierValue" :items="couriers" />
                  <v-btn :disabled="!changeCourierValue" @click="handleChangeCourier()" color="primary">{{ $t("gbl-ok")
                    }}</v-btn>
                </base-material-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>

        <v-dialog overlay-opacity="0.6" max-width="600" :value="changeDeliverDialog" persistent>
          <v-container fill-height fluid grid-list-xl>
            <v-layout justify-center wrap>
              <v-flex md12>
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <v-row class="pa-0">
                      <v-col cols="1" class="pa-0"><v-icon large>mdi-account</v-icon></v-col>
                      <v-col align="center" cols="10" class="pa-0 card-title-text" style="font-size: 25px">{{
                        $t("order-state-change-deliver-title") }}</v-col>
                      <v-col cols="1" class="pa-0"><v-icon @click="changeDeliverDialog = false"
                          large>mdi-close</v-icon></v-col>
                    </v-row>
                  </template>
                  <div style="height:10px" />
                  <v-select :label="$t('order-state-change-deliver-hint-type')" item-text="name" item-value="id"
                    outlined dense v-model="changeCourierValue" :items="couriers" />
                  <v-btn :disabled="!changeCourierValue" @click="handleChangeDeliver()" color="primary">{{ $t("gbl-ok")
                    }}</v-btn>
                </base-material-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>

        <v-dialog overlay-opacity="0.6" max-width="600" :value="changeOrderStateDialog" persistent>
          <v-container fill-height fluid grid-list-xl>
            <v-layout justify-center wrap>
              <v-flex md12>
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <v-row class="pa-0">
                      <v-col cols="1" class="pa-0"><v-icon large>mdi-office-building-marker-outline</v-icon></v-col>
                      <v-col align="center" cols="10" class="pa-0 card-title-text" style="font-size: 25px">{{
                        $t("order-state-editor-title-modify") }}</v-col>
                      <v-col cols="1" class="pa-0"><v-icon @click="changeOrderStateDialog = false"
                          large>mdi-close</v-icon></v-col>
                    </v-row>
                  </template>
                  <div style="height:10px" />
                  <v-select :label="$t('order-state-editor-hint-type')" item-text="name" item-value="id" outlined dense
                    v-model="orderStateDialogValue" :items="orderStates" />
                  <v-btn @click="handleChangeOrderState()" color="primary">{{
                    $t("profile-change-pwd-confirm")
                    }}</v-btn>
                </base-material-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>

        <v-dialog overlay-opacity="0.6" max-width="600" :value="changeOrderPaymentStateDialog" persistent>
          <v-container fill-height fluid grid-list-xl>
            <v-layout justify-center wrap>
              <v-flex md12>
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <v-row class="pa-0">
                      <v-col cols="1" class="pa-0"><v-icon large>mdi-office-building-marker-outline</v-icon></v-col>
                      <v-col align="center" cols="10" class="pa-0 card-title-text" style="font-size: 25px">{{
                        $t("payment-editor-title-modify") }}</v-col>
                      <v-col cols="1" class="pa-0"><v-icon @click="changeOrderPaymentStateDialog = false"
                          large>mdi-close</v-icon></v-col>
                    </v-row>
                  </template>
                  <div style="height:10px" />
                  <v-select :label="$t('payment-editor-hint-type')" item-text="name" item-value="id" outlined dense
                    v-model="orderPaymentStateDialogValue" :items="getPaymentStates" />
                  <v-btn @click="handleChangePaymentOrderState()" color="primary">{{ $t("profile-change-pwd-confirm")
                    }}</v-btn>
                </base-material-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>

        <messageBox ref="msgBox" />
        <orderEditor @orderConfigured="handleOrder" v-model="orderEditorDialog" ref="orderEditor" />

        <v-row v-if="$store.state.hasRight('230')">
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card color="success darken-1" icon="mdi-currency-eur"
              :title="$t('invoicesreports-total-net-value')" :value="computeNetValue" sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText" />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card color="success darken-2" icon="mdi-currency-eur"
              :title="$t('invoicesreports-total-gross-value')" :value="computeGrossValue" sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText" />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card color="success" icon="mdi-currency-eur" :title="$t('invoicesreports-total-payed')"
              :value="computeTotalPayed" sub-icon="mdi-calendar" :sub-text="filterByPeriodText" />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card color="warning" icon="mdi-currency-eur"
              :title="$t('invoicesreports-total-not-payed')" :value="computeTotalUnpayed" sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText" />
          </v-col>
        </v-row>

        <v-btn v-if="$store.state.hasRight('176') && !loadingFilters" @click="addOrder()" color="primary">{{
          $t("ordermng-add-order") }}</v-btn>

        <v-btn v-if="!loadingFilters" @click="exportQuantities()" color="primary">{{ $t("ordermng-export-quantities")
          }}</v-btn>
        <v-btn v-if="!loadingFilters" @click="exportCompactQuantities()" color="primary">{{
          $t("ordermng-export-quantities-compact") }}</v-btn>
        <div class="vertical-spacer" />
        <FiltersBar :filters="filters" :disabled="loadingOrders" @onFiltersLoading="onFiltersLoading"
          @onFiltersLoaded="onFiltersLoaded" :showLoader="true" :OrdersFilter="true" :LoanOrders="true" />

        <base-material-card v-if="!loadingFilters" color="primary" icon="mdi-cube-send" inline class="px-5 py-3">
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{
              loanOrders ? $t("ordermng-loan-title") : $t("ordermng-title")
              }}
            </div>
          </template>

          <div style="height:10px" />
          <v-text-field v-model="filter" append-icon="mdi-magnify" class="mr-auto pl-1" :label="$t('gbl-search')"
            hide-details single-line style="max-width: 250px;" />

          <div class="vertical-spacer" />

          <v-data-table class="grid-table" :items-per-page="perPage" :must-sort="true" :headers="headers"
            :items="orders" :options.sync="options" :server-items-length="totalOrders" :loading="loadingOrders"
            :footer-props="dataTableFooterOptions">
            <template v-slot:item.id="{ item }">
              <v-chip class="mr-2" v-if="item.sales_point == 3" color="blue" dark>{{ item.id }}</v-chip>
              <v-chip class="mr-2" v-if="!item.validated" color="red" dark>Da validare</v-chip>
              <v-chip class="mr-2" v-if="item.sales_point != 3" color="orange" dark>{{ item.id }}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-row><v-col class="text-center" style="min-width:96px;">
                  <v-tooltip v-if="
                    item.validated &&
                    item.ddt_generation_id &&
                    item.invoice_generation_id
                  " bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" slot="activator" color="red">mdi-cancel</v-icon> </template><span>{{
                      $t("ordersmng-not-editable") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="
                    item.validated &&
                    !item.ddt_generation_id &&
                    item.invoice_generation_id
                  " bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" slot="activator" color="red">mdi-cancel</v-icon> </template><span>{{
                      $t("ordersmng-not-editable") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="
                    item.validated &&
                    !item.invoice_generation_id &&
                    item.ddt_generation_id
                  " bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" slot="activator" color="orange">mdi-cancel</v-icon> </template><span>{{
                      $t("ordersmng-not-editable-remove-ddt") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="primary"
                        @click="printResume(item)"
                        v-on="on"><v-icon>mdi-file-document-edit-outline</v-icon></v-btn></template><span>{{
                          $t("ordermng-print-resume") }}</span></v-tooltip>
                  <v-tooltip v-if="
                    item.validated &&
                    $store.state.hasRight('178') &&
                    loanOrders
                  " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="primary"
                        @click="convertToOrder(item)"
                        v-on="on"><v-icon>mdi-cube-send</v-icon></v-btn></template><span>{{
                      $t("ordermng-convert-loan-to-order")
                      }}</span></v-tooltip>
                  <v-tooltip v-if="
                    item.validated &&
                    $store.state.hasRight('178') &&
                    !item.invoice_generation_id &&
                    !item.ddt_generation_id
                  " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="orange darken-2"
                        @click="editItem(item)" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn></template><span>{{
                      $t("ordermng-modifyorder") }}</span></v-tooltip>
                  <v-tooltip v-if="
                    !item.validated &&
                    $store.state.hasRight('178') &&
                    !item.invoice_generation_id &&
                    !item.ddt_generation_id
                  " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="green darken-2"
                        @click="editItem(item)" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn></template><span>{{
                      $t("ordermng-validate-order") }}</span></v-tooltip>



                  <v-tooltip v-if="
                    $store.state.hasRight('179') &&
                    !item.invoice_generation_id &&
                    !item.ddt_generation_id
                  " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="red darken-1"
                        @click="deleteItem(item)" v-on="on"><v-icon>mdi-delete</v-icon></v-btn></template><span>{{
                      $t("ordermng-removeorder") }}</span></v-tooltip>
                </v-col></v-row></template>
            <template v-slot:item.creation_date="{ item }">
              {{ item.creation_date | toLocaleDate }}
            </template>

            <template v-slot:item.notes="{ item }">
              <v-tooltip v-if="item.notes" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" slot="activator" color="cyan">mdi-information-outline</v-icon> </template><span>{{
                  item.notes }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.invoice_notes="{ item }">
              <v-tooltip v-if="item.invoice_notes" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" slot="activator" color="cyan">mdi-information-outline</v-icon> </template><span>{{
                  item.invoice_notes }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.private_notes="{ item }">
              <v-tooltip v-if="item.private_notes" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" slot="activator" color="cyan">mdi-information-outline</v-icon> </template><span>{{
                  item.private_notes }}</span>
              </v-tooltip>
            </template>

            <template v-slot:item.loan_expiration="{ item }">
              <span :class="item.loan_expired ? 'red--text' : ''">
                {{ item.loan_expiration | toLocaleDate }}</span>
              <v-tooltip v-if="item.validated && $store.state.hasRight('182')" bottom><template
                  v-slot:activator="{ on }"><v-btn x-small text icon color="orange darken-2"
                    @click="setLoanExpirationDate(item)"
                    v-on="on"><v-icon>mdi-pencil</v-icon></v-btn></template><span>{{
                  $t("ordermng-change-loan-expiration-date")
                  }}</span></v-tooltip>
            </template>

            <template v-slot:item.shipment_date="{ item }">
              {{ item.shipment_date | toLocaleDate }}
              <v-tooltip v-if="item.validated && $store.state.hasRight('182')" bottom><template
                  v-slot:activator="{ on }"><v-btn x-small text icon color="orange darken-2"
                    @click="setShippingDate(item)" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn></template><span>{{
                  $t("ordermng-change-shipping-date")
                  }}</span></v-tooltip>
            </template>

            <template v-slot:item.courier="{ item }">
              {{ !item.no_shipment ? item.courier : $t("ordermng-no-courier") }}
              <v-tooltip v-if="
                item.validated &&
                !item.no_shipment &&
                $store.state.hasRight('189')
              " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="orange darken-2"
                    @click="setCourier(item)" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn></template><span>{{
                  $t("ordermng-change-courier") }}</span></v-tooltip>
            </template>

            <template v-slot:item.deliver="{ item }">
              {{ !item.no_shipment ? item.deliver : $t("ordermng-no-courier") }}
              <v-tooltip v-if="
                item.validated &&
                !item.no_shipment &&
                $store.state.hasRight('189')
              " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="orange darken-2"
                    @click="setDeliver(item)" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn></template><span>{{
                  $t("ordermng-change-courier") }}</span></v-tooltip>
            </template>

            <template v-slot:item.total_gross="{ item }">
              <span v-if="item.total_gross">
                {{ item.total_gross.toFixed(2) | toCurrency }} €
              </span>
            </template>

            <template v-slot:item.sales_point="{ item }">
              {{ getSalesPoint(item) }}
            </template>

            <template color="green" v-slot:item.signature_id="{ item }">
              <v-tooltip v-if="item.signature_id" bottom><template v-slot:activator="{ on }"><v-btn x-small text icon
                    color="green darken-2" @click="showSignature(item.id)"
                    v-on="on"><v-icon>mdi-check-circle-outline</v-icon></v-btn></template><span>{{
                  $t("ordermng-show-signature") }}</span></v-tooltip>
            </template>

            <template v-slot:item.business_name="{ item }">
              <div class="body-2">
                <v-icon :color="item.reference_color">mdi-circle</v-icon>
                {{
                item.customer
                ? item.customer
                : item.first_name + " " + item.last_name
                }}
              </div>
              <div class="body-3">{{ item.ad_address }}</div>
            </template>

            <template v-slot:item.ddt_number="{ item }">
              <div style="min-width:160px">
                {{
                item.ddt_number ? item.ddt_number + " - " + item.ddt_year : ""
                }}

                <v-tooltip v-if="
                  item.validated &&
                  !$store.state.spooler_online &&
                  $store.state.hasRight('181') &&
                  !item.ddt_generation_id &&
                  (item.doc_generation_mode == 1 ||
                    item.doc_generation_mode == 3)
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="yellow darken-2"
                      @click="generateAndPrintDDT(item)"
                      v-on="on"><v-icon>mdi-printer</v-icon></v-btn></template><span>{{
                    $t("ordermng-generate-and-print-ddt")
                    }}</span></v-tooltip>

                <v-tooltip v-if="
                  item.validated &&
                  $store.state.spooler_online &&
                  $store.state.hasRight('181') &&
                  !item.ddt_generation_id &&
                  (item.doc_generation_mode == 1 ||
                    item.doc_generation_mode == 3)
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="yellow darken-2"
                      @click="generateAndPrintDDT(item, true)"
                      v-on="on"><v-icon>mdi-printer-wireless</v-icon></v-btn></template><span>{{
                    $t("ordermng-generate-and-print-ddt")
                    }}</span></v-tooltip>

                <v-tooltip v-if="
                  item.validated &&
                  $store.state.hasRight('181') &&
                  !item.ddt_generation_id &&
                  (item.doc_generation_mode == 1 ||
                    item.doc_generation_mode == 3)
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="yellow darken-2"
                      @click="generateDDT(item)"
                      v-on="on"><v-icon>mdi-layers-outline</v-icon></v-btn></template><span>{{
                    $t("ordermng-generate-ddt") }}</span></v-tooltip>
                <v-tooltip v-if="
                  item.validated &&
                  !$store.state.spooler_online &&
                  $store.state.hasRight('186') &&
                  item.ddt_generation_id &&
                  item.doc_generation_mode != 3
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="cyan"
                      @click="printDDT(item)" v-on="on"><v-icon>mdi-printer</v-icon></v-btn></template><span>{{
                    $t("ordermng-print-ddt") }}</span></v-tooltip>

                <v-tooltip v-if="
                  item.validated &&
                  $store.state.spooler_online &&
                  $store.state.hasRight('186') &&
                  item.ddt_generation_id &&
                  item.doc_generation_mode != 3
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="cyan"
                      @click="printDDT(item, true)"
                      v-on="on"><v-icon>mdi-printer-wireless</v-icon></v-btn></template><span>{{
                    $t("ordermng-print-ddt") }}</span></v-tooltip>

                <v-tooltip v-if="
                  item.validated &&
                  $store.state.hasRight('187') &&
                  !$store.state.spooler_online &&
                  item.ddt_generation_id &&
                  item.doc_generation_mode == 3
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="green"
                      @click="printDDTWithAmounts(item)"
                      v-on="on"><v-icon>mdi-printer</v-icon></v-btn></template><span>{{
                    $t("ordermng-print-ddt-with-amounts")
                    }}</span></v-tooltip>

                <v-tooltip v-if="
                  item.validated &&
                  !$store.state.spooler_online &&
                  $store.state.hasRight('187') &&
                  item.ddt_generation_id &&
                  item.doc_generation_mode == 3
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="green"
                      @click="printDDTWithAmounts(item, true)"
                      v-on="on"><v-icon>mdi-printer-wireless</v-icon></v-btn></template><span>{{
                    $t("ordermng-print-ddt-with-amounts")
                    }}</span></v-tooltip>

                <v-tooltip v-if="
                  item.validated &&
                  $store.state.hasRight('186') &&
                  item.ddt_generation_id &&
                  item.doc_generation_mode != 3
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="cyan"
                      @click="downloadDDT(item)"
                      v-on="on"><v-icon>mdi-file-table-outline</v-icon></v-btn></template><span>{{
                    $t("ordermng-download-ddt") }}</span></v-tooltip>

                <v-tooltip v-if="
                  item.validated &&
                  $store.state.hasRight('187') &&
                  item.ddt_generation_id &&
                  item.doc_generation_mode == 3
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="green"
                      @click="downloadDDTWithAmounts(item)"
                      v-on="on"><v-icon>mdi-file-document-outline</v-icon></v-btn></template><span>{{
                    $t("ordermng-download-ddt-with-amounts")
                    }}</span></v-tooltip>

                <v-tooltip v-if="
                  item.validated &&
                  $store.state.hasRight('187') &&
                  item.ddt_edi_path
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="purple"
                      @click="downloadDDTEDI(item)"
                      v-on="on"><v-icon>mdi-file-document-outline</v-icon></v-btn></template><span>{{
                    $t("ordermng-download-edi-ddt") }}</span></v-tooltip>

                <v-tooltip v-if="
                  $store.state.hasRight('188') &&
                  item.ddt_generation_id &&
                  !item.invoice_generation_id
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="orange darken-1"
                      @click="removeDDT(item)" v-on="on"><v-icon>mdi-database-remove</v-icon></v-btn></template><span>{{
                    $t("ordermng-remove-ddt") }}</span></v-tooltip>
              </div>
            </template>

            <template v-slot:item.invoice_number="{ item }">
              <div style="min-width:120px">
                {{
                item.invoice_number
                ? item.invoice_number + " - " + item.invoice_year
                : ""
                }}
                <v-tooltip v-if="
                  item.validated &&
                  $store.state.hasRight('195') &&
                  !item.invoice_generation_id
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="yellow darken-2"
                      @click="generateInvoice(item)" :disabled="item.doc_generation_mode != 2 ? true : false"
                      v-on="on"><v-icon>mdi-file-certificate-outline</v-icon></v-btn></template><span>{{
                    $t("ordermng-generate-invoice") }}</span></v-tooltip>

                <v-tooltip v-if="
                  item.validated &&
                  !$store.state.spooler_online &&
                  $store.state.hasRight('187') &&
                  item.invoice_generation_id
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="green"
                      @click="printInvoice(item)" v-on="on"><v-icon>mdi-printer</v-icon></v-btn></template><span>{{
                    $t("ordermng-print-invoice") }}</span></v-tooltip>

                <v-tooltip v-if="
                  item.validated &&
                  $store.state.spooler_online &&
                  $store.state.hasRight('187') &&
                  item.invoice_generation_id
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="green"
                      @click="printInvoice(item, true)"
                      v-on="on"><v-icon>mdi-printer-wireless</v-icon></v-btn></template><span>{{
                    $t("ordermng-print-invoice") }}</span></v-tooltip>

                <v-tooltip v-if="
                  item.validated &&
                  $store.state.hasRight('187') &&
                  item.invoice_generation_id
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="green"
                      @click="downloadInvoice(item)"
                      v-on="on"><v-icon>mdi-file-check-outline</v-icon></v-btn></template><span>{{
                    $t("ordermng-download-invoice") }}</span></v-tooltip>
                <v-tooltip v-if="
                  item.validated &&
                  $store.state.hasRight('187') &&
                  item.invoice_generation_id
                " bottom><template v-slot:activator="{ on }"><v-btn x-small text icon color="green"
                      @click="downloadInvoiceXml(item)" v-on="on"><v-icon>mdi-xml</v-icon></v-btn></template><span>{{
                    $t("ordermng-download-invoice-xml")
                    }}</span></v-tooltip>
              </div>
            </template>

            <template v-slot:item.state="{ item }">
              <span v-if="item.validated">{{ getOrderState(item.state) }}</span>
              <span v-if="!item.validated">{{
                $t("ordermng-to-be-validated")
                }}</span>
              <v-tooltip v-if="item.validated && $store.state.hasRight('191')" bottom><template
                  v-slot:activator="{ on }"><v-btn x-small text icon color="orange darken-2"
                    @click="setOrderState(item)" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn></template><span>{{
                  $t("ordermng-change-state") }}</span></v-tooltip>
            </template>

            <template v-slot:item.payment_state="{ item }">
              {{ getOrderPaymentState(item.payment_state) }}
              <v-tooltip v-if="item.validated && $store.state.hasRight('190')" bottom><template
                  v-slot:activator="{ on }"><v-btn x-small text icon color="orange darken-2"
                    @click="setOrderPaymentState(item)" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn></template><span>{{
                  $t("ordermng-change-state") }}</span></v-tooltip>
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import ordersManager from "../apis/orders";
import usersManager from "../apis/users";

import FiltersBar from "../components/FiltersBar";
import messageBox from "../components/MessageBox";
import orderEditor from "../components/OrderEditor";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      orderEditorDialog: false,
      changeOrderStateDialog: false,
      orderStateDialogItem: null,
      orderStateDialogValue: null,

      changeOrderPaymentStateDialog: false,
      orderPaymentStateDialogItem: null,
      orderPaymentStateDialogValue: null,

      orderStates: staticData.ordersStates,
      paymentStates: staticData.ordersPaymentStates,
      shipment_date_modal: null,
      shipment_date_value: null,

      loan_expiration_date_modal: null,
      loan_expiration_date_value: null,

      options: {
        sortBy: ["id"],
        sortDesc: [true]
      },

      headers: [],
      perPage: staticData.defautPerPage,
      totalOrders: 0,
      filter: "",

      filters: {
        filterByUserId: null,
        filterByCustomerId: null,
        filterByPeriod: 11,
        filterBySalesPoint: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByOrderState: 0,
        filterByOrderSource: null,
        filterByInvoiceNumbers: null,
        filterByInvoiceYear: null,
        filterByDDTNumbers: null,
        filterByDDTYear: null,
        filterByDeliveryPoint: null,
        filterByCourierId: null
      },
      orders: [],
      loadingOrders: false,
      loadingFilters: true,

      stats: null,
      payed: null,
      unpayed: null,

      fetchingCouriers: false,
      changeCourierDialog: false,
      changeDeliverDialog: false,
      changeCourierValue: null,
      changeCourierItem: null,
      couriers: [],

      generating_ddt: false,
      generating_invoice: false,

      salesPoints: {},

      signatureData: null,
      showSignatureDialog: false,
      loanOrders: this.$route.meta.loan
    };
  },

  mounted() {
    if (this.loanOrders)
      this.orderStates = [
        ...this.orderStates,
        { name: this.$t("order-state-expired"), id: 4 }
      ];

    this.headers = [
      {
        text: this.$t("ordersmng-tbl-id"),
        align: "left",
        sortable: true,
        value: "id"
      }
    ];

    if (this.loanOrders) {
      this.headers = [
        ...this.headers,
        {
          text: this.$t("ordersmng-tbl-loan-expiration"),
          align: "left",
          sortable: true,
          value: "loan_expiration"
        }
      ];
    }
    this.headers = [
      ...this.headers,
      {
        text: this.$t("ordersmng-tbl-customer"),
        align: "left",
        sortable: true,
        value: "business_name"
      },
      {
        text: this.$t("ordersmng-tbl-date"),
        align: "left",
        sortable: true,
        value: "creation_date"
      },
      {
        text: this.$t("ordersmng-tbl-user"),
        align: "left",
        sortable: true,
        value: "user"
      },
      {
        text: this.$t("ordersmng-tbl-shipment-date"),
        align: "left",
        sortable: true,
        value: "shipment_date"
      },

      {
        text: this.$t("ordersmng-tbl-courier"),
        align: "left",
        sortable: true,
        value: "courier"
      },

      {
        text: this.$t("ordersmng-tbl-deliver"),
        align: "left",
        sortable: true,
        value: "deliver"
      }
    ];

    if (!this.loanOrders) {
      this.headers = [
        ...this.headers,
        {
          text: this.$t("ordersmng-tbl-state"),
          align: "left",
          sortable: true,
          value: "state"
        },
        {
          text: this.$t("ordersmng-tbl-payment-state"),
          align: "left",
          sortable: true,
          value: "payment_state"
        },
        {
          text: this.$t("ordersmng-tbl-signature"),
          align: "center",
          sortable: true,
          value: "signature_id"
        },

        {
          text: this.$t("ordersmng-tbl-invoice"),
          align: "left",
          sortable: true,
          value: "invoice_number"
        },

        {
          text: this.$t("ordersmng-tbl-sales-point"),
          align: "left",
          sortable: true,
          value: "sales_point"
        }
      ];
    }

    this.headers = [
      ...this.headers,
      {
        text: this.$t("ordersmng-tbl-gross"),
        align: "left",
        sortable: true,
        value: "total_gross"
      },
      {
        text: this.$t("ordersmng-tbl-amount"),
        align: "left",
        sortable: true,
        value: "amount"
      },
      {
        text: this.$t("ordersmng-tbl-ddt"),
        align: "left",
        sortable: true,
        value: "ddt_number"
      },
      {
        text: this.$t("ordersmng-tbl-notes"),
        align: "center",
        sortable: true,
        value: "notes"
      },
      {
        text: this.$t("ordersmng-tbl-private-notes"),
        align: "center",
        sortable: true,
        value: "private_notes"
      }
    ];

    this.headers = [
      ...this.headers,
      { value: "actions", sortable: false, text: "" }
    ];

    this.salesPoints = {};
    for (let x in this.$store.state.sales_points) {
      this.salesPoints[
        this.$store.state.sales_points[x].id
      ] = this.$store.state.sales_points[x].name;
    }
  },

  components: {
    messageBox,
    orderEditor,
    FiltersBar
  },

  computed: {
    getPaymentStates() {
      let ar = [...this.paymentStates].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return ar;
    },

    filterByPeriodText() {
      return staticData.statisticsPeriods[this.filters.filterByPeriod].name;
    },

    computeGrossValue() {
      if (this.stats)
        return this.$options.filters.toCurrencySymbol(
          this.stats.net_total + this.stats.vat_total
        );
      else return "...";
    },

    computeNetValue() {
      if (this.stats) {
        return this.$options.filters.toCurrencySymbol(this.stats.net_total);
      } else return "...";
    },

    computeTotalPayed() {
      if (this.payed) {
        return this.$options.filters.toCurrencySymbol(this.payed.net_total);
      } else return "...";
    },

    computeTotalUnpayed() {
      if (this.unpayed) {
        return this.$options.filters.toCurrencySymbol(this.unpayed.net_total);
      } else return "...";
    }
  },

  watch: {
    options: {
      handler() {
        this.fetchOrders();
      },
      deep: true
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchOrders();
        }, 300);
      }
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchOrders();
        }, 500);
      },
      deep: true
    }
  },

  methods: {
    showSignature(order_id) {
      ordersManager
        .fetchOrderSignature(order_id)
        .then(res => {
          this.signatureData = res;
          this.showSignatureDialog = true;
        })
        .catch(err => {
          console.log(err);
        });
    },

    getSalesPoint(item) {
      if (this.salesPoints[item.sales_point])
        return this.salesPoints[item.sales_point];
      return this.$t("gbl-none-male");
    },

    setCourier(item) {
      this.fetchingCouriers = true;
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "ordersmng-fetching-couriers"
      );
      usersManager
        .fetchCouriers()
        .then(res => {
          this.changeCourierItem = item;
          this.changeCourierValue = item.courier_id;
          this.couriers = res.items;
          this.fetchingCouriers = false;
          this.$store.state.global_overlay = false;
          this.changeCourierDialog = true;
        })
        .catch(err => {
          this.fetchingCouriers = false;
          this.$store.state.global_overlay = false;
          console.log(err);
        });
    },

    setDeliver(item) {
      this.fetchingCouriers = true;
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "ordersmng-fetching-couriers"
      );
      usersManager
        .fetchCouriers()
        .then(res => {
          this.changeCourierItem = item;
          this.changeCourierValue = item.deliver_id;
          this.couriers = res.items;
          this.fetchingCouriers = false;
          this.$store.state.global_overlay = false;
          this.changeDeliverDialog = true;
        })
        .catch(err => {
          this.fetchingCouriers = false;
          this.$store.state.global_overlay = false;
          console.log(err);
        });
    },

    handleChangeCourier() {
      ordersManager
        .changeCourier(this.changeCourierItem.id, this.changeCourierValue)
        .then(() => {
          this.changeCourierItem.courier_id = this.changeCourierValue;
          this.changeCourierItem = null;
          this.changeCourierValue = null;
          this.changeCourierDialog = false;
          this.fetchOrders();
        })
        .catch(err => {
          console.log(err);
          this.changeCourierDialog = false;
          this.changeCourierItem = null;
          this.changeCourierValue = null;
        });
    },

    handleChangeDeliver() {
      ordersManager
        .changeDeliver(this.changeCourierItem.id, this.changeCourierValue)
        .then(() => {
          this.changeCourierItem.courier_id = this.changeCourierValue;
          this.changeCourierItem = null;
          this.changeCourierValue = null;
          this.changeDeliverDialog = false;
          this.fetchOrders();
        })
        .catch(err => {
          console.log(err);
          this.changeDeliverDialog = false;
          this.changeCourierItem = null;
          this.changeCourierValue = null;
        });
    },

    onFiltersLoading() {
      this.loadingFilters = true;
    },

    onFiltersLoaded() {
      this.loadingFilters = false;
    },

    getOrderState(state) {
      return staticData.ordersStates[state].name;
    },

    getOrderPaymentState(state) {
      return staticData.ordersPaymentStates[state].name;
    },

    buildCtx() {
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByUserId = this.filters.filterByUserId;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCourierId = this.filters.filterByCourierId;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByOrderState = this.filters.filterByOrderState;
      ctx.filterByOrderSource = this.filters.filterByOrderSource;
      ctx.filterBySalesPoint = this.filters.filterBySalesPoint;
      ctx.filterByLoan = this.loanOrders;
      ctx.filterByInvoiceNumbers = this.filters.filterByInvoiceNumbers;
      ctx.filterByInvoiceYear = this.filters.filterByInvoiceYear;
      ctx.filterByDDTNumbers = this.filters.filterByDDTNumbers;
      ctx.filterByDDTYear = this.filters.filterByDDTYear;
      ctx.filterByDeliveryPoint = this.filters.filterByDeliveryPoint;
      return ctx;
    },

    fetchOrders() {
      this.loadingOrders = true;
      this.totalOrders = 0;
      this.orders = [];
      let ctx = this.buildCtx();
      ordersManager
        .fetchOrders(ctx)
        .then(result => {
          this.stats = result.stats;
          this.payed = result.payed;
          this.unpayed = result.unpayed;
          if (!this.payed.net_total) this.payed.net_total = 0.0;
          if (!this.unpayed.net_total) this.unpayed.net_total = 0.0;

          if (!this.stats.net_total) this.stats.net_total = 0.0;
          if (!this.stats.vat_total) this.stats.vat_total = 0.0;
          this.totalOrders = result.totalCount;
          this.orders = result.items;
          this.loadingOrders = false;
        })
        .catch(err => {
          this.loadingOrders = false;
          console.log(err);
        });
    },

    handleOrder(event) {
      if (event.editingOrder) {
        let order = event.order;
        ordersManager
          .updateOrder(order)
          .then(() => {
            this.fetchOrders();
            if (!event.continueInsert) this.orderEditorDialog = false;
            else this.$refs.orderEditor.resetAfterAdd();
          })
          .catch(err => {
            this.$refs.msgBox
              .show(err, this.$t("ordersmng-error-title"), true)
              .then(() => { })
              .catch(() => { });
            console.log(err);
            this.$refs.orderEditor.resetAfterAdd();
          });
      } else {
        let order = event.order;
        order.loan = this.loanOrders;
        ordersManager
          .addOrder(order)
          .then(() => {
            this.fetchOrders();
            if (!event.continueInsert) this.orderEditorDialog = false;
            else this.$refs.orderEditor.resetAfterAdd();
          })
          .catch(err => {
            this.$refs.msgBox
              .show(err, this.$t("ordersmng-error-title"), true)
              .then(() => { })
              .catch(() => { });

            console.log(err);
            this.$refs.orderEditor.resetAfterAdd();
          });
      }
    },

    exportCompactQuantities() {
      usersManager
        .keepAlive()
        .then(() => {
          let ctx = this.buildCtx();

          ordersManager
            .downloadOrdersQuantitiesCompact(ctx)
            .then(() => { })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    exportQuantities() {
      usersManager
        .keepAlive()
        .then(() => {
          let ctx = this.buildCtx();

          ordersManager
            .downloadOrdersQuantities(ctx)
            .then(() => { })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    addOrder() {
      let courierId = null;
      if (this.$store.state.hasRight("183") && !this.$store.state.isAdmin()) {
        courierId = this.$store.state.user.id;
      }

      this.$refs.orderEditor
        .setModalOrder(
          {
            customer_id: null,
            shipment_address_id: null,
            invoice_address_id: null,
            purchase_order: null,
            courier_id: courierId,
            no_shipment: false,
            num_of_packages: null,
            state: 0,
            notes: "",
            loan: this.loanOrders
          },
          false
        )
        .then(() => {
          this.orderEditorDialog = true;
        });
    },

    convertToOrder(item) {
      let orderId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("ordersmng-convert-text"),
          this.$t("ordersmng-convert-title")
        )
        .then(() => {
          ordersManager
            .convertLoanToOrder(orderId)
            .then(() => {
              // find id in  this.$store.state.user.notifications.loans
              this.$store.state.user.notifications.loans = this.$store.state.user.notifications.loans.filter(
                x => x.id != orderId
              );
              this.fetchOrders();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => { });
    },

    deleteItem(item) {
      let orderId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("ordersmng-delete-text"),
          this.$t("ordersmng-delete-title")
        )
        .then(() => {
          ordersManager
            .removeOrder(orderId)
            .then(() => {
              this.fetchOrders();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => { });
    },

    printResume(item) {
      usersManager
        .keepAlive()
        .then(() => {

          ordersManager
            .downloadOrdersQuantitiesItem(item.id)
            .then(() => { })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    editItem(item) {
      let order = JSON.parse(JSON.stringify(item));
      order.loan = this.loanOrders;
      this.$refs.orderEditor.setModalOrder(order, true).then(() => {
        this.orderEditorDialog = true;
      });
    },

    setShippingDate(item) {
      this.shipment_date_value = null;
      this.shipment_date_value_item = item;
      this.shipment_date_modal = true;
    },

    setLoanExpirationDate(item) {
      this.loan_expiration_date_value = null;
      this.loan_expiration_date_value_item = item;
      this.loan_expiration_date_modal = true;
    },

    setOrderState(item) {
      this.changeOrderStateDialog = true;
      this.orderStateDialogValue = item.state;
      this.orderStateDialogItem = item;
    },

    setOrderPaymentState(item) {
      this.changeOrderPaymentStateDialog = true;
      this.orderPaymentStateDialogValue = item.payment_state;
      this.orderPaymentStateDialogItem = item;
    },

    handleLoanExpirationDate() {
      this.$refs.dialog_loan_expiration_date.save(
        this.loan_expiration_date_value
      );
      ordersManager
        .changeLoanExpirationDate(
          this.loan_expiration_date_value_item.id,
          this.loan_expiration_date_value
        )
        .then(() => {
          this.loan_expiration_date_value_item.loan_expiration = this.loan_expiration_date_value;
          this.loan_expiration_date_value_item = null;
          this.loan_expiration_date_value = null;
        })
        .catch(err => {
          console.log(err);
          this.loan_expiration_date_value_item = null;
          this.loan_expiration_date_value = null;
        });
    },

    handleShipmentDate() {
      this.$refs.dialog_shipment_date.save(this.shipment_date_value);
      ordersManager
        .changeShipmentDate(
          this.shipment_date_value_item.id,
          this.shipment_date_value
        )
        .then(() => {
          this.shipment_date_value_item.shipment_date = this.shipment_date_value;
          this.shipment_date_value_item = null;
          this.shipment_date_value = null;
        })
        .catch(err => {
          console.log(err);
          this.shipment_date_value_item = null;
          this.shipment_date_value = null;
        });
    },

    handleChangeOrderState() {
      ordersManager
        .changeStatus(this.orderStateDialogItem.id, this.orderStateDialogValue)
        .then(() => {
          this.orderStateDialogItem.state = this.orderStateDialogValue;
          this.changeOrderStateDialog = false;
          this.orderStateDialogItem = null;
          this.orderStateDialogValue = null;
          this.fetchOrders();
        })
        .catch(err => {
          console.log(err);
          this.changeOrderStateDialog = false;
          this.orderStateDialogItem = null;
          this.orderStateDialogValue = null;
        });
    },

    handleChangePaymentOrderState() {
      ordersManager
        .changePaymentStatus(
          this.orderPaymentStateDialogItem.id,
          this.orderPaymentStateDialogValue
        )
        .then(() => {
          this.orderPaymentStateDialogItem.payment_state = this.orderPaymentStateDialogValue;
          this.changeOrderPaymentStateDialog = false;
          this.orderPaymentStateDialogItem = null;
          this.orderPaymentStateDialogValue = null;
        })
        .catch(err => {
          console.log(err);
          this.changeOrderPaymentStateDialog = false;
          this.orderPaymentStateDialogItem = null;
          this.orderPaymentStateDialogValue = null;
        });
    },

    downloadInvoice(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadInvoice(
              item.invoice_number,
              item.invoice_year,
              this.$store.state.user.locale
            )
            .then(() => { })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadInvoiceXml(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadInvoiceXml(
              item.invoice_number,
              item.invoice_year,
              this.$store.state.user.locale
            )
            .then(() => { })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    generateInvoice(item) {
      this.$refs.msgBox
        .show(
          this.$t("ordersmng-generate-invoice-text"),
          this.$t("ordersmng-generate-invoice-title")
        )
        .then(() => {
          this.generating_invoice = true;
          this.$store.state.global_overlay = true;
          this.$store.state.global_overlay_text = this.$t(
            "ordersmng-generate-invoice"
          );
          usersManager
            .keepAlive()
            .then(() => {
              ordersManager
                .generateInvoices([[item.id]])
                .then(res => {
                  this.generating_invoice = false;
                  this.$store.state.global_overlay = false;
                  item.invoice_generation_id = res[0][0].id;
                  item.invoice_number = res[0][0].invoice_number;
                  item.invoice_year = res[0][0].invoice_year;
                  item.state = 2;
                })
                .catch(err => {
                  this.generating_invoice = false;
                  this.$store.state.global_overlay = false;
                  console.log(err);
                });
            })
            .catch(err => {
              this.generating_invoice = false;
              this.$store.state.global_overlay = false;
              console.log(err);
            });
        })
        .catch(() => { });
    },

    generateDDT(item) {
      this.$refs.msgBox
        .show(
          this.$t("ordersmng-generate-ddt-text"),
          this.$t("ordersmng-generate-ddt-title")
        )
        .then(() => {
          this.generating_ddt = true;
          this.$store.state.global_overlay = true;
          this.$store.state.global_overlay_text = this.$t(
            "ordersmng-generate-ddt"
          );
          usersManager
            .keepAlive()
            .then(() => {
              ordersManager
                .generateDDT(item.id)
                .then(res => {
                  item.ddt_generation_id = res.id;
                  item.ddt_number = res.ddt_number;
                  item.ddt_year = res.ddt_year;
                  item.ddt_edi_path = res.ddt_edi_path;
                  item.state = 2;
                  this.generating_ddt = false;
                  this.$store.state.global_overlay = false;
                })
                .catch(err => {
                  console.log(err);
                  this.generating_ddt = false;
                  this.$store.state.global_overlay = false;
                });
            })
            .catch(err => {
              console.log(err);
              this.generating_ddt = false;
              this.$store.state.global_overlay = false;
            });
        })
        .catch(() => { });
    },

    generateAndPrintDDT(item, spooler) {
      this.$refs.msgBox
        .show(
          this.$t("ordersmng-generate-ddt-text"),
          this.$t("ordersmng-generate-ddt-title")
        )
        .then(() => {
          this.generating_ddt = true;
          this.$store.state.global_overlay = true;
          this.$store.state.global_overlay_text = this.$t(
            "ordersmng-generate-ddt"
          );
          usersManager
            .keepAlive()
            .then(() => {
              ordersManager
                .generateDDT(item.id)
                .then(res => {
                  item.ddt_generation_id = res.id;
                  item.ddt_number = res.ddt_number;
                  item.ddt_year = res.ddt_year;
                  item.ddt_edi_path = res.ddt_edi_path;
                  item.state = 2;
                  this.generating_ddt = false;
                  this.$store.state.global_overlay = false;
                  if (item.doc_generation_mode == 3)
                    this.printDDTWithAmounts(item, spooler);
                  else this.printDDT(item, spooler);
                })
                .catch(err => {
                  console.log(err);
                  this.generating_ddt = false;
                  this.$store.state.global_overlay = false;
                });
            })
            .catch(err => {
              console.log(err);
              this.generating_ddt = false;
              this.$store.state.global_overlay = false;
            });
        })
        .catch(() => { });
    },

    removeDDT(item) {
      this.$refs.msgBox
        .show(
          this.$t("ordersmng-delete-ddt-text"),
          this.$t("ordersmng-delete-ddt-title")
        )
        .then(() => {
          this.generating_ddt = true;
          this.$store.state.global_overlay = true;
          this.$store.state.global_overlay_text = this.$t(
            "ordersmng-generate-ddt"
          );
          usersManager
            .keepAlive()
            .then(() => {
              ordersManager
                .removeDDT(item.id)
                .then(() => {
                  item.ddt_generation_id = null;
                  item.ddt_number = null;
                  item.ddt_year = null;
                  item.ddt_edi_path = null;
                  item.state = 1;
                  this.generating_ddt = false;
                  this.$store.state.global_overlay = false;
                })
                .catch(err => {
                  console.log(err);
                  this.generating_ddt = false;
                  this.$store.state.global_overlay = false;
                });
            })
            .catch(err => {
              console.log(err);
              this.generating_ddt = false;
              this.$store.state.global_overlay = false;
            });
        })
        .catch(() => { });
    },

    downloadDDTEDI(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadDDTEDI(item.id)
            .then(() => { })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadDDTWithAmounts(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadDDTWithAmounts(item.id, this.$store.state.user.locale)
            .then(() => { })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadDDT(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadDDT(item.id, this.$store.state.user.locale)
            .then(() => { })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    printInvoice(item, spooler) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .printInvoice(
              item.invoice_number,
              item.invoice_year,
              this.$store.state.user.locale,
              spooler
            )
            .then(() => {
              if (item.state == 0) {
                ordersManager.changeStatus(item.id, 1).then(() => {
                  item.state = 1;
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    printDDTWithAmounts(item, spooler) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .printDDTWithAmounts(
              item.id,
              this.$store.state.user.locale,
              spooler
            )
            .then(() => {
              if (item.state == 0) {
                ordersManager.changeStatus(item.id, 1).then(() => {
                  item.state = 1;
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    printDDT(item, spooler) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .printDDT(item.id, this.$store.state.user.locale, spooler)
            .then(() => {
              if (item.state == 0) {
                ordersManager.changeStatus(item.id, 1).then(() => {
                  item.state = 1;
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
