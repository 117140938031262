<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <v-dialog
          overlay-opacity="0.6"
          persistent
          max-width="1280"
          :value="ticketsDialog"
        >
          <!-- @input is required to emit emit input if clicked outside unless we use persistent -->

          <v-container fill-height fluid grid-list-xl>
            <v-layout style="max-height:1080px" justify-center wrap>
              <v-flex md12>
                <base-material-card class="pa-0" color="primary">
                  <template v-slot:heading>
                    <v-row class="pa-0">
                      <v-col cols="1" class="pa-0"
                        ><v-icon large>mdi-currency-eur</v-icon></v-col
                      >
                      <v-col
                        align="center"
                        cols="10"
                        class="pa-0 card-title-text"
                        style="font-size: 25px"
                        >{{ $t("ticket-resume-title") }}</v-col
                      >
                      <v-col cols="1" class="pa-0 text-right"
                        ><v-icon @click="ticketsDialog = false" large
                          >mdi-close</v-icon
                        ></v-col
                      >
                    </v-row>
                  </template>
                  <v-card-text v-if="ticket">
                    <v-card flat class="">
                      <v-card-title>
                        <v-row dense>
                          <v-col class="text-left font-weight-bold ">
                            {{
                              ticket.c_business_name
                                ? ticket.c_business_name
                                : ticket.c_first_name + " " + ticket.c_last_name
                            }}
                            -
                            <span class="font-weight-light">{{
                              ticket.creation_date | toLocaleDateTime
                            }}</span></v-col
                          >
                          <v-col></v-col>
                          <v-col class="text-left font-weight-bold "
                            >Categoria
                            <span class="font-weight-light">{{
                              ticket.category_name
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="text-left font-weight-bold" dense>
                          <v-col
                            >Stato
                            <span class="font-weight-light">{{
                              getTicketState(ticket.state)
                            }}</span>
                          </v-col>
                          <v-col
                            >Ordine
                            <span class="font-weight-light">{{
                              ticket.ref_order
                            }}</span></v-col
                          ><v-col
                            >Rif. cliente
                            <span class="font-weight-light">{{
                              ticket.ref_number
                            }}</span></v-col
                          >
                        </v-row>
                        <v-divider></v-divider>

                        <v-divider></v-divider>
                      </v-card-title>
                      <v-card-text class="mt-4">
                        <v-row>
                          <v-col class="text-center font-weight-bold">{{
                            ticket.subject
                          }}</v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text>
                        <template v-for="item in ticket.items">
                          <div :key="item.id">
                            <v-row
                              v-if="!item.a_first_name"
                              class="caption"
                              dense
                            >
                              <v-col class="text-left "
                                >{{
                                  item.c_business_name
                                    ? item.c_business_name
                                    : item.c_first_name + " " + item.c_last_name
                                }}
                                - {{ item.time | toLocaleDateTime }}</v-col
                              >
                            </v-row>
                            <v-row
                              v-if="item.a_first_name"
                              class="caption"
                              dense
                            >
                              <v-col class="text-left "
                                >{{
                                  item.a_first_name + " " + item.a_last_name
                                }}
                                - {{ item.time | toLocaleDateTime }}</v-col
                              >
                            </v-row>
                            <v-row dense>
                              <v-col class="text-left ">{{
                                item.content
                              }}</v-col>
                              <v-col style="max-width:48px"
                                ><v-tooltip
                                  v-if="
                                    ticket.items.indexOf(item) != 0 &&
                                      $store.state.hasRight('244')
                                  "
                                  bottom
                                  ><template v-slot:activator="{ on }"
                                    ><v-btn
                                      x-small
                                      text
                                      icon
                                      color="red darken-2"
                                      @click="deleteAnswer(item)"
                                      v-on="on"
                                      ><v-icon>mdi-delete</v-icon></v-btn
                                    ></template
                                  ><span>{{
                                    $t("ticketsmng-ticket-remove")
                                  }}</span></v-tooltip
                                ></v-col
                              >
                            </v-row>
                          </div>
                        </template>
                      </v-card-text>
                      <v-textarea
                        v-model="ticketAnswer"
                        label="Risposta"
                        outlined
                        dense
                        multi-line
                        rows="4"
                      ></v-textarea>
                    </v-card>
                  </v-card-text>
                  <v-btn
                    v-if="$store.state.hasRight('242')"
                    :disabled="ticketAnswer.length < 2 || sendingAnswer"
                    @click="answerTicket(ticket)"
                    color="primary"
                    >{{ $t("ticketsmng-answer-ticket") }}</v-btn
                  >
                  <v-btn
                    v-if="$store.state.hasRight('243')"
                    :disabled="ticket.state == 2"
                    @click="closeTicket(ticket)"
                    color="secondary"
                    >{{ $t("ticketsmng-close-ticket") }}</v-btn
                  >
                  <v-btn @click="ticketsDialog = false" color="primary">{{
                    $t("gbl-close")
                  }}</v-btn>
                </base-material-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>

        <messageBox ref="msgBox" />

        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="success darken-1"
              icon="mdi-currency-eur"
              :title="$t('tickets-total')"
              :value="this.stats ? this.stats.total.toString() : '...'"
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="success darken-2"
              icon="mdi-currency-eur"
              :title="$t('tickets-total-open')"
              :value="
                this.stats &&
                this.stats.submitted != null &&
                this.stats.processing != null
                  ? (this.stats.submitted + this.stats.processing).toString()
                  : '...'
              "
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="success"
              icon="mdi-currency-eur"
              :title="$t('tickets-total-closed')"
              :value="
                this.stats && this.stats.closed != null
                  ? this.stats.closed.toString()
                  : '...'
              "
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <base-material-stats-card
              color="warning"
              icon="mdi-currency-eur"
              :title="$t('tickets-total-users')"
              :value="
                this.stats && this.stats.users != null
                  ? this.stats.users.toString()
                  : '...'
              "
              sub-icon="mdi-calendar"
              :sub-text="filterByPeriodText"
            />
          </v-col>
        </v-row>

        <div class="vertical-spacer" />
        <FiltersBar
          :filters="filters"
          :disabled="loadingTickets"
          @onFiltersLoading="onFiltersLoading"
          @onFiltersLoaded="onFiltersLoaded"
          :showLoader="true"
          :OrdersFilter="false"
          :TicketsFilter="true"
          :LoanOrders="false"
        />

        <base-material-card
          v-if="!loadingFilters"
          color="primary"
          icon="mdi-cube-send"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("ticketsmng-title") }}
            </div>
          </template>

          <div class="vertical-spacer" />

          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="tickets"
            :options.sync="options"
            :server-items-length="totalTickets"
            :loading="loadingTickets"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.id="{ item }">
              <v-chip class="mr-2" color="blue" dark>{{ item.id }}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-row
                ><v-col class="text-center">
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click="openTicket(item)"
                        v-on="on"
                        ><v-icon>mdi-ticket</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("ticketsmng-ticket-details")
                    }}</span></v-tooltip
                  ><v-tooltip v-if="$store.state.hasRight('241')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="red darken-2"
                        @click="removeTicket(item)"
                        v-on="on"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("ticketsmng-ticket-remove")
                    }}</span></v-tooltip
                  >
                </v-col></v-row
              ></template
            >
            <template v-slot:item.creation_date="{ item }">
              {{ item.creation_date | toLocaleDateTime }}
            </template>

            <template v-slot:item.state="{ item }">
              <span>{{ getTicketState(item.state) }}</span>
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import ticketsManager from "../apis/tickets";

import FiltersBar from "../components/FiltersBar";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      changeTicketStateDialog: false,
      ticketStateDialogItem: null,
      ticketStateDialogValue: null,

      options: {
        sortBy: ["id"],
        sortDesc: [true]
      },

      headers: [],
      perPage: staticData.defautPerPage,
      totalTickets: 0,
      tickets: [],
      ticket: {},
      filters: {
        filterByUserId: null,
        filterByCustomerId: null,
        filterByPeriod: 14,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByTicketState: 3
      },
      loadingTickets: false,
      loadingFilters: true,

      stats: null,
      ticketsDialog: false,
      ticketAnswer: "",
      sendingAnswer: false
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("ticketsmng-tbl-id"),
        align: "left",
        sortable: true,
        value: "id"
      },
      {
        text: this.$t("ticketsmng-tbl-customer"),
        align: "left",
        sortable: true,
        value: "business_name"
      },
      {
        text: this.$t("ticketsmng-tbl-state"),
        align: "left",
        sortable: true,
        value: "state"
      },
      {
        text: this.$t("ticketsmng-tbl-date"),
        align: "left",
        sortable: true,
        value: "creation_date"
      }
    ];

    this.headers = [
      ...this.headers,
      { value: "actions", sortable: false, text: "" }
    ];
  },

  components: {
    messageBox,
    FiltersBar
  },

  computed: {
    filterByPeriodText() {
      return staticData.statisticsPeriods[this.filters.filterByPeriod].name;
    }
  },

  watch: {
    options: {
      handler() {
        this.fetchTickets();
      },
      deep: true
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchTickets();
        }, 500);
      },
      deep: true
    }
  },

  methods: {
    getTicketState(id) {
      if (!id) return "";
      return staticData.ticketsStates.find(s => s.id == id).name;
    },

    onFiltersLoading() {
      this.loadingFilters = true;
    },

    onFiltersLoaded() {
      this.loadingFilters = false;
    },

    closeTicket(item) {
      this.$refs.msgBox
        .show(
          this.$t("ticketsmng-close-text"),
          this.$t("ticketsmng-close-title")
        )
        .then(() => {
          ticketsManager
            .closeTicket(item.id)
            .then(() => {
              this.ticketsDialog = false;
              this.fetchTickets();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    fetchTickets() {
      this.loadingTickets = true;
      this.totalTickets = 0;
      this.tickets = [];
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByTicketState = this.filters.filterByTicketState;

      ticketsManager
        .fetchTickets(ctx)
        .then(result => {
          this.stats = result.stats;
          this.totalTickets = result.totalTickets;
          this.tickets = result.tickets;
          this.loadingTickets = false;
        })
        .catch(err => {
          this.loadingTickets = false;
          console.log(err);
        });
    },

    answerTicket(ticket) {
      this.sendingAnswer = true;
      ticketsManager
        .answerTicket(ticket.id, this.ticketAnswer)
        .then(res => {
          this.sendingAnswer = false;
          this.ticketAnswer = "";
          if (res.answer) {
            this.ticket.items.push(res.answer);
            this.fetchTickets();
          }
        })
        .catch(err => {
          this.sendingAnswer = false;
          console.log(err);
        });
    },

    openTicket(item) {
      this.ticketAnswer = "";
      ticketsManager
        .fetchTicket(item.id)
        .then(result => {
          this.ticket = result.ticket;
          this.ticketsDialog = true;
        })
        .catch(err => {
          console.log(err);
        });
    },

    deleteAnswer(item) {
      this.$refs.msgBox
        .show(
          this.$t("ticketsmng-delete-text"),
          this.$t("ticketsmng-delete-title")
        )
        .then(() => {
          ticketsManager
            .removeAnswer(item.id)
            .then(() => {
              ticketsManager
                .fetchTicket(this.ticket.id)
                .then(result => {
                  this.ticket = result.ticket;
                })
                .catch(err => {
                  console.log(err);
                });
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    removeTicket(item) {
      this.$refs.msgBox
        .show(
          this.$t("ticketsmng-delete-text"),
          this.$t("ticketsmng-delete-title")
        )
        .then(() => {
          ticketsManager
            .removeTicket(item.id)
            .then(() => {
              this.fetchTickets();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    }
  }
};
</script>
