import axios from "axios";

import store from "../store";
import i18n from "../plugins/i18n";
import config from "../config";

let helpers = {
  printURL: function(url, spooler, doc_num, doc_year) {
    return new Promise((resolve, reject) => {
      if (!spooler) {
        let pdfFrame = document.body.appendChild(
          document.createElement("iframe")
        );
        pdfFrame.style.display = "none";
        pdfFrame.onload = function() {
          pdfFrame.contentWindow.onafterprint = function(/*event*/) {
            pdfFrame.remove();
            resolve();
          };
          pdfFrame.contentWindow.print();
        };
        pdfFrame.src = url;
      } else {
        // Send the URL to the spooler
        store.state.global_overlay = true;
        store.state.global_overlay_text = i18n.t(
          "gbl-sending-document-to-printer-spool"
        );

        let spooler_url =
          "http://localhost:" + config.printerSpoolLocalPort + "/job";
        axios
          .post(spooler_url, {
            url: url,
            copies: store.state.spooler_num_of_copies,
            doc_num: doc_num,
            doc_year: doc_year,
          })
          .then(() => {
            store.state.global_overlay = false;
            resolve();
          })
          .catch((err) => {
            store.state.global_overlay = false;
            console.log(err);
            reject(err);
          });
      }
    });
  },
};

export default helpers;
