<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="600" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large
                    >mdi-office-building-marker-outline</v-icon
                  ></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{
                    editingContact
                      ? $t("contact-editor-title-modify")
                      : $t("contact-editor-title-add")
                  }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon v-if="!sending_changes" @click="closeDialog" large
                    >mdi-close</v-icon
                  ></v-col
                >
              </v-row>
            </template>
            <div class="vertical-spacer" />
            <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
              <v-progress-circular
                v-if="loadingTypes"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="primary"
              ></v-progress-circular>

              <div v-if="!loadingTypes">
                <v-row v-if="!loadingTypes" dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        :label="$t('contact-editor-type')"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        :disabled="loadingTypes"
                        v-model="contact_type"
                        :items="contact_types"
                        return-object
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      :immediate="editingContact"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="contact.name"
                        :label="$t('contact-editor-name')"
                        outlined
                        dense
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      v-model="contact.phone"
                      :label="$t('contact-editor-phone')"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="contact.mobile"
                      :label="$t('contact-editor-mobile')"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      v-model="contact.email"
                      :label="$t('contact-editor-email')"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="contact.secondary_email"
                      :label="$t('contact-editor-secondary-email')"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <div style="height:10px" />
                <v-btn
                  v-if="!sending_changes"
                  width="120"
                  :disabled="invalid"
                  v-on:click="confirm()"
                  color="primary"
                  >{{ $t("gbl-ok") }}</v-btn
                >
                <v-progress-circular
                  v-if="sending_changes"
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="primary"
                ></v-progress-circular>

                <v-btn
                  :disabled="sending_changes"
                  width="120"
                  v-on:click="closeDialog()"
                  color="secondary"
                  >{{ $t("gbl-cancel") }}</v-btn
                >
              </div>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import customersManager from "../apis/customers";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      loadingTypes: false,
      editingContact: false,
      sending_changes: false,
      contact_types: [],
      contact_type: 4,
      contact: {},
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: ["value"],

  mounted() {
    this.fetchContactType();
  },

  watch: {},

  computed: {},

  methods: {
    closeDialog() {
      this.$emit("input");
    },

    confirm() {
      this.contact.contact_type_locale = this.contact_type.locale_name;
      this.contact.contact_type = this.contact_type.id;
      this.$emit("contactConfigured", {
        editingContact: this.editingContact,
        contact: this.contact,
      });
      this.sending_changes = true;
    },

    cancel() {
      this.closeDialog();
    },

    fetchContactType() {
      this.loadingTypes = true;
      customersManager
        .fetchContactTypes()
        .then((res) => {
          this.contact_types = res.types.map((x) => {
            x.locale_name = x.name;
            x.name = this.$t(x.name);
            if (this.contact.contact_type == x.id) this.contact_type = x;
            return x;
          });
          this.loadingTypes = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingTypes = false;
        });
    },

    setContact(item, editing) {
      this.editingContact = editing;
      this.reset();
      this.contact = Object.assign({}, item);
      for (let x = 0; x < this.contact_types.length; x++) {
        let type = this.contact_types[x];
        if (type.id == this.contact.contact_type) {
          this.contact_type = type;
          this.contact.contact_type = type.id;
          this.contact.contact_type_locale = type.locale_name;
          break;
        }
      }
    },

    reset() {
      this.sending_changes = false;
      this.contact = {
        type: 4,
        name: "",
        phone: "",
        mobile: "",
        email: "",
        secondary_email: "",
      };
      this.contact_type = 4;
    },
  },
};
</script>
