<template>
<v-dialog persistent overlay-opacity="0.6" max-width="1200" :value="value" @input="closeDialog()"> <!-- @input is required to emit emit input if clicked outside unless we use persistent -->           
  <v-container fill-height fluid grid-list-xl> 
    <v-layout justify-center wrap>
       <v-flex md12>
        <base-material-card class="pa-0" color="primary">
        <template v-slot:heading>
            <v-row class="pa-0">
              <v-col cols=1 class="pa-0"><v-icon large>mdi-home</v-icon></v-col>
              <v-col align="center" cols=10 class="pa-0 card-title-text" style="font-size: 25px">{{$t('fileselector-title')}}</v-col>
              <v-col cols=1 class="pa-0 text-right"><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col>
            </v-row>
          </template>
        <v-card-text>
        <FilesView :selectionMode="true" @fileSelected="onFileSelection" :path="fileViewPath"></FilesView>
        </v-card-text>
        </base-material-card>
       </v-flex>
    </v-layout>
  </v-container>
</v-dialog>
</template>

<script>

  import FilesView from '../components/FilesView'

  export default {
    data() {
      return {
        fileViewPath : this.$t('filemanager-root-path')
      }
    },

    components :{
        FilesView
    },

    props : ['value'],

    methods: {
      onFileSelection(result) {
        this.$emit('fileSelected',result);
      },

      closeDialog() {
        this.$emit('input');
        this.$emit('fileSelected');
      },

    }
  }
</script>