<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <FiltersBar
          :filters="filters"
          :disabled="loadingDashboard"
          @onFiltersLoading="onFiltersLoading"
          @onFiltersLoaded="onFiltersLoaded"
          :showLoader="true"
          :FinancialDashboardFilter="true"
        />
        <div class="vertical-spacer" />

        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header ripple color="primary">
              <template v-slot:actions>
                <v-icon color="white">
                  $expand
                </v-icon>
              </template>

              <span style="font-size:25px" class="white--text card-title-text">
                {{ $t("dashboard-financial-statistics") }}
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  v-if="$store.state.hasRight('56')"
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <base-material-stats-card
                    color="success darken-1"
                    icon="mdi-currency-eur"
                    :title="$t('dashboard-total-net-value')"
                    :value="computeNetValue"
                    sub-icon="mdi-calendar"
                    :sub-text="filterByPeriodText"
                  />
                </v-col>
                <v-col
                  v-if="$store.state.hasRight('56')"
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <base-material-stats-card
                    color="success darken-2"
                    icon="mdi-currency-eur"
                    :title="$t('dashboard-total-gross-value')"
                    :value="computeGrossValue"
                    sub-icon="mdi-calendar"
                    :sub-text="filterByPeriodText"
                  />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                  <base-material-stats-card
                    color="success"
                    icon="mdi-currency-eur"
                    :title="$t('dashboard-total-payed')"
                    :value="computeTotalPayed"
                    sub-icon="mdi-calendar"
                    :sub-text="filterByPeriodText"
                  />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                  <base-material-stats-card
                    color="warning"
                    icon="mdi-currency-eur"
                    :title="$t('dashboard-total-not-payed')"
                    :value="computeTotalUnpayed"
                    sub-icon="mdi-calendar"
                    :sub-text="filterByPeriodText"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" lg="3">
                  <base-material-stats-card
                    color="success darken-1"
                    icon="mdi-layers"
                    :title="$t('dashboard-total-orders')"
                    :value="computeOrdersCount"
                    sub-icon="mdi-calendar"
                    :sub-text="filterByPeriodText"
                  />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                  <base-material-stats-card
                    color="success darken-2"
                    icon="mdi-account-multiple"
                    :title="$t('dashboard-total-customers')"
                    :value="computeCustomersCount"
                    sub-icon="mdi-calendar"
                    :sub-text="filterByPeriodText"
                  />
                </v-col>
                <v-col
                  v-if="$store.state.hasRight('56')"
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <base-material-stats-card
                    color="success"
                    icon="mdi-chart-areaspline"
                    :title="$t('dashboard-average-per-customer')"
                    :value="computeCustomerAverage"
                    sub-icon="mdi-calendar"
                    :sub-text="filterByPeriodText"
                  />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                  <base-material-stats-card
                    color="warning"
                    icon="mdi-medal"
                    :title="$t('dashboard-top-product')"
                    :value="computeTopProduct"
                    sub-icon="mdi-calendar"
                    :sub-text="filterByPeriodText"
                    class="product-stats-card"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-row>
          <v-col cols="6">
            <base-material-card class="pa-0" color="success">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("dashboard-top-products-title") }}</v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-data-table
                  v-if="topProducts"
                  :headers="products_headers"
                  hide-default-header
                  :items="topProducts"
                  class="elevation-1"
                  :items-per-page="products_options.itemsPerPage"
                  :server-items-length="totalTopProducts"
                  :footer-props="dataTableFooterOptions"
                  :options.sync="products_options"
                >
                  <template v-slot:item.net="{ item }">
                    {{ item.net | toCurrencySymbol }}
                  </template>
                  <template v-slot:item.count="{ item }">
                    {{ item.count.toFixed(2) + " " + $t(item.measure_unit) }}
                  </template>
                </v-data-table>

                <v-progress-circular
                  class="mt-3"
                  v-if="
                    loadingDashboard && (!topProducts || topProducts.length < 1)
                  "
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="primary"
                ></v-progress-circular>
              </v-card-text>
            </base-material-card>
          </v-col>
          <v-col cols="6">
            <base-material-card class="pa-0" color="success">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col
                    align="center"
                    class="pa-0 card-title-text"
                    style="font-size: 25px"
                    >{{ $t("dashboard-top-customers-title") }}</v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-data-table
                  v-if="topCustomers"
                  :headers="customers_headers"
                  hide-default-header
                  :items="topCustomers"
                  class="elevation-1"
                  :items-per-page="customers_options.itemsPerPage"
                  :server-items-length="totalTopCustomers"
                  :footer-props="dataTableFooterOptions"
                  :options.sync="customers_options"
                >
                  >
                  <template v-slot:item.net="{ item }">
                    {{ item.net | toCurrencySymbol }}
                  </template>
                  <template v-slot:item.count="{ item }">
                    {{ item.count }} {{ $t("dashboard-orders") }}
                  </template>
                </v-data-table>
                <v-progress-circular
                  class="mt-3"
                  v-if="
                    loadingDashboard &&
                      (!topCustomers || topCustomers.length < 1)
                  "
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="primary"
                ></v-progress-circular>
              </v-card-text>
            </base-material-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import FiltersBar from "../components/FiltersBar";
import staticData from "../apis/static";
import dashboard from "../apis/dashboard";

export default {
  data() {
    return {
      dataTableFooterOptions: {
        showFirstLastPage: true,
        "items-per-page-options": [5, 10, 20, 50, 100]
      },
      loadingFilters: true,
      dashboardState: {},
      customers_options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: []
      },
      products_options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: []
      },
      filters: {
        filterByUserId: null,
        filterByCustomerId: null,
        filterByProductId: null,
        filterByPeriod: 10,
        filterBySalesPoint: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByOrderState: null,
        filterByInvoiceState: null,
        filterByCourierId: null,
        filterByCustomerCategory: null,
        filterByProductCategory: null
      },
      totalTopCustomers: 0,
      totalTopProducts: 0,
      loadingDashboard: false,
      stats: null,
      payed: null,
      unpayed: null,
      topCustomers: null,
      topProducts: null,
      ordersCount: null,
      customersCount: null,
      customerAverage: 0,
      products_headers: [
        { text: "name", value: "name" },
        { text: "net", value: "net" },
        { text: "count", value: "count" }
      ],
      customers_headers: [
        { text: "name", value: "name" },
        { text: "net", value: "net" },
        { text: "count", value: "count" }
      ]
    };
  },

  watch: {
    customers_options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchDashboard();
        }, 300);
      }
    },

    products_options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchDashboard();
        }, 300);
      }
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchDashboard();
        }, 300);
      }
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchDashboard();
        }, 500);
      },
      deep: true
    }
  },

  computed: {
    filterByPeriodText() {
      return staticData.statisticsPeriods[this.filters.filterByPeriod].name;
    },

    computeOrdersCount() {
      if (this.ordersCount) return this.ordersCount.toString();
      else return "";
    },

    computeCustomersCount() {
      if (this.customersCount) return this.customersCount.toString();
      else return "";
    },

    computeTopProduct() {
      if (this.topProducts && this.topProducts.length > 0)
        return this.topProducts[0].name;
      else return "";
    },
    computeGrossValue() {
      if (this.stats)
        return this.$options.filters.toCurrencySymbol(
          this.stats.net_total + this.stats.vat_total
        );
      else return "...";
    },

    computeCustomerAverage() {
      return this.$options.filters.toCurrencySymbol(this.customerAverage);
    },

    computeNetValue() {
      if (this.stats) {
        return this.$options.filters.toCurrencySymbol(this.stats.net_total);
      } else return "...";
    },

    computeTotalPayed() {
      if (this.payed) {
        return this.$options.filters.toCurrencySymbol(this.payed.net_total);
      } else return "...";
    },

    computeTotalUnpayed() {
      if (this.unpayed) {
        return this.$options.filters.toCurrencySymbol(this.unpayed.net_total);
      } else return "...";
    }
  },

  components: {
    FiltersBar
  },

  mounted() {
    this.fetchDashboard();
  },

  methods: {
    onFiltersLoading() {
      this.loadingFilters = true;
    },

    onFiltersLoaded() {
      this.loadingFilters = false;
    },

    fetchDashboard() {
      this.loadingDashboard = true;
      this.topProducts = [];
      this.totalTopProducts = 0;
      this.totalTopCustomers = 0;
      this.topCustomers = [];
      this.stats = null;
      this.payed = null;
      this.unpayed = null;
      this.ordersCount = null;
      this.customersCount = null;
      this.customerAverage = null;

      let ctx = {};
      ctx.customersCurrentPage = this.customers_options.page;
      ctx.customersPerPage =
        this.customers_options.itemsPerPage != -1
          ? this.customers_options.itemsPerPage
          : null;
      ctx.productsCurrentPage = this.products_options.page;
      ctx.productsPerPage =
        this.products_options.itemsPerPage != -1
          ? this.products_options.itemsPerPage
          : null;

      ctx.filterByUserId = this.filters.filterByUserId;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterBySalesPoint = this.filters.filterBySalesPoint;
      ctx.filterByProductCategory = this.filters.filterByProductCategory;
      ctx.filterByCustomerCategory = this.filters.filterByCustomerCategory;
      ctx.filterByProductId = this.filters.filterByProductId;

      dashboard
        .fetchFinancialDashboard(ctx)
        .then(result => {
          this.stats = result.stats;
          this.payed = result.payed;
          this.unpayed = result.unpayed;
          this.topProducts = result.topProducts;
          this.totalTopProducts = result.topProductsCountResults.count;
          this.totalTopCustomers = result.topCustomersCountResults.count;

          this.topCustomers = result.topCustomers;
          this.ordersCount = result.ordersCount.count;
          this.customersCount = result.customersCount.count;
          if (!this.payed.net_total) this.payed.net_total = 0.0;
          if (!this.unpayed.net_total) this.unpayed.net_total = 0.0;
          if (!this.stats.net_total) this.stats.net_total = 0.0;
          if (!this.stats.vat_total) this.stats.vat_total = 0.0;

          this.customerAverage = this.customersCount
            ? this.stats.net_total / this.customersCount
            : 0;

          this.paymentStats = result.paymentStats;
          this.loadingDashboard = false;
        })
        .catch(err => {
          this.loadingDashboard = false;
          console.log(err);
        });
    }
  }
};
</script>

<style>
.sales-chart {
  margin-top: 10px;
}

.sales-chart .ct-chart-donut .ct-label {
  fill: white;
  font-size: 16px;
}

.sales-chart .ct-chart-donut .ct-series-a {
  stroke: #2196fc;
}

.sales-chart .ct-chart-donut .ct-series-a .ct-slice-donut-solid {
  fill: #2196fc;
}

.sales-chart .ct-chart-donut .ct-series-b {
  stroke: #ffc107;
}

.sales-chart .ct-chart-donut .ct-series-b .ct-slice-donut-solid {
  fill: #ffc107;
}

.sales-chart .ct-chart-donut .ct-series-c {
  stroke: #f44336;
}

.sales-chart .ct-chart-donut .ct-series-c .ct-slice-donut-solid {
  fill: #f44336;
}

.sales-bar-chart {
  margin-top: 10px;
}

.sales-bar-chart .ct-bar {
  stroke-width: 60px;
}

.sales-bar-chart .ct-series-a .ct-bar {
  stroke: #2196fc;
}

.sales-bar-chart .ct-series-b .ct-bar {
  stroke: #ffc107;
}

.sales-bar-chart .ct-series-c .ct-bar {
  stroke: #f44336;
}

.product-stats-card h3 {
  font-size: 14px;
  height: 40px;
  width: 170px;
  word-wrap: break-word;
}
</style>
