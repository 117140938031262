<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="500"
    :value="value"
    @input="closeDialog()"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-mail</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("send-allergens-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-card-text>
              <v-row dense class="text-subtitle-1"
                ><v-col>
                  {{ item ? item.name : "" }}
                </v-col></v-row
              >
              <v-row dense>
                <v-col>
                  {{ $t("send-allergens-select-customer") }}
                  <v-autocomplete
                    :disabled="
                      isLoadingCustomer ||
                        sending_regular_mail ||
                        sending_legal_mail
                    "
                    clearable
                    dense
                    no-filter
                    v-model="flt_customer"
                    :items="flt_customers"
                    :loading="isLoadingCustomer"
                    :search-input.sync="searchCustomerInput"
                    outlined
                    item-text="display_name"
                    item-value="id"
                    :label="$t('filters-customers')"
                    return-object
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  {{ $t("send-allergens-select-email") }}
                  <v-select
                    :disabled="
                      isLoadingCustomer ||
                        isLoadingAddresses ||
                        !customer_id ||
                        sending_regular_mail ||
                        sending_legal_mail
                    "
                    :label="$t('address-editor-hint-type')"
                    item-text="context"
                    item-value="id"
                    outlined
                    dense
                    v-model="mail_address"
                    :items="mail_addresses"
                    return-object
                    hide-details
                  >
                    <template slot="selection" slot-scope="data">
                      ({{ data.item.context }}) {{ data.item.email }} -
                      {{ $t(data.item.type) }}
                    </template>
                    <template slot="item" slot-scope="data">
                      ({{ data.item.context }}) {{ data.item.email }} -
                      {{ $t(data.item.type) }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-alert
                class="ma-2"
                dense
                type="success"
                color="green"
                dismissible
                v-if="send_result"
              >
                {{ $t("send-allergens-success") }}
              </v-alert>

              <v-alert
                class="ma-2"
                dense
                type="error"
                dismissible
                v-if="send_error"
              >
                {{ send_error }}
              </v-alert>

              <div style="height:10px" />
              <v-btn
                v-if="!sending_legal_mail && !sending_regular_mail"
                :disabled="
                  sending_regular_mail || !mail_address || !customer_id
                "
                v-on:click="sendLegalMail()"
                color="primary"
                >{{ $t("send-allergens-send-legal") }}</v-btn
              >

              <v-btn
                v-if="!sending_legal_mail && !sending_regular_mail"
                :disabled="sending_legal_mail || !mail_address || !customer_id"
                v-on:click="sendRegularMail()"
                color="orange"
                >{{ $t("send-allergens-send-normal") }}</v-btn
              >
              <v-progress-circular
                v-if="sending_regular_mail || sending_legal_mail"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="primary"
              ></v-progress-circular>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import config from "../config";

import customersManager from "../apis/customers";
import productsManager from "../apis/products";

export default {
  data() {
    return {
      item: null,
      flt_customer: null,
      flt_customers: [],
      searchCustomerInput: null,
      isLoadingCustomer: false,
      isLoadingAddresses: false,
      customer_id: null,
      sending_regular_mail: false,
      sending_legal_mail: false,
      mail_addresses: [],
      mail_address: null,
      send_result: null,
      send_error: null,
    };
  },

  props: ["value"],

  methods: {
    sendLegalMail() {
      this.send_result = null;
      this.send_error = null;

      this.sending_legal_mail = true;
      productsManager
        .sendAllergen({
          name: this.item.name,
          path: this.item.allergen_card,
          address: this.mail_address.email,
        })
        .then((res) => {
          if (res.responseError) {
            this.send_error = res.responseError;
            this.send_result = false;
          } else this.send_result = true;
          this.sending_regular_mail = false;
        })
        .catch((err) => {
          this.sending_legal_mail = false;
          if (err) {
            this.send_error = err;
            this.send_result = false;
          } else this.send_result = true;
        });
    },

    sendRegularMail() {
      this.send_result = null;
      this.send_error = null;

      this.sending_regular_mail = true;
      productsManager
        .sendAllergen({
          name: this.item.name,
          path: this.item.allergen_card,
          address: this.mail_address.email,
        })
        .then((res) => {
          if (res.responseError) {
            this.send_error = res.responseError;
            this.send_result = false;
          } else this.send_result = true;
          this.sending_regular_mail = false;
        })
        .catch((err) => {
          this.sending_regular_mail = false;
          if (err) {
            this.send_error = err;
            this.send_result = false;
          } else this.send_result = true;
        });
    },

    closeDialog() {
      this.$emit("input");
    },

    resetModal() {
      this.flt_customer = null;
      this.flt_customers = [];
      this.searchCustomerInput = null;
      this.isLoadingCustomer = false;
      this.isLoadingAddresses = false;
      this.customer_id = null;
      this.sending_regular_mail = false;
      this.sending_legal_mail = false;
      this.item = null;
      this.customer_id = null;
      this.mail_address = null;
      this.mail_addresses = [];
      this.customers = [];
      this.send_result = null;
    },

    setItem(item) {
      this.resetModal();
      this.item = item;
    },

    fetchFilterCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchCustomerMailAddresses(customerId) {
      this.isLoadingAddresses = true;
      this.mail_address = null;
      this.mail_addresses = [];
      customersManager
        .fetchCustomerMailAddresses(customerId)
        .then((results) => {
          this.isLoadingAddresses = false;
          this.mail_addresses = results.addresses;
        })
        .catch((err) => {
          this.isLoadingAddresses = false;
          console.log(err);
        });
    },
  },
  watch: {
    searchCustomerInput(val) {
      clearTimeout(this._searchCustomerInput);
      if (val) {
        this._searchCustomerInput = setTimeout(() => {
          this.isLoadingCustomer = true;
          this.fetchFilterCustomers(val)
            .then((res) => {
              this.flt_customers = res.customers.map((x) => {
                if (x.business_name) x.display_name = x.business_name;
                else x.display_name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomer = false;
            })
            .catch(() => {
              this.isLoadingCustomer = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        this.customer_id = null;
      }
    },

    flt_customer: {
      handler() {
        this.customer_id = this.flt_customer ? this.flt_customer.id : null;
      },
    },

    customer_id: {
      handler() {
        this.mail_address = null;
        this.mail_addresses = [];
        if (this.customer_id) this.fetchCustomerMailAddresses(this.customer_id);
      },
    },
  },
};
</script>
