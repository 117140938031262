<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <messageBox ref="msgBox" />
        <creditNoteEditor
          ref="creditNoteEditor"
          @createNC="handleCreditNote"
          v-model="creditNoteEditorDialog"
        />

        <v-btn
          v-if="
            $store.state.hasRight('195') &&
              (orders_unique ||
                (orders_unique_customers && orders_unique_address))
          "
          :disabled="!orders_selection || loadingFilters || building_invoices"
          @click="createInvoices()"
          color="primary"
          >{{ $t("invoicesmng-create-invoice") }}</v-btn
        >
        <v-btn
          v-if="$store.state.hasRight('195')"
          :disabled="loadingFilters || building_invoices"
          @click="createCreditNote()"
          color="primary"
          >{{ $t("invoicesmng-create-credit-note") }}</v-btn
        >

        <v-btn
          :disabled="!orders_selection || loadingFilters || building_invoices"
          v-if="!orders_unique && $store.state.hasRight('195')"
          @click="createInvoicesMultiple()"
          color="primary"
          >{{ $t("invoicesmng-create-multiple-invoices") }}</v-btn
        >

        <div class="vertical-spacer" />
        <FiltersBar
          :filters="filters"
          :disabled="loadingOrders"
          @onFiltersLoading="onFiltersLoading"
          @onFiltersLoaded="onFiltersLoaded"
          :showLoader="true"
          :OrdersFilter="true"
          :hideInvoicesFilters="true"
        />

        <base-material-card
          v-if="!loadingFilters"
          color="primary"
          icon="mdi-cube-send"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("invoicesmng-title") }}
            </div>
          </template>

          <div style="height:10px" />
          <v-text-field
            v-model="filter"
            append-icon="mdi-magnify"
            class="mr-auto pl-1"
            :label="$t('gbl-search')"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <div class="vertical-spacer" />

          <v-data-table
            :disabled="building_invoices"
            v-model="selected_orders"
            show-select
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="orders"
            :options.sync="options"
            :server-items-length="totalOrders"
            :loading="loadingOrders"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.business_name="{ item }">
              <v-icon :color="item.reference_color">mdi-circle</v-icon>
              {{
                item.business_name
                  ? item.business_name
                  : item.first_name + " " + item.last_name
              }}
            </template>

            <template v-slot:item.creation_date="{ item }">
              {{ item.creation_date | toLocaleDate }}
            </template>

            <template v-slot:item.shipment_date="{ item }">
              {{ item.shipment_date | toLocaleDate }}
            </template>

            <template v-slot:item.total_gross="{ item }">
              {{
                item.total_gross
                  ? $options.filters.toCurrency(item.total_gross.toFixed(2))
                  : ""
              }}
            </template>

            <template v-slot:item.state="{ item }">
              {{ getOrderState(item.state) }}
            </template>

            <template v-slot:item.ddt_number="{ item }">
              <div style="min-width:140px">
                {{
                  item.ddt_number ? item.ddt_number + " - " + item.ddt_year : ""
                }}
                <v-tooltip
                  v-if="$store.state.hasRight('186') && item.ddt_generation_id"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="cyan"
                      @click="downloadDDT(item)"
                      v-on="on"
                      ><v-icon>mdi-file-table-outline</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-download-ddt") }}</span></v-tooltip
                >

                <v-tooltip
                  v-if="$store.state.hasRight('187') && item.ddt_generation_id"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="green"
                      @click="downloadDDTWithAmounts(item)"
                      v-on="on"
                      ><v-icon>mdi-file-document-outline</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("ordermng-download-ddt-with-amounts")
                  }}</span></v-tooltip
                >
                <v-tooltip
                  v-if="$store.state.hasRight('187') && item.ddt_edi_path"
                  bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="purple"
                      @click="downloadDDTEDI(item)"
                      v-on="on"
                      ><v-icon>mdi-file-document-outline</v-icon></v-btn
                    ></template
                  ><span>{{ $t("ordermng-download-edi-ddt") }}</span></v-tooltip
                >
              </div>
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import ordersManager from "../apis/orders";
import usersManager from "../apis/users";
import creditNoteEditor from "../components/CreditNoteEditor";
import FiltersBar from "../components/FiltersBar";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      options: {
        sortBy: ["id"],
        sortDesc: [true]
      },
      creditNoteEditorDialog: false,
      headers: [],
      perPage: staticData.defautPerPage,
      totalOrders: 0,
      filter: "",

      filters: {
        filterByUserId: null,
        filterByCustomerId: null,
        filterByPeriod: 8,
        filterBySalesPoint: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByOrderState: null
      },

      selected_orders: [],
      orders: [],
      loadingOrders: false,
      loadingFilters: true,
      orders_selection: false,
      orders_unique_customer: true,
      orders_unique: true,
      orders_unique_address: true,
      building_invoices: false,
      filtered_orders: [[]]
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("ordersmng-tbl-id"),
        align: "left",
        sortable: true,
        value: "id"
      },
      {
        text: this.$t("ordersmng-tbl-customer"),
        align: "left",
        sortable: true,
        value: "business_name"
      },
      {
        text: this.$t("ordersmng-tbl-date"),
        align: "left",
        sortable: true,
        value: "creation_date"
      },
      {
        text: this.$t("ordersmng-tbl-shipment-date"),
        align: "left",
        sortable: true,
        value: "shipment_date"
      },

      {
        text: this.$t("ordersmng-tbl-state"),
        align: "left",
        sortable: true,
        value: "state"
      },
      {
        text: this.$t("ordersmng-tbl-payment-state"),
        align: "left",
        sortable: true,
        value: "payment_state"
      },
      {
        text: this.$t("ordersmng-tbl-gross"),
        align: "left",
        sortable: true,
        value: "total_gross"
      },
      {
        text: this.$t("ordersmng-tbl-amount"),
        align: "left",
        sortable: true,
        value: "amount"
      },
      {
        text: this.$t("ordersmng-tbl-ddt"),
        align: "left",
        sortable: true,
        value: "ddt_number"
      },

      {
        text: this.$t("ordersmng-tbl-notes"),
        align: "left",
        sortable: true,
        value: "notes"
      },
      {
        text: this.$t("ordersmng-tbl-user"),
        align: "left",
        sortable: true,
        value: "user"
      }
    ];
  },

  components: {
    messageBox,
    FiltersBar,
    creditNoteEditor
  },

  watch: {
    selected_orders: {
      handler() {
        this.validateSelectedOrders();
      },
      deep: true
    },

    options: {
      handler() {
        this.fetchOrdersToInvoice();
      },
      deep: true
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchOrdersToInvoice();
        }, 300);
      }
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchOrdersToInvoice();
        }, 500);
      },
      deep: true
    }
  },

  methods: {
    createCreditNote() {
      this.$refs.creditNoteEditor.setModalCNOrder(null);
      this.creditNoteEditorDialog = true;
    },

    handleCreditNote(event) {
      let order = event.order;
      this.creditNoteEditorDialog = false;
      this.fetching_data = true;
      this.$store.state.global_overlay_text = this.$t(
        "invoicesmng-work-in-progress"
      );
      this.$store.state.global_overlay = true;
      ordersManager
        .createPartialCreditNote(order)
        .then(() => {
          this.fetching_data = false;
          this.$store.state.global_overlay = false;
          this.fetchInvoices();
        })
        .catch(err => {
          console.log(err);
          this.fetching_data = false;
          this.$store.state.global_overlay = false;
        });
    },

    buildInvoices() {
      let addresses = {};
      let customers = {};
      for (let n = 0; n < this.selected_orders.length; n++) {
        let order = this.selected_orders[n];
        let key = order.customer_id + "_" + order.shipment_address_id;
        if (!order.split_by_address)
          key = order.customer_id + "_" + order.invoice_address_id;
        if (order.split_by_address) {
          if (!addresses[key]) {
            addresses[key] = [order.id];
          } else {
            addresses[key].push(order.id);
          }
        } else {
          if (!customers[key]) {
            customers[key] = [order.id];
          } else {
            customers[key].push(order.id);
          }
        }
      }
      let ret = [];
      for (let x in customers) {
        ret.push(customers[x]);
      }
      for (let x in addresses) {
        ret.push(addresses[x]);
      }

      return ret;
    },

    createInvoices() {
      this.building_invoices = true;
      this.filtered_orders = this.buildInvoices();

      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "invoicesmng-building-invoices"
      );

      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .generateInvoices(this.filtered_orders)
            .then(() => {
              this.building_invoices = false;
              this.$store.state.global_overlay = false;
              this.fetchOrdersToInvoice();
            })
            .catch(err => {
              this.building_invoices = false;
              this.$store.state.global_overlay = false;
              console.log(err);
            });
        })
        .catch(err => {
          this.$store.state.global_overlay = false;
          this.building_invoices = false;
          console.log(err);
        });
    },

    createInvoicesMultiple() {
      this.building_invoices = true;
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "invoicesmng-building-invoices"
      );
      this.filtered_orders = this.buildInvoices();
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .generateInvoices(this.filtered_orders)
            .then(() => {
              this.building_invoices = false;
              this.$store.state.global_overlay = false;
              this.fetchOrdersToInvoice();
            })
            .catch(err => {
              this.building_invoices = false;
              this.$store.state.global_overlay = false;
              console.log(err);
            });
        })
        .catch(err => {
          this.building_invoices = false;
          this.$store.state.global_overlay = false;
          console.log(err);
        });
    },

    validateSelectedOrders() {
      // Orders should be of the same customers, also flag if they have different shipping addresses
      let firstCustomer = null;
      this.orders_selection = false;
      this.orders_unique = this.selected_orders.length <= 1 ? true : false;
      this.orders_unique_customers =
        this.selected_orders.length > 0 ? true : false;

      let addresses = {};
      this.orders_unique_address = true;
      for (let n = 0; n < this.selected_orders.length; n++) {
        this.orders_selection = true;
        let order = this.selected_orders[n];
        let key = order.customer_id + "_" + order.invoice_address_id;
        if (order.split_by_address) {
          if (!addresses[key]) addresses[key] = order.shipment_address_id;
          else {
            if (addresses[key] != order.shipment_address_id) {
              this.orders_unique_address = false;
            }
          }
        }

        if (firstCustomer == null) {
          firstCustomer = key;
          this.orders_unique_customer = true;
        } else if (key != firstCustomer) {
          this.orders_unique_customers = false;
          this.orders_unique = false;
        }
      }
    },

    onFiltersLoading() {
      this.loadingFilters = true;
    },

    onFiltersLoaded() {
      this.loadingFilters = false;
    },

    getOrderState(state) {
      return staticData.ordersStates[state].name;
    },

    getOrderPaymentState(state) {
      return staticData.ordersPaymentStates[state].name;
    },

    fetchOrdersToInvoice() {
      this.selected_orders = [];
      this.loadingOrders = true;
      this.totalOrders = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByUserId = this.filters.filterByUserId;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByOrderState = this.filters.filterByOrderState;
      ctx.filterBySalesPoint = this.filters.filterBySalesPoint;

      ordersManager
        .fetchOrdersToInvoice(ctx)
        .then(result => {
          this.totalOrders = result.totalCount;
          this.orders = result.items;
          this.loadingOrders = false;
        })
        .catch(err => {
          this.loadingOrders = false;
          console.log(err);
        });
    },

    downloadDDTEDI(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadDDTEDI(item.id)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadDDTWithAmounts(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadDDTWithAmounts(item.id, this.$store.state.user.locale)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },

    downloadDDT(item) {
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .downloadDDT(item.id, this.$store.state.user.locale)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
