<template>
  <v-dialog persistent overlay-opacity="0.6" max-width="1200" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <messageBox ref="msgBox" />
      <setCurrency ref="setCurrency" />
      <setQuantity ref="setQuantity" />
      <customProduct
        :nc="true"
        ref="customProduct"
        @customProduct="handleCustomProduct"
      />

      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-file-undo-outline</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("ordermng-create-partial-credit-note-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div class="vertical-spacer" />

            <v-progress-circular
              v-if="!customer && this.order && this.order.customer_id"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="primary"
            ></v-progress-circular>

            <div v-if="!customer">
              <v-row dense>
                <v-col>
                  {{ $t("send-allergens-select-customer") }}
                  <v-autocomplete
                    :disabled="isLoadingCustomer"
                    clearable
                    dense
                    no-filter
                    v-model="flt_customer"
                    :items="flt_customers"
                    :loading="isLoadingCustomer"
                    :search-input.sync="searchCustomerInput"
                    outlined
                    item-text="display_name"
                    item-value="id"
                    :label="$t('filters-customers')"
                    return-object
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
            <div v-if="customer && order">
              <v-row v-if="order.doc_number" dense>
                <v-col>
                  {{ $t("order-edit-document-number") }}
                  <span class="font-weight-bold">{{
                    order.doc_number + " - " + order.doc_year
                  }}</span>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  {{ $t("order-edit-customer-name") }}
                  <span class="font-weight-bold">{{
                    customer.business_name
                      ? customer.business_name
                      : customer.first_name + " " + customer.last_name
                  }}</span>
                </v-col>
              </v-row>
              <div style="height:10px" />

              <div>
                <v-row>
                  <v-col cols="12">
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th></th>
                          <th class="text-center">
                            {{ $t("order-edit-tbl-code") }}
                          </th>
                          <th class="text-center">
                            {{ $t("order-edit-tbl-product") }}
                          </th>

                          <th class="text-center" style="min-width:100px">
                            {{ $t("order-edit-tbl-base-price") }}
                          </th>
                          <th class="text-center">
                            {{ $t("order-edit-tbl-vat") }}
                          </th>
                          <th class="text-center">
                            {{ $t("order-edit-tbl-quantity") }}
                          </th>
                          <th class="text-center">
                            {{ $t("order-edit-tbl-total") }}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in cart" :key="index">
                          <td class="text-left">
                            <img
                              width="48"
                              height="48"
                              v-if="item.fetchedImage"
                              :src="item.fetchedImage"
                              style="margin-top:5px; border:1px solid black;"
                            />
                            <img
                              width="48"
                              height="48"
                              v-if="!item.fetchedImage"
                              :src="require('../assets/product.png')"
                              style="margin-top:5px; border:1px solid black;"
                            />
                          </td>
                          <td class="text-center">
                            {{ getProductCode(item) }}
                          </td>
                          <td class="text-center">{{ item.name }}</td>

                          <td class="text-center" style=";min-width:140px">
                            {{ item.effective_price | toCurrencySymbol }}
                          </td>
                          <td class="text-center">
                            {{
                              ((item.effective_price * item.vat_percent) / 100)
                                | toCurrencySymbol
                            }}
                          </td>

                          <td style="vertical-align:middle;">
                            <v-text-field
                              :value="getItemQuantity(item)"
                              @change="changeItemQuantity(item, $event)"
                              outlined
                              dense
                              number
                              hide-details
                              single-line
                              @keypress="isQuantityNumber($event)"
                            ></v-text-field>
                          </td>
                          <td class="text-center">
                            {{
                              (item.effective_price *
                                parseFloat(item.quantity.replace(",", ".")))
                                | toCurrencySymbol
                            }}
                          </td>
                          <td class="text-center">
                            <v-btn
                              @click="removeItem(item)"
                              fab
                              dark
                              width="24"
                              height="24"
                              color="red"
                              ><v-icon small dark>mdi-delete</v-icon></v-btn
                            >
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>

                          <td class="text-center">
                            {{ $t("order-edit-total-products") }}:
                            {{ quantityTotal }}
                          </td>
                          <td class="text-center">
                            {{ cartTotal | toCurrencySymbol }}
                          </td>
                          <td>
                            {{ vatTotal | toCurrencySymbol }}
                          </td>
                          <td></td>
                          <td>{{ grossTotal | toCurrencySymbol }}</td>
                          <td>
                            <v-tooltip bottom
                              ><template v-slot:activator="{ on }"
                                ><v-btn
                                  fab
                                  dark
                                  width="24"
                                  height="24"
                                  color="green"
                                  @click.stop="addCustomProduct()"
                                  v-on="on"
                                  ><v-icon>mdi-plus</v-icon></v-btn
                                ></template
                              ><span>{{
                                $t("order-edit-nc-action-add-row")
                              }}</span></v-tooltip
                            >
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </div>

              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="order.memo"
                    :label="$t('invoice-create-total-nc-hint')"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense v-if="cartTotalExceedsInvoice">
                <v-col>
                  <span class="blink font-weight-bold red--text">{{
                    $t("invoice-create-total-nc-exceed")
                  }}</span>
                </v-col>
              </v-row>

              <div style="height:10px" />
              <v-progress-circular
                v-if="pushing_order"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="primary"
              ></v-progress-circular>

              <v-btn
                v-if="!pushing_order"
                width="120"
                :disabled="!cart_valid"
                v-on:click="confirm()"
                color="primary"
                >{{ $t("gbl-ok") }}</v-btn
              >

              <v-btn width="120" v-on:click="cancel()" color="secondary">{{
                $t("gbl-cancel")
              }}</v-btn>
            </div>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import productsManager from "../apis/products";
import customersManager from "../apis/customers";
import messageBox from "../components/MessageBox";
import setCurrency from "../components/SetCurrency";
import setQuantity from "../components/SetQuantity";
import customProduct from "../components/CustomProduct";

import config from "../config";

export default {
  data() {
    return {
      order: {
        user_id: null,
        cart: []
      },
      flt_customer: null,
      flt_customers: [],
      searchCustomerInput: null,
      isLoadingCustomer: false,
      customer: null,
      fetchingCart: false,
      cart: [],
      imageCache: {}
    };
  },

  props: ["value"],

  components: {
    messageBox,
    setCurrency,
    setQuantity,
    customProduct
  },

  computed: {
    cartTotalExceedsInvoice() {
      if (!this.order || !this.order.total_price || !this.order.total_vat)
        return false;
      let total =
        parseFloat(this.order.total_price) + parseFloat(this.order.total_vat);

      if (this.grossTotal.toFixed(2) > total) return true;
      else return false;
    },

    cartTotal() {
      let total = 0;
      for (let index in this.cart) {
        total +=
          parseFloat(this.cart[index].quantity.toString().replace(",", ".")) *
          this.cart[index].effective_price;
      }
      return total;
    },

    vatTotal() {
      let total = 0;
      for (let index in this.cart) {
        total +=
          (parseFloat(this.cart[index].quantity.toString().replace(",", ".")) *
            this.cart[index].effective_price *
            this.cart[index].vat_percent) /
          100;
      }
      return total;
    },

    grossTotal() {
      let total = 0;
      for (let index in this.cart) {
        total +=
          parseFloat(this.cart[index].quantity.toString().replace(",", ".")) *
          this.cart[index].effective_price;
        total +=
          (parseFloat(this.cart[index].quantity.toString().replace(",", ".")) *
            this.cart[index].effective_price *
            this.cart[index].vat_percent) /
          100;
      }
      return total;
    },

    quantityTotal() {
      return this.cart.length;
    },

    cart_valid() {
      if (this.cart.length < 1) return false;
      for (let n = 0; n < this.cart.length; n++) {
        let item = this.cart[n];
        let quantity = parseFloat(item.quantity.toString().replace(",", "."));

        if (isNaN(quantity)) return false;
        if (quantity <= 0) return false;
      }
      return true;
    }
  },

  watch: {
    searchCustomerInput(val) {
      clearTimeout(this._searchCustomerInput);
      if (val) {
        this._searchCustomerInput = setTimeout(() => {
          this.isLoadingCustomer = true;
          this.fetchFilterCustomers(val)
            .then(res => {
              this.flt_customers = res.customers.map(x => {
                if (x.business_name) x.display_name = x.business_name;
                else x.display_name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomer = false;
            })
            .catch(() => {
              this.isLoadingCustomer = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        this.customer_id = null;
      }
    },

    flt_customer: {
      handler() {
        this.customer = this.flt_customer ? this.flt_customer : null;
      }
    }
  },
  methods: {
    fetchFilterCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then(results => {
            resolve(results);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },

    getItemQuantity(item) {
      return parseFloat(item.quantity.replace(",", "."))
        .toFixed(2)
        .replace(".", ",");
    },

    changeItemQuantity(item, event) {
      if (event)
        item.quantity = parseFloat(event.replace(",", "."))
          .toFixed(2)
          .replace(".", ",");
      else item.quantity = "0";
    },

    isQuantityNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 44
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    isNotObject(item) {
      return typeof item !== "object";
    },

    getQuantityStyle(item) {
      return !item.no_stock &&
        parseFloat(item.quantity.toString().replace(",", ".")) >
          item.stock_amount
        ? "color:red"
        : "";
    },

    getProductCode(item) {
      if (item && item.id) {
        let padding = item.id.toString();
        while (padding.length < config.productIdPadding)
          padding = "0" + padding;
        return padding;
      }
      return "";
    },

    fetchProductImage(item) {
      return new Promise((resolve, reject) => {
        if (item.fetchingImage) {
          if (!this.imageCache[item.id]) {
            productsManager
              .fetchProductImage(item.id)
              .then(results => {
                if (results.image) {
                  item.fetchedImage = "data:image/png;base64," + results.image;
                  this.imageCache[item.id] = results.image;
                  item.fetchKey++;
                  item.fetchingImage = false;
                  resolve();
                } else {
                  item.fetchedImage = null;
                  item.fetchKey++;
                  item.fetchingImage = false;
                  resolve();
                }
              })
              .catch(err => {
                console.log(err);
                item.fetchedImage = null;
                item.fetchKey++;
                item.fetchingImage = false;
                resolve();
              });
          } else {
            item.fetchedImage =
              "data:image/png;base64," + this.imageCache[item.id];
            item.fetchingImage = false;
            item.fetchKey++;
            resolve();
          }
        } else {
          reject();
        }
      });
    },

    confirm: function() {
      this.handleSubmit(false);
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    closeDialog: function() {
      this.cancel();
    },

    handleSubmit() {
      this.pushing_order = true;

      this.order.cart = [];
      delete this.order.rows;
      delete this.order["user_id"];
      delete this.order["invoice_address_id"];
      delete this.order.vat_exemption;

      for (let index in this.cart) {
        let item = this.cart[index];
        if (
          typeof item.quantity === "string" ||
          item.quantity instanceof String
        ) {
          item.quantity = parseFloat(item.quantity.replace(",", ".")).toFixed(
            2
          );
        } else item.quantity = item.quantity.toFixed(2);

        let object = {
          id: item.id,
          price: item.effective_price,
          effective_price: item.effective_price * item.quantity,
          unit_price: item.effective_price,
          quantity: item.quantity,
          vat_percent: item.vat_percent,
          custom: item.custom,
          name: item.name,
          product_prefix: item.product_prefix,
          product_id: item.product_id,
          measure_unit: item.measure_unit
        };
        this.order.cart.push(object);
      }

      if (!this.order.customer_id) this.order.customer_id = this.customer.id;
      this.$emit("createNC", {
        order: this.order
      });
    },

    setModalCNOrder(order) {
      return new Promise(async (resolve, reject) => {
        if (order) {
          this.order = Object.assign({}, order);
          this.cart = [...this.order.rows];
          for (let x in this.cart) {
            this.cart[x].fetchingImage = true;
            this.cart[x].fetchKey = 0;
            this.cart[x].effective_price =
              this.cart[x].effective_price / this.cart[x].quantity;
            this.cart[x].custom = false;
            this.cart[x].quantity = this.cart[x].quantity.toFixed(2);
            this.fetchProductImage(this.cart[x]);
          }
        } else {
          this.order = {
            user_id: null,
            cart: []
          };
          this.cart = [];
          this.customer = null;
          this.flt_customer = null;
          this.flt_customers = [];
        }

        this.order.memo = null;
        this.pushing_order = false;
        if (this.order.customer_id) {
          this.fetchCustomer(this.order.customer_id)
            .then(() => {
              resolve();
            })
            .catch(err => {
              reject(err);
            });
        } else {
          // Select customer, order is null
          resolve();
        }
      });
    },

    fetchCustomer(customerId) {
      return new Promise(async (resolve, reject) => {
        customersManager
          .fetchCustomer(customerId)
          .then(res => {
            this.customer = res.customer;
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    reset() {
      this.imageCache = {}; // Always reset the cache
      this.customer = null;
      this.cart = [];
    },

    removeItem(item) {
      this.$refs.msgBox
        .show(
          this.$t("order-edit-remove-nc-text"),
          this.$t("order-edit-decrease-remove-title")
        )
        .then(() => {
          this.cart.splice(this.cart.indexOf(item), 1);
          this.buildSelectProducts();
        })
        .catch(() => {});
    },
    handleCustomProduct(event) {
      let product = event.product;
      product.unit_price = parseFloat(product.unit_price).toFixed(2);
      product.effective_price = product.unit_price;
      product.quantity = product.quantity.toString();
      product.no_stock = 1;
      product.hidden = 0;
      product.stock_amount = 0;
      product.discount = 0;
      product.fetchKey = 0;
      product.custom = true;
      this.cart.push(product);
      this.$refs.customProduct.closeDialog();
    },

    addCustomProduct() {
      this.$refs.customProduct.show();
    }
  }
};
</script>

<style scoped>
.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
