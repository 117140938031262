<template>
  <v-dialog
    overlay-opacity="0.6"
    persistent
    max-width="600"
    :value="showDialog"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-package-variant-closed</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{
                    nc
                      ? $t("nc-custom-product-title")
                      : $t("custom-product-title")
                  }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon v-if="!sending_changes" @click="closeDialog" large
                    >mdi-close</v-icon
                  ></v-col
                >
              </v-row>
            </template>
            <div class="vertical-spacer" />
            <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
              <v-progress-circular
                v-if="loadingMeasureUnits"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="primary"
              ></v-progress-circular>

              <div v-if="!loadingMeasureUnits">
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="product.name"
                        :label="$t('product-edit-hint-name')"
                        outlined
                        dense
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required|min_value:0.001"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="product.quantity"
                        :label="$t('product-edit-hint-quantity')"
                        outlined
                        dense
                        number
                        @keypress="isQuantityNumber($event)"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      :immediate="true"
                      rules="required|min_value:0"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="product.unit_price"
                        :label="$t('product-edit-hint-price')"
                        :error-messages="errors"
                        :success="valid"
                        required
                        outlined
                        type="number"
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      :immediate="true"
                      rules="required|min_value:0"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="product.vat_percent"
                        :label="$t('product-edit-hint-vat')"
                        :error-messages="errors"
                        :success="valid"
                        required
                        outlined
                        type="number"
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      :immediate="true"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        :label="$t('product-edit-hint-unit-type')"
                        v-model="product.measure_unit"
                        :items="measureUnits"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                      >
                        <template slot="selection" slot-scope="data">
                          {{ $t(data.item.name) }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ $t(data.item.name) }}
                        </template>
                      </v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <div style="height:10px" />
                <v-btn
                  v-if="!sending_changes"
                  width="120"
                  :disabled="invalid"
                  v-on:click="confirm()"
                  color="primary"
                  >{{ $t("gbl-ok") }}</v-btn
                >
                <v-progress-circular
                  v-if="sending_changes"
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="primary"
                ></v-progress-circular>

                <v-btn
                  :disabled="sending_changes"
                  width="120"
                  v-on:click="closeDialog()"
                  color="secondary"
                  >{{ $t("gbl-cancel") }}</v-btn
                >
              </div>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      loadingMeasureUnits: false,
      sending_changes: false,
      product: {},
      showDialog: false,
      measureUnits: [],
      handle_vat: false
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver
  },

  mounted() {
    this.measureUnits = this.$store.state.measure_units;
    this.reset();
  },

  watch: {},

  computed: {},

  props: ["nc"],

  methods: {
    isQuantityNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 44
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    show(handle_vat) {
      this.showDialog = true;
      this.handle_vat = handle_vat;
    },

    closeDialog() {
      this.reset();
      this.showDialog = false;
    },

    confirm() {
      this.sending_changes = true;
      this.$emit("customProduct", {
        product: this.product,
        handle_vat: this.handle_vat
      });
    },

    cancel() {
      this.closeDialog();
    },

    reset() {
      this.sending_changes = false;
      this.product = {
        name: this.nc
          ? this.$t("nc-custom-product-name")
          : this.$t("custom-product-name"),
        quantity: 1,
        description: "",
        unit_price: 1.0,
        vat_percent: 10.0,
        measure_unit: 1,
        conversion_factor: 1,
        custom: true
      };
    }
  }
};
</script>
