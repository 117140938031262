<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <v-progress-circular
          v-if="loadingData"
          :indeterminate="true"
          :rotate="0"
          :size="32"
          :width="4"
          color="primary"
        ></v-progress-circular>

        <base-material-card
          v-if="!loadingData"
          color="primary"
          icon="mdi-package-variant-closed"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("allergenscard-title") }}
            </div>
          </template>
          <div style="height:10px" />
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="filter"
                append-icon="mdi-magnify"
                class="mr-auto pl-1"
                :label="$t('gbl-search')"
                hide-details
                single-line
                style="max-width: 250px;"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <div style="height:10px" />
              <v-select
                :label="$t('productsmng-category')"
                hide-details
                item-text="name"
                item-value="id"
                outlined
                dense
                v-model="filterByProductCategory"
                :items="categories"
              >
              </v-select>
            </v-col>
          </v-row>

          <div class="vertical-spacer" />
          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="products"
            :options.sync="options"
            :server-items-length="totalProducts"
            :loading="loadingProducts"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.pimage="{ item }">
              <div style="height:64px; width: 64px; margin:5px;">
                <v-img
                  max-width="64"
                  max-height="64"
                  v-if="!item.image"
                  :src="require('../assets/product.png')"
                  :key="item.fetchKey"
                />
                <v-img
                  max-width="64"
                  max-height="64"
                  v-if="item.image"
                  :src="item.fetchedImage"
                  :key="item.fetchKey"
                />
                <v-progress-circular
                  v-if="item.fetchingImage"
                  :indeterminate="true"
                  :rotate="0"
                  :size="64"
                  :width="6"
                  color="primary"
                ></v-progress-circular>
              </div>
            </template>

            <template v-slot:item.price="{ item }">
              {{ item.price | toCurrency }}
            </template>
            <template v-slot:item.allergen_card="{ item }">
              {{
                item.allergen_card
                  ? stripePath(item.allergen_card)
                  : $t("gbl-none-female")
              }}
              <v-btn
                class="ma-0 pa-0"
                text
                icon
                v-if="item.allergen_card"
                @click="downloadAllergenCard(item)"
                color="green"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.measure_unit="{ item }">
              {{ $t(measureUnitById[item.measure_unit]) }}
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import productsManager from "../apis/products";
import fileManager from "../apis/filemanager";

export default {
  data() {
    return {
      categories: [{ name: this.$t("gbl-all-female"), value: null }],

      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      headers: [],
      imageCache: {},

      currentPage: 1,
      perPage: staticData.defautPerPage,

      filter: "",
      filterByProductCategory: null,

      products: [],
      totalProducts: 0,
      loadingProducts: false,
      loadingData: false,
    };
  },

  mounted() {
    this.headers = [
      {
        text: "",
        align: "left",
        sortable: false,
        value: "pimage",
        width: "64px",
      },
      {
        text: this.$t("productsmng-tbl-name"),
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: this.$t("productsmng-tbl-category"),
        align: "left",
        sortable: true,
        value: "category",
      },

      {
        text: this.$t("productsmng-tbl-allergen-card"),
        align: "left",
        sortable: true,
        value: "allergen_card",
      },
    ];

    this.fetchCategories();
  },

  watch: {
    options: {
      handler() {
       this.fetchProducts();
      },
      deep: true,
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchProducts();
        }, 300);
      },
    },

    filterByProductCategory: {
      handler() {
        if (this.options.page != 1) this.options.page = 1; 
        else this.fetchProducts();
      },
    },
  },

  methods: {
    stripePath(path) {
      let index = path.lastIndexOf("/");
      if (index >= 0) {
        let res = path.substr(index + 1, path.length);
        if (res.length) return res;
      }
      return path;
    },

    downloadAllergenCard(item) {
      fileManager
        .downloadFile(item.allergen_card, this.stripePath(item.allergen_card))
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },

    fetchCategories() {
      this.categories = [{ name: this.$t("gbl-all-female"), id: null }];
      this.loadingData = true;
      let ctx = {};
      ctx.sortBy = "name";
      ctx.sortDesc = false;
      productsManager
        .fetchCategories(ctx)
        .then((results) => {
          this.categories = [...this.categories, ...results.items];
          this.loadingData = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingData = false;
        });
    },

    fetchProducts() {
      this.loadingProducts = true;
      this.totalProducts = 0;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        ctx.filterByProductCategory = this.filterByProductCategory;
        productsManager
          .fetchProducts(ctx)
          .then((result) => {
            this.totalProducts = result.totalCount;
            for (let item of result.items) {
              item.fetchKey = 0;
              if (item.image) {
                item.fetchingImage = true;
                this.fetchProductImage(item);
              } else {
                item.fetchingImage = false;
              }
            }
            this.products = result.items;
            this.loadingProducts = false;
            resolve(result);
          })
          .catch((err) => {
            this.loadingProducts = false;
            console.log(err);
            reject();
          });
      });
    },

    fetchProductImage(item) {
      if (item.fetchingImage) {
        if (!this.imageCache[item.id]) {
          productsManager.fetchProductImage(item.id).then((results) => {
            if (results.image) {
              item.fetchedImage = "data:image/png;base64," + results.image;
              this.imageCache[item.id] = results.image;
              item.fetchKey++;
            }
            item.fetchingImage = false;
          });
        } else {
          item.fetchedImage =
            "data:image/png;base64," + this.imageCache[item.id];
          item.fetchingImage = false;
        }
      }
    },
  },
};
</script>
