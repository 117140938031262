<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <messageBox ref="msgBox" />
        <setDayException ref="setDayException" />
        <base-material-card
          color="primary"
          icon="mdi-cube-send"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("daysmng-title") }}
            </div>
          </template>

          <v-btn color="primary" @click="addException()">{{
            $t("daysmng-add-exception")
          }}</v-btn>
          <div class="vertical-spacer" />

          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="days"
            :options.sync="options"
            :server-items-length="totalDays"
            :loading="loadingDays"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.actions="{ item }">
              <v-row
                ><v-col class="text-center">
                  <v-tooltip bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="red darken-1"
                        @click="removeException(item)"
                        v-on="on"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("daysmng-remove-exception")
                    }}</span></v-tooltip
                  >
                </v-col></v-row
              ></template
            >
            <template v-slot:item.exception="{ item }">{{
              "Chiusura"
            }}</template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import ordersManager from "../apis/orders";
import messageBox from "../components/MessageBox";
import setDayException from "../components/SetDayException";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      options: {
        sortBy: ["id"],
        sortDesc: [true]
      },

      headers: [],
      perPage: staticData.defautPerPage,
      totalDays: 0,
      days: [],

      loadingDays: false
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("daysmng-tbl-day"),
        align: "left",
        sortable: true,
        value: "day"
      },
      {
        text: this.$t("daysmng-tbl-exception"),
        align: "left",
        sortable: true,
        value: "exception"
      }
    ];

    this.headers = [
      ...this.headers,
      { value: "actions", sortable: false, text: "" }
    ];
  },

  components: {
    messageBox,
    setDayException
  },

  computed: {},

  watch: {
    options: {
      handler() {
        this.fetchDaysExceptions();
      },
      deep: true
    }
  },

  methods: {
    addException() {
      this.$refs.setDayException
        .show()
        .then(res => {
          ordersManager
            .addDaysException(res)
            .then(() => {
              this.fetchDaysExceptions();
            })
            .catch(err => {
              this.loadingDays = false;
              console.log(err);
            });
        })
        .catch(() => {});
    },

    removeException(item) {
      this.$refs.msgBox
        .show(
          this.$t("daysmng-remove-exception-text"),
          this.$t("daysmng-remove-exception-title")
        )
        .then(() => {
          ordersManager
            .removeDaysException(item.id)
            .then(() => {
              this.fetchDaysExceptions();
            })
            .catch(err => {
              this.loadingDays = false;
              console.log(err);
            });
        })
        .catch(() => {});
    },

    fetchDaysExceptions() {
      this.loadingDays = true;
      this.totalTickets = 0;
      this.tickets = [];
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];

      ordersManager
        .fetchDaysExceptions(ctx)
        .then(result => {
          this.totalDays = result.totalItems;
          this.days = result.items;
          this.loadingDays = false;
        })
        .catch(err => {
          this.loadingDays = false;
          console.log(err);
        });
    },

    removeTicket() {
      this.$refs.msgBox
        .show(
          this.$t("ticketsmng-delete-text"),
          this.$t("ticketsmng-delete-title")
        )
        .then(() => {})
        .catch(() => {});
    }
  }
};
</script>
