<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <messageBox ref="msgBox" />
    <setString ref="setString" />
    <setStringWithSelect ref="setStringWithSelect" />
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-package-variant</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("category-edit-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <v-btn v-if="true" color="primary" @click="addCategory()">{{
                $t("category-edit-add")
              }}</v-btn>
              <v-data-table
                :items-per-page="perPage"
                :must-sort="true"
                :headers="headers"
                :items="categories"
                :options.sync="options"
                :server-items-length="totalCategories"
                :loading="loading"
                :footer-props="dataTableFooterOptions"
              >
                <template v-slot:item.name="{ item }">
                  {{ item.name }}
                  <v-icon
                    small
                    color="primary"
                    v-if="true"
                    @click="editItem(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <template v-slot:item.availability="{ item }">
                  {{ staticData.availability[item.availability].name }}
                  <v-icon
                    small
                    color="primary"
                    v-if="true"
                    @click="editAvailability(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <template v-slot:item.last_time="{ item }">
                  {{ staticData.last_time[item.last_time].name }}
                  <v-icon
                    small
                    color="primary"
                    v-if="true"
                    @click="editLastTime(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    color="error"
                    v-if="true"
                    @click="deleteItem(item)"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import staticData from "../apis/static";
import productsManager from "../apis/products";

import setString from "../components/SetString";
import setStringWithSelect from "./SetStringWithSelect.vue";

import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      staticData: staticData,
      currentPage: 1,
      perPage: staticData.defautPerPage,
      sortBy: "name",
      sortDesc: false,
      filter: "",
      totalCategories: 0,
      categories: [],
      headers: [
        {
          text: this.$t("category-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("category-edit-tbl-availability"),
          align: "left",
          sortable: true,
          value: "availability"
        },
        {
          text: this.$t("category-edit-tbl-last-time"),
          align: "left",
          sortable: true,
          value: "last_time"
        },
        { text: "", align: "right", sortable: false, value: "actions" }
      ],
      options: {
        sortBy: ["name"],
        sortDesc: [false]
      },
      loading: false
    };
  },

  components: {
    setString,
    setStringWithSelect,
    messageBox
  },

  props: ["value"],

  watch: {
    options: {
      handler() {
        this.fetchCategories();
      },
      deep: true
    }
  },

  mounted() {
    this.fetchCategories();
  },

  methods: {
    fetchCategories() {
      this.loading = true;
      this.categories = [];
      this.totalCategories = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      productsManager
        .fetchCategories(ctx)
        .then(results => {
          this.totalCategories = results.totalCount;
          this.categories = results.items;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },

    closeDialog: function() {
      this.$emit("categoryEditingDone");
      this.$emit("input");
    },

    deleteItem(item) {
      let sourceId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("category-edit-remove-text"),
          this.$t("category-edit-remove-title")
        )
        .then(() => {
          productsManager
            .removeCategory(sourceId)
            .then(() => {
              this.fetchCategories();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editAvailability(item) {
      let category = JSON.parse(JSON.stringify(item));

      this.$refs.setStringWithSelect
        .show(
          this.$t("category-edit-availability-config-title"),
          null,
          this.$t("category-edit-availability-config-hint"),
          null,
          this.$t("category-edit-availability-config-text"),
          category.availability,
          staticData.availability,
          category.id,
          false,
          false
        )
        .then(event => {
          category.id = event.context;
          category.availability = event.value.select;
          productsManager
            .updateCategoryAvailability(category)
            .then(() => {
              this.fetchCategories();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editLastTime(item) {
      let category = JSON.parse(JSON.stringify(item));

      this.$refs.setStringWithSelect
        .show(
          this.$t("category-edit-lasttime-config-title"),
          null,
          this.$t("category-edit-lasttime-config-hint"),
          null,
          this.$t("category-edit-lasttime-config-text"),
          category.last_time,
          staticData.last_time,
          category.id,
          false,
          false
        )
        .then(event => {
          category.id = event.context;
          category.last_time = event.value.select;
          productsManager
            .updateCategoryLastTime(category)
            .then(() => {
              this.fetchCategories();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editItem(item) {
      let category = JSON.parse(JSON.stringify(item));
      this.$refs.setString
        .show(
          this.$t("category-edit-config-title"),
          this.$t("category-edit-config-hint"),
          this.$t("category-edit-config-text"),
          category.name,
          category.id,
          true,
          false
        )
        .then(event => {
          category.id = event.context;
          category.name = event.value;
          productsManager
            .updateCategory(category)
            .then(() => {
              this.fetchCategories();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    addCategory() {
      this.$refs.setString
        .show(
          this.$t("category-edit-add-title"),
          this.$t("category-edit-add-hint"),
          this.$t("category-edit-add-text"),
          this.$t("category-edit-add-default"),
          null,
          true,
          false
        )
        .then(event => {
          productsManager
            .addCategory({ name: event.value })
            .then(() => {
              this.fetchCategories();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    }
  }
};
</script>
